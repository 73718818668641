import React from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  withStyles,
  Typography,
  Container,
  Grid,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormGroup,
} from "@material-ui/core";
import tellIt from "../Images/TellIt/TellHome/tellItN.svg";
import frame214 from "../Images/TellIt/TellHome/frame214N2.svg";
import frame425N from "../Images/TellIt/TellHome/frame425N.svg";
import step1 from "../Images/TellIt/TellHome/step1.svg";
import step2 from "../Images/TellIt/TellHome/step2.svg";
import step3 from "../Images/TellIt/TellHome/step3.svg";
import step4 from "../Images/TellIt/TellHome/step4.svg";
import step5 from "../Images/TellIt/TellHome/step5.svg";
import vector2 from "../Images/TellIt/TellHome/vector2.svg";
import ellipse53 from "../Images/TellIt/TellHome/ellipse53.svg";
import rectangle13 from "../Images/TellIt/TellHome/rectangle13.svg";

import Preview from "./Preview.js";
//Checkbox library
import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PopupHome1 from "./PopupHome1";

const styles = makeStyles({
  mapHome: {
    height: "1720px",
    maxWidth: "1440px",
    overflow: "auto",
    position: "",
    // eslint-disable-next-line
    ["@media (max-width:1100px)"]: {
      maxWidth: "1100px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      maxWidth: "1280px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1440px)"]: {
      maxWidth: "1440px",
    },
  },
  //*Main Grid
  frame751: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "1078px",
    height: "1445px",
    padding: "70px 181px 72px 170px",
    // eslint-disable-next-line
    ["@media (max-width:1100px)"]: {
      marginLeft: "-100px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-80px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1440px)"]: {
      marginLeft: "-20px",
    },
  },
  //Background vector specification
  rectangle13: {
    position: "absolute",
    left: "10px",
    top: "-80px",
    zIndex: 0,
    // eslint-disable-next-line
    ["@media (max-width:1100px)"]: {
      left: "-120px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      left: "-80px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1440px)"]: {
      left: "-70px",
    },
  },
  vector2: {
    position: "absolute",
    left: "910.84px",
    top: "350.94px",
    zIndex: 0,
    // eslint-disable-next-line
    ["@media (max-width:1100px)"]: {
      left: "390px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      left: "820px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1440px)"]: {
      left: "870px",
    },
  },
  ellipse53: {
    position: "absolute",
    left: "-30px",
    top: "1167px",
    zIndex: 0,
    // eslint-disable-next-line
    ["@media (max-width:1100px)"]: {
      left: "-90px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      left: "-110px",
    },
  },
  //1 Subgrid: Name:Map IT and
  frame750: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "1078px",
    height: "649px",
    padding: "0px",
    marginBottom: "64px",
  },
  tellIt: {
    position: "static",
    width: "293px",
    height: "112px",
    marginTop: "20px",
  },
  frame749: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "1078px",
    height: "509px",
    padding: "0px",
    top: "50px",
  },
  frame346: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "301px",
    height: "509px",
    padding: "0px",
    margin: "0px 142px 0px 0px",
    // eslint-disable-next-line
    ["@media (max-width:1100px)"]: {
      marginRight: "30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginRight: "70px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1440px)"]: {
      marginRight: "142px",
    },
  },
  frame518: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "254.59px",
    height: "241px",
    padding: "0px",
    margin: "24px 0px",
  },
  group36: {
    position: "static",
    width: "254.59px",
    height: "185px",
    margin: "0px 0px 8px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  frame426: {
    position: "absolute",
    margin: "58.14px 0px 56px 99.46px",
  },
  frame517: {
    position: "static",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "224px",
    height: "48px",
    padding: "0px",
  },
  frame215: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "301px",
    height: "196px",
    padding: "0px",
  },
  frame216: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "301px",
    height: "56px",
    padding: "0px",
    margin: "0px 0px 24px 0px",
    justifyContent: "space-between",
  },
  frame216info1: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    fontFeatureSetting: "ss03 on",
  },

  frame217: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "301px",
    height: "84px",
    padding: "0px",
    justifyContent: "space-between",
  },
  frame217info2: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    fontFeatureSetting: "ss03 on",
  },
  frame198: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "635px",
    height: "448px",
    padding: "0px",
    justifyContent: "space-between",
  },
  frame198Title: {
    position: "static",
    width: "520px",
    height: "48px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "32px",
    lineHeight: "48px",
    variant: "h3",
    fontFeatureSetting: "ss03 on",
    color: "#24243F",
  },
  frame198info1: {
    position: "static",
    width: "635px",
    height: "64px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "32px",
    variant: "subtitle",
    fontFeatureSetting: "ss03 on",
    color: "#2D86D9",
  },
  frame198info2: {
    position: "static",
    width: "635px",
    height: "96px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "32px",
    variant: "subtitle",
    fontFeatureSetting: "ss03 on",
    color: "#24243F",
  },
  frame198info3: {
    position: "static",
    width: "635px",
    height: "160px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "32px",
    variant: "subtitle",
    fontFeatureSetting: "ss03 on",
    color: "#24243F",
  },
  //2.SubGrid: Steps details and Checkbox
  frame748: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "1077px",
    height: "732px",
    padding: "0px",
    top: "64px",
  },
  frame538: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "1078px",
    height: "276px",
    margin: "0px 0px 64px 0px",
    //left: "81px",
    justifyContent: "space-between",
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      left: "40px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      left: "40px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1440px)"]: {
      left: "60px",
    },
  },
  frame564: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "915px",
    height: "144px",
    padding: "0px",
    margin: "0px 0px 8px 0px",
    left: "100px",
  },
  frame663: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "170px",
    height: "280px",
    padding: "0px",
    marginRight: "58px",
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginRight: "30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginRight: "40px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1440px)"]: {
      marginRight: "50px",
    },
  },
  imageHover: {
    "&:hover": {
      transform: "rotate(-5deg)",
      transition: "0.5s",
    },
  },
  stepInfo: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
  },
  frame188: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "650px",
    height: "400px",
    padding: "0px",
    marginLeft: "441px",
    //marginTop: "40px",
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "300px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "330px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1440px)"]: {
      marginLeft: "450px",
    },
  },
  frame597: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "650px",
    height: "88px",
    padding: "0px",
    margin: "0px 0px 24px 0px",
  },
  frame597info1: {
    position: "relative",
    width: "452px",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "32px",
    variant: "h4",
    color: "#24243F",
    margin: "0px 0px 8px 0px",
  },
  frame597info2: {
    position: "relative",
    width: "636px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    color: "#000000",
  },
  frame331: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "636px",
    height: "160px",
    padding: "0px",
    margin: "0px 0px 32px 0px",
  },
  frame213: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    width: "579px",
    height: "130px",
    marginBottom: '25px'
  },
  frame188button: {
    borderRadius: "32px",
    textTransform: "none",
    width: "636px",
    height: "64px",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
    margin: "170px 0px -10px -10px",
  },
  blueLink: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2D86D9",
    textTransform: "none",
    textDecoration: "none",
    marginLeft: "-7px",
  },
});

// Checkbox Shape and color Customization //
const CustomColorCheckbox = withStyles({
  root: {
    color: "#BBBECC",
    "&$checked": {
      color: "#656C88",
    },
    margin: "16 px",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function MapHome() {
  const classes = styles();

  // State definition and handle change for checkbox

  const [state, setState] = React.useState({
    checked1: false,
    checked2: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { checked1, checked2 } = state;
  const error1 = [checked1].filter((v) => v).length !== 1;
  const error2 = [checked2].filter((v) => v).length !== 1;

  return (
    <div style={{ minHeight: "1600px" }}>
      <Grid direction="column">
        <Grid item xs className={classes.rectangle13}>
          <img src={rectangle13} alt="rectangle 13" />
        </Grid>
        <Grid item xs className={classes.vector2}>
          <img src={vector2} alt="vector 2" />
        </Grid>
        <Grid item xs className={classes.ellipse53}>
          <img src={ellipse53} alt="ellipse53" />
        </Grid>
      </Grid>
      <Container className={classes.mapHome}>
        <Grid direction="column" item xs className={classes.frame751}>
          <Grid order="0" item xs className={classes.frame750}>
            <Grid order="0" item xs className={classes.tellIt}>
              <img src={tellIt} alt="MAP IT" />
            </Grid>
            <Grid
              order="1"
              item
              xs
              direction="row"
              className={classes.frame749}
            >
              <Grid item xs direction="column" className={classes.frame346}>
                <img src={frame214} alt="20 Minutes 3 Steps" />
                <Box className={classes.frame518}>
                  <Box className={classes.group36}>
                    <img src={frame425N} alt="20 Minutes 3 Steps" />
                  </Box>
                  <Box className={classes.frame517}>
                    <Preview />
                  </Box>
                </Box>
                <Box className={classes.frame215}>
                  <Box className={classes.frame216}>
                    <Typography className={classes.frame216info1}>
                      Hva du får
                    </Typography>
                    <Typography className={classes.frame216info2}>
                    <PopupHome1 />
                    </Typography>
                  </Box>
                  <Box className={classes.frame217}>
                    <Typography className={classes.frame216info1}>
                      Hva du kan bruke den til
                    </Typography>
                    <Typography className={classes.frame217info2}>
                      Skrive søknader om finansiering
                    </Typography>
                    <Typography className={classes.frame217info2}>
                      Data til pitching og presentasjoner
                    </Typography>
                    <Typography className={classes.frame217info2}>
                      Mer støtte i sosiale medier
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs direction="column" className={classes.frame198}>
                <Typography className={classes.frame198Title}>
                  Hva handler Fortell Det! om?
                </Typography>
                <Typography className={classes.frame198info1}>
                  I denne delen vil du oppsummere og skrive en kort rapport om
                  prosjektets samfunnseffekt.
                </Typography>
                <Typography className={classes.frame198info2}>
                  En enkel énsides rapport er den mest effektive måten å dele
                  resultatet av arbeidet ditt med støttespillere, partnerne og
                  det bredere fellesskapet.
                </Typography>
                <Typography className={classes.frame198info3}>
                  Hvorfor? Det er viktig å forstå hva som går bra og ikke slik
                  at du kan forbedre aktivitetene dine. Å ha en kort
                  samfunnseffektrapport vil også hjelpe deg med å formidle
                  kvaliteten i arbeidet ditt til kunder, investorer og
                  støttespillere.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/*Subgrid 2: Steps details and Checkbox*/}
          <Grid
            order="1"
            direction="column"
            item
            xs
            className={classes.frame748}
          >
            <Grid order="0" item xs className={classes.frame538}>
              {/*step 1 Image with hover effect, css is defined in App.css file  */}
              <Box className={classes.frame663} component="div">
                <Box className="mapHomeImage">
                  <img
                    src={step1}
                    alt="step 1"
                    className={classes.imageHover}
                  />
                  <Box className="text">Steg 1 Kontekst</Box>
                </Box>
                <Box className="hide" mt={-1}>
                  <ul>
                    <li className="li">
                      {" "}
                      <Typography noWrap> Prosjektdetaljer</Typography>
                    </li>
                    <li className="li">
                      <Typography noWrap> Heispitch</Typography>
                    </li>
                  </ul>
                </Box>
              </Box>
              {/*step 2 Image with hover effect, css is defined in App.css file  */}
              <Box className={classes.frame663} component="div">
                <Box className="mapHomeImage">
                  <img
                    src={step2}
                    alt="step 2"
                    className={classes.imageHover}
                  />
                  <Box className="text">Steg 2 Aktiviteter og endringer</Box>
                </Box>
                <Box className="hide" mt={-1}>
                  <ul>
                    <li className="li">
                      <Typography noWrap>Resultater</Typography>
                    </li>
                    <li className="li">
                      <Typography noWrap>Endringer</Typography>{" "}
                    </li>
                  </ul>
                </Box>
              </Box>
              {/*step 3 Image with hover effect, css is defined in App.css file */}
              <Box className={classes.frame663}>
                <Box className="mapHomeImage">
                  <img
                    src={step3}
                    alt="step 3"
                    className={classes.imageHover}
                  />
                  <Box className="text">Steg 3 Tilbakemelding og effekt</Box>
                </Box>
                <Box className="hide" mt={-1}>
                  <ul>
                    <li className="li">
                      <Typography noWrap>Effekt</Typography>
                    </li>
                    <li className="li">
                      {" "}
                      <Typography noWrap>
                        Tilbakemeldinger fra interessenter{" "}
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </Box>
              {/*step 4 Image with hover effect, css is defined in App.css file */}
              <Box className={classes.frame663}>
                <Box className="mapHomeImage">
                  <img
                    src={step4}
                    alt="step 4"
                    className={classes.imageHover}
                  />
                  <Box className="text">Steg 4 Neste steg</Box>
                </Box>
                <Box className="hide" mt={-1}>
                  <Typography
                    style={{ textAlign: "center", marginTop: "16px" }}
                  >
                    En énsides rapport som presenterer resultatene og
                    kommuniserer verdien av dine aktiviteter
                  </Typography>
                </Box>
              </Box>
              {/* Final step  Image with hover effect, css is defined in App.css file */}
              <Box className={classes.frame663}>
                <Box className="mapHomeImage">
                  <img
                    src={step5}
                    alt="Steg 5"
                    className={classes.imageHover}
                  />
                  <Box className="text">Få din samfunnseffektrapport</Box>
                </Box>
                <Box className="hide">
                  <Typography
                    style={{ textAlign: "center", marginTop: "16px" }}
                  >
                    En énsides rapport som presenterer resultatene og
                    kommuniserer verdien av aktivitetene dine.{" "}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid order="1" item xs className={classes.frame188}>
              <Box className={classes.frame597}>
                <Typography className={classes.frame597info1}>
                  Greit å ha før du starter!
                </Typography>
                <Typography className={classes.frame597info2}>
                  For å lage en effektrapport, er det lurt å sørge for at du har
                  litt informasjon før du starter. Vi anbefaler at du har:
                </Typography>
              </Box>
              <Box className={classes.frame331}>
                <FormControl
                  required
                  error={error1}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      style={{ display: "table" }}
                      control={
                        <div style={{ display: "table-cell" }}>
                          <CustomColorCheckbox
                            value={checked1}
                            onChange={handleChange}
                            name="checked1"
                            color="#24243F"
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            style={{ transform: "scale(1.5)" }}
                            required
                          />
                        </div>
                      }
                      label={
                        <Box ml={2} className={classes.frame213}>
                          <Typography
                            variant="body"
                            style={{
                              color: "#24243F",
                              fontSize: "16px",
                              lineHeight: "24px",
                              fontWeight: "bold",
                            }}
                          >
                            Oversikt over prosjektmål, aktiviteter og prestasjoner. Dette inkluderer en klar hypotese om endringen og resultatene av aktivitetene dine.
                          </Typography>
                          <FormHelperText
                            variant="body"
                            style={{ fontSize: "16px", lineHeight: "24px" }}
                          >
                            Her bør du ha en klar hypotese om hvilke endringer
                            og resultater aktivitetene dine leder til. Er du
                            ikke helt sikker? Få en oppfriskning ved å gå til:
                            {" "}
                            <Button
                              to="/Main/MapHome"
                              component={Link}
                              className={classes.blueLink}
                            >
                              KARTLEGG DET!
                            </Button>
                          </FormHelperText>
                        </Box>
                      }
                    />
                  </FormGroup>
                </FormControl>
                <FormControl
                  required
                  error={error2}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      style={{ display: "table", marginBottom: "32px" }}
                      control={
                        <div style={{ display: "table-cell" }}>
                          <CustomColorCheckbox
                            value={checked2}
                            onChange={handleChange}
                            name="checked2"
                            color="#24243F"
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            style={{ transform: "scale(1.5)" }}
                            required
                          />
                        </div>
                      }
                      label={
                        <Box ml={2} className={classes.frame213}>
                          <Typography
                            variant="body"
                            style={{
                              color: "#24243F",
                              fontSize: "16px",
                              lineHeight: "24px",
                              fontWeight: "bold",
                            }}
                          >
                            Data om indikatorer, resultater og tilbakemeldinger fra målgruppen. Dette inkluderer resultater fra datainnsamlingen og sammendraget av funnene dine.
                            {" "}
                          </Typography>
                          <FormHelperText
                            variant="body"
                            style={{ fontSize: "16px", lineHeight: "24px" }}
                          >
                           Begynn med å tolke resultatene fra datainnsamlingen og oppsummere funnene dine. Er du ikke helt sikker? Få en oppfriskning ved å gå til:{" "}
                            <Button
                              to="/Main/TrackHome"
                              component={Link}
                              className={classes.blueLink}
                            >
                              UNDERSØK DET!
                            </Button>
                          </FormHelperText>
                        </Box>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Button
                variant="contained"
                className={classes.frame188button}
                disabled={!(checked1 && checked2)}
                component={Link}
                to="/Main/TellItStep1_1"
              >
                Sett i gang!
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
