import React, { Component } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import Vectortrack2 from "../../Images/TrackIt/TrackStep2/Vectortrack2.svg";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import Ellipse30track1 from "../../Images/TrackIt/TrackStep2/Ellipse30Track1.svg";
import Ellipse31 from "../../Images/Main/Ellipse31.png";
import DudeTrackIt from "../../Images/TrackIt/TrackStep1/DudeTrackIt.svg";
import ExitPopupTrackIt from "../ExitDialoge/ExitPopupTrackIt";

const styles = (theme) => ({
  innerContent: {
    width: "100%",
    //Changes by Kishan
    height: "1070px",
    // height: '100%',
    "overflow-x": "visible",
    "overflow-y": "auto",
  },
  frame747: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "1440px",
    left: "0px",
    top: "0px",
    marginTop: "250px",
  },
  frame746: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "50px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-145px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-274px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-310px",
      marginTop: "50px",
    },
  },
  frame745: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    height: "564px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "72px",
  },
  frame743: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0px 70px 0px 70px",
    bottom: "0px",
    position: "sticky",
    width: "1440px",
    height: "85px",
    top: "1526px",
    flexGrow: "1",
    marginBottom: "-300px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "166px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "283px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "329px",
    },
  },
  frame190: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "524px",
    height: "48px",
    left: "735px",
    top: "37px",
    flex: "none",
    order: "1",
    flexGrow: "0",

    // eslint-disable-next-line
    ["@media (min-width:1440px)"]: {
      marginLeft: "30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-10px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-140px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-255px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-300px",
      width: "440px",
    },
  },
  frame235: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "264px",
    height: "48px",
    left: "260px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  nextButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "10px",
    position: "static",
    left: "144px",
    top: "0px",
    background: "#24243F",
    borderRadius: "24px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#24243F",
    },
  },
  backButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "120px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  downloadButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "214px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  exploreButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "141px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
    marginRight: "150px",
  },
  exitButton: {
    position: "static",
    width: "120px",
    height: "48px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  frame203: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "229px",
    height: "56px",
    left: "101px",
    top: "29px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      width: "200px",
    },
  },
  frame203buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    position: "static",
    width: "75px",
    height: "24px",
    left: "0px",
    top: "32px",
    borderRadius: "8px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginBottom: "12px",
  },
  frame203button: {
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#2D86D9",
    flex: "none",
    order: "0",
    flexGrow: "0",
    marginLeft: "-8px",
    marginTop: "17px",
    textTransform: "none",
    textDecoration: "none",
  },
  frame203Text: {
    position: "static",
    left: "229px",
    top: "24px",
    right: "0px",
    bottom: "0px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  FrameDudeTrackIt: {
    position: "static",
    left: "85px",
    top: "85px",
    right: "0px",
    bottom: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "10px 10px 0px 16px",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      marginLeft: "-10px",
    },
  },
  outerFrameDudeTrackIt: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "0px",
    position: "static",
    width: "330px",
    height: "85px",
    left: "70px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },

  frame744: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px 150px 0px 365px",
    position: "static",
    width: "1440px",
    height: "515px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "43px",
  },
  frame274: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "483px",
    left: "735px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "-40px",
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "0px",
    },
  },
  frame233: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "128px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "48px 0px",
  },
  Paragraph1: {
    position: "static",
    width: "524px",
    height: "168px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "30px",
  },
  Paragraph2: {
    position: "static",
    width: "524px",
    height: "110px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "30px",
  },
  Paragraph3: {
    position: "static",
    width: "524px",
    height: "168px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "30px",
    marginBottom: "30px",
  },
  step1: {
    fontWeight: "bold",
    position: "static",
    width: "524px",
    height: "32px",
    left: "0px",
    top: "0px",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
  },
  frame234: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "302px",
    height: "72px",
    left: "403px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px 30px",
  },
  header1: {
    position: "static",
    width: "302px",
    height: "48px",
    left: "0px",
    top: "24px",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#24243F",
  },
  headerStep: {
    fontWeight: "bold",
    position: "static",
    width: "50px",
    height: "24px",
    left: "0px",
    top: "0px",
    fontSize: "16px",
    lineHeight: "32px",
    color: "#24243F",
  },
  frame590: {
    width: "364.34px",
    height: "262.36px",
    // left: "96px",
    // top: "2.19px",
  },
  Frame590Vector: {
    position: "absolute",
    left: "96px",
    top: "2.19px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-150px",
      left: "127px",
      top: "77px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-100px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-150px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
  },
  Ellipse30: {
    marginLeft: "-15px",
    marginBottom: "23px",
  },
  Ellipse31: {
    left: "0px",
    top: "0px",
  },

  subHeader1: {
    width: "250px",
    height: "24px",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    marginLeft: "-197px",
    marginTop: "4px",
  },
  subHeader2: {
    width: "524px",
    height: "24px",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    marginTop: "30px",
  },
  subText1: {
    fontWeight: "400",
  },
  formControl: {
    marginTop: "30px",
    width: "255px",
  },
  contentButtons: {
    marginTop: "155px",
  },
  frame210: {
    marginTop: "50px",
  },

  box1Text: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    margin: "0 10px",
  },
  box1: {
    background: "#E3E5E9",
    borderRadius: "20px",
    margin: "8px 0px",
    padding: "10px 20px",
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
      background: "#BCDDF7",
    },
    "&:focus": {
      background: "#BCDDF7",
    },

    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
  },
  box1blue: {
    backgroundColor: "#BCDDF7",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    borderRadius: "20px",
    margin: "8px 0px",
    padding: "10px 20px",
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
      background: "#BCDDF7",
    },
    "&:focus": {
      background: "#BCDDF7",
    },
  },
});

class TrackStep2_1 extends Component {
  tempImpact;
  constructor(props) {
    super(props);

    this.state = {
      display: "static",

      specificOutcomeOptions: {
        0: [
          "Trener regelmessig",
          "Adresserer problemer med misburuk av rusmidler",
          "Spiser og sover godt",
        ],
        1: [
          "Forbedret motstandsdyktighet",
          "Er generelt tilfreds med livet",
          "I stand til å håndtere mental helse",
        ],
        2: [
          "Har passende bosituasjon i god stand",
          "Forbedret tilgang til tjenester og fasiliteter",
          "Forbedret tilgang til finansielle produkter og tjenester",
        ],
        3: [
          "Forbedret støtte for familiemedlemmer, partnere, hovedforsørgere og venner av dem med spesifikke behov",
          "Forbedret støtte for familier, barn og unge",
          "Blir er mer aktivt medlem av lokalsamfunnet",
        ],
        4: [
          "Har et passende arbeid",
          "Har opprettholdt arbeidsforhold",
          "Forbedret mellommenneskelige og sosiale ferdigheter",
        ],
        5: [
          "Er i passende utdanning eller opplæring",
          "Forbedrede karakterer",
          "Forbedret generell forberedelse til arbeid",
        ],
      },
      outcome: "",
      outcomes: [],
      chosenOutcome: "",
      active1: false,
      active2: false,
      active3: false,
      checked1: false,
    };
    this.onClickOutcome1 = this.onClickOutcome1.bind(this);
  }
  componentWillUpdate(nextProps, nextState) {
    let tempImpact = JSON.parse(localStorage.getItem("trackStep13"));

    localStorage.setItem(
      "trackStep21",
      JSON.stringify({
        outcome: tempImpact.outcome,
        outcomes: nextState.outcomes,
        chosenOutcome: nextState.chosenOutcome,
        active1: nextState.active1,
        active2: nextState.active2,
        active3: nextState.active3,
        checked1: nextState.checked1,
      })
    );
  }
  onClickOutcome1 = (e) => {
    this.setState({
      chosenOutcome: e.target.innerText,
      checked1: true,
      active1: true,
      active2: false,
      active3: false,
    });
  };
  onClickOutcome2 = (e) => {
    this.setState({
      chosenOutcome: e.target.innerText,
      checked1: true,
      active1: false,
      active2: true,
      active3: false,
    });
  };
  onClickOutcome3 = (e) => {
    this.setState({
      chosenOutcome: e.target.innerText,
      checked1: true,
      active1: false,
      active2: false,
      active3: true,
    });
  };
  componentDidMount() {
    this.trackStep2_1Data = JSON.parse(localStorage.getItem("trackStep21"));
    this.tempImpact = JSON.parse(localStorage.getItem("trackStep14"));

    this.setState({
      outcomes: this.tempImpact.outcomes,
    });

    if (localStorage.getItem("trackStep21")) {
      this.setState({
        outcome: this.trackStep2_1Data.outcome,
        outcomes: this.trackStep2_1Data.outcomes,
        chosenOutcome: this.trackStep2_1Data.chosenOutcome,
        active1: this.trackStep2_1Data.active1,
        active2: this.trackStep2_1Data.active2,
        active3: this.trackStep2_1Data.active3,
        checked1: this.trackStep2_1Data.checked1,
      });
      // if (this.trackStep2_1Data.chosenOutcome !== '') {
      //   this.setState({
      //     checked1: true
      //   });
      // }
    } else {
      // if (this.trackStep2_1Data.chosenOutcome !== '') {
      //   this.setState({
      //     checked1: true
      //   });
      // }
      this.setState({
        chosenOutcome: this.state.chosenOutcome,
      });
      // if (this.trackStep2_1Data.chosenOutcome === this.state.chosenOutcome) {
      //   this.setState({

      //     active: true,
      //   });
      // }
    }
  }
  render() {
    const { classes } = this.props;
    const { outcomes } = this.state;
    const { checked1 } = this.state;
    const { active1 } = this.state;
    const { active2 } = this.state;
    const { active3 } = this.state;

    return (
      <div className={classes.innerContent}>
        <Grid item xs className={classes.frame590}>
          <img
            src={Vectortrack2}
            alt="Vectortrack2"
            className={classes.Frame590Vector}
          />
        </Grid>
        <Grid item xs className={classes.frame747}>
          <Grid item xs className={classes.frame746}>
            <Grid item xs className={classes.frame745}>
              <Grid item xs className={classes.frame744}>
                <Grid item xs className={classes.frame274}>
                  <Grid item xs className={classes.frame233}>
                    <Typography className={classes.step1}>
                      Steg 2: Plan for måling av data
                    </Typography>

                    <Typography className={classes.Paragraph1}>
                      Det er viktig å planlegge hvilke endringer som skal måles
                      og hvordan man skal måle dem. Dette steget beskriver
                      hvordan du kan følge opp fremdriften til prosjektet. Dette
                      inkluderer personene du får dataene fra, og metodene du
                      vil bruke.
                    </Typography>
                    <Typography className={classes.Paragraph2}>
                      Når du nettopp har begynt å måle samfunnseffekt kan det
                      være lurt å prøve å måle kun en endring. Hvis du vil måle
                      mer enn en, kan du gjerne gjenta denne delen.
                    </Typography>
                    <Grid container xs className={classes.frame210}>
                      <Grid item xs>
                        <img
                          src={Ellipse31}
                          alt="Ellipse31"
                          className={classes.Ellipse31}
                        />
                        <img
                          src={Ellipse30track1}
                          alt="Ellipse30"
                          className={classes.Ellipse30}
                        />{" "}
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.subHeader1}>
                        Endringen du vil måle
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography className={classes.Paragraph3}>
                    Basert på effektområdet du valgte, er det tre mulige endringer. Hvilken endring vil du måle? Velg én.
                    </Typography>

                    {/* dynamic data to come from prev page / client info
                      
                      blue background added on hover (not on select) in app.css*/}
                    {/* {outcomes.map((item) => (
                      ))} */}
                    {active1 && (
                      <Button
                        className={classes.box1blue}
                        // style={{ backgroundColor: active1  ? "#BCDDF7F" : "#E3E5E9" }}

                        onClick={this.onClickOutcome1}
                      >
                        {outcomes[0]}
                      </Button>
                    )}
                    {!active1 && (
                      <Button
                        className={classes.box1}
                        //style={{ backgroundColor: active1  ? "#BCDDF7F" : "#E3E5E9" }}

                        onClick={this.onClickOutcome1}
                      >
                        {outcomes[0]}
                      </Button>
                    )}
                    {active2 && (
                      <Button
                        className={classes.box1blue}
                        style={{
                          backgroundColor: active2 ? "#BCDDF7F" : "#E3E5E9",
                        }}
                        onClick={this.onClickOutcome2}
                      >
                        {outcomes[1]}
                      </Button>
                    )}
                    {!active2 && (
                      <Button
                        className={classes.box1}
                        style={{
                          backgroundColor: active2 ? "#BCDDF7F" : "#E3E5E9",
                        }}
                        onClick={this.onClickOutcome2}
                      >
                        {outcomes[1]}
                      </Button>
                    )}
                    {active3 && (
                      <Button
                        className={classes.box1blue}
                        style={{
                          backgroundColor: active3 ? "#BCDDF7F" : "#E3E5E9",
                        }}
                        onClick={this.onClickOutcome3}
                      >
                        {outcomes[2]}
                      </Button>
                    )}
                    {!active3 && (
                      <Button
                        className={classes.box1}
                        style={{
                          backgroundColor: active3 ? "#BCDDF7F" : "#E3E5E9",
                        }}
                        onClick={this.onClickOutcome3}
                      >
                        {outcomes[2]}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs className={classes.frame234}>
                  <Typography noWrap className={classes.headerStep}>
                    Steg 2
                  </Typography>

                  <Typography className={classes.header1}>
                  Plan for måling <br />av data
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs className={classes.frame743}>
              <Grid item xs className={classes.frame190}>
                <Grid order="1" item xs className={classes.frameExitButton}>
                  <ExitPopupTrackIt />
                </Grid>
                <Grid item xs className={classes.frame235}>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235TertiaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.backButton}
                      component={Link}
                      to="/Main/TrackItStep1_4"
                    >
                      Tilbake
                    </Button>
                  </Grid>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235PrimaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.nextButton}
                      component={Link}
                      to="/Main/TrackItStep2_2"
                      type="submit"
                      disabled={!checked1}
                    >
                      Neste
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs className={classes.outerFrameDudeTrackIt}>
                <Grid order="0" item xs className={classes.FrameDudeTrackIt}>
                  <img
                    src={DudeTrackIt}
                    alt="illustrationMan"
                    className={classes.DudeTrackIt}
                  />
                </Grid>
                <Grid item xs className={classes.frame203}>
                  <Typography className={classes.frame203Text}>
                    Spørsmål?
                  </Typography>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.frame203buttonContainer}
                  >
                    <Button
                      className={classes.frame203button}
                      component={Link}
                      to="/Main/Contact"
                    >
                       Kontakt Norge Unlimited
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TrackStep2_1);
