import React from 'react'
import { Box, Container, makeStyles, Paper, Typography } from '@material-ui/core'

const styles=makeStyles({
    bar:{
        height:'1260px',
        justifyContent:'center',
        display:'flex',
        alignItems:'flex-start',
        marginTop:'50px',
        margin:'auto',
    }
})

export default function Privacy() {
    const classes = styles();
    return (
        <div className={classes.bar}>
           <Container height='900px' >
               <Paper elevation={3} style={{backgroundColor:'#FBF9F8',marginBottom:'50px',overflowY:'scroll', height:'1200px'}}  >
                    <Box variant='h4' component={Typography} sx={{paddingTop:'20px',display:'flex',alignItems:'center',justifyContent:'center',fontWeight:700}}>
                        Personvernerklæring
                    </Box>
                    <Box variant='body' component='div'  sx={{padding:'60px', fontFamily: 'Verdana', fontSize:15}}> 
                        <Box variant='body' color='#4da6ff' fontSize='16px'>1 Innledning</Box>
                        <Box variant='body' component='div' mt={1}>
                            Tøyen Unlimited ("vi") er opptatt av at du skal ha tillit til oss og hvordan 
                            vi behandler dine personopplysninger. I denne personvernerklæringen forklarer 
                            vi derfor hvorfor vi samler inn informasjon om deg, hvordan vi bruker denne 
                            informasjonen og hvordan vi tar hensyn til ditt personvern. 
                        </Box>
                        <Box variant='body' component='div' mt={1}>
                            Personopplysninger er opplysninger og vurderinger som kan knyttes til en identifiserbar 
                            enkeltperson. Dette kan for eksempel være navn og kontaktinformasjon, kjøpshistorikk eller 
                            informasjon om interesser.
                        </Box>
                        <Box variant='body' component='div' mt={1}>
                            Bruk av personopplysninger må følge personopplysningsloven. Vi, som behandlingsansvarlig, 
                            vil sørge for at vi bruker personopplysninger om deg i samsvar med lovens regler.
                        </Box>
                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>2 Hvilke personopplysninger har vi om deg</Box>
                        <Box variant='body' component='div' mt={1}>
                            Avhengig av hvilken informasjon du gir oss, hvordan du bruker våre tjenester, og hvilke
                            tillatelser du gir oss, har vi disse opplysningene:
                        </Box>
                        <ul>
                            <li>
                                <Box fontWeight='bold'>Opplysninger om deg:</Box> 
                                Informasjon du oppgir når du oppretter en brukerkonto eller kontakter oss via vårt kontaktskjema, som navn, telefonnummer og e-postadresse.
                            </li>
                            <li>
                                <Box fontWeight='bold'>Kommunikasjon:</Box>
                                Informasjon du gir oss når du kontakter oss, som e-poster sendt mellom oss. 
                            </li>
                            <li>
                                <Box fontWeight='bold'>Opplysninger fra partnere: </Box>
                                Informasjon vi får fra våre samarbeidspartnere, som navn, telefonnummer og e-postadresse.
                            </li>
                            <li>
                                <Box fontWeight='bold'>Profileringsopplysninger: </Box>
                                Informasjon og antakelser vi lager automatisk basert på de andre opplysningene (nevnt ovenfor) vi har om deg,
                                som dine preferanser og interesser og hvilke demografiske grupper du tilhører, med mindre du har motsatt deg 
                                dette. 
                            </li>
                        </ul>
                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>3 Hva bruker vi personopplysningene til</Box>
                        <Box variant='body' component='div' mt={1}>
                            Vi bruker personopplysningene dine til følgende formål: 
                        </Box>
                        <ul>
                            <li>
                                <Box fontWeight='bold'>Tilby nettside:</Box> 
                                Vi kan bruke opplysninger for å tilpasse vår nettside til det tekniske utstyret du bruker. 
                                Vi kan også bruke opplysningene til å tilpasse og forbedre innholdet på vår nettside basert 
                                på dine tilbakemeldinger og innspill. 
                            </li>
                            <li>
                                <Box fontWeight='bold'>Kundekontakt:</Box>
                                Vi lagrer opplysninger som du gir og mottar dersom du har kontakt med oss for å få råd og veiledning. 
                            </li>
                        </ul>
                        <Box variant='body' component='div' mt={1}>
                            Det rettslige grunnlaget for formål 1 og 2 er vår berettigede interesse i å kunne tilby og videreutvikle vår veiledning.
                        </Box>
                        <Box variant='body' component='div' mt={2}>
                            Det rettslige grunnlaget for formål 2 er for å oppfylle vår kontrakt med deg.
                        </Box>
                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>4 Hvem deler vi personopplysninger med</Box>
                        <Box variant='body'  mt={1}>
                            Vi deler personopplysninger med selskaper som leverer tekniske og administrative tjenester til oss, slik som IT-leverandører. Vi har avtaler med disse for å sikre ditt personvern. 
                        </Box>
                        <Box variant='body'  mt={1}>
                            Vi kan utlevere opplysninger i enkelttilfeller, eksempelvis til offentlige myndigheter, ved mistanke om lovbrudd.
                        </Box>
                        <Box variant='body'  mt={1}>
                            Hvis du samtykker, kan vi dele dine personopplysninger med våre samarbeidspartnere som beskrevet i ditt samtykke. Dette kan f.eks. være andre organisasjoner som jobber med sosiale entreprenører.
                        </Box>

                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>5 Hvor lenge lagrer vi personopplysninger</Box>
                        <Box variant='body'  mt={1}>
                            Vi lagrer opplysninger om deg, kun så lenge det er nødvendig for å oppnå formålet de ble innhentet for, 
                            eller dersom vi er pålagt å lagre opplysningene. Opplysningene vil slettes eller anonymiseres når de ikke 
                            lengre er nødvendige for å oppnå formålet. Under er en oversikt over hvor lenge vi lagrer dine opplysninger:
                        </Box>
                        <ul>
                            <li>
                                <Box fontWeight='bold'>Opplysninger om deg:</Box> 
                                Hvis du har registrert deg hos oss, sletter vi opplysningene når du ikke lenger ønsker vår veiledning eller når du selv ber om det.
                            </li>
                            <li>
                                <Box fontWeight='bold'>Opplysninger om bruk på nettsidene: </Box>
                                Vi lagrer informasjon om din bruk av våre nettsider i opptil 6 måneder.
                            </li>
                            <li>
                                <Box fontWeight='bold'>Opplysninger fra partnere:  </Box>
                                Vi lagrer eventuell informasjon fra samarbeidspartnere så lenge du har samtykket. 
                            </li>
                            <li>
                                <Box fontWeight='bold'>Kommunikasjon:  </Box>
                                Vi lagrer kommunikasjon med våre kunder i tre år, med mindre vi trenger å lagre det lenger, 
                                f.eks. i forbindelse med klager eller krav.
                            </li>
                        </ul>
                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>6 Dine rettigheter</Box>
                        <Box variant='body'  mt={1}>
                            Du kan ha rett til å be oss å:
                        </Box>
                        <ul >
                            <li >
                                Gi deg ytterligere informasjon om hvordan vi behandler dine personopplysninger.
                            </li>
                            <li>
                                Gi deg en kopi av personopplysninger om deg.
                            </li>
                            <li>
                                Oppdatere dine personopplysninger.
                            </li>
                            <li>
                                Slette opplysninger som vi ikke lenger har grunnlag til å oppbevare. 
                            </li>
                            <li>
                                Begrense eller stoppe behandlingen av dine opplysninger.
                            </li>
                            <li>
                                Gi deg opplysninger du har gitt til oss i en strukturert, alminnelig anvendt og maskinlesbart format eller, der det er teknisk mulig, be oss om å overføre disse til en annen virksomhet. 
                            </li>
                            <li>
                                Trekke tilbake eventuelle samtykker du har gitt oss. 
                            </li>
                            <li>
                                Vær klar over at det finnes begrensninger i disse rettighetene. Ta gjerne kontakt med oss hvis du vil ha mer informasjon om dette.
                            </li>
                        </ul>
                        <Box variant='body'  mt={2}>
                            Hvis du mener at vi behandler personopplysninger i strid med personopplysningsloven, har du rett til å klage til Datatilsynet. Før du eventuelt gjør dette, vil vi gjerne at du kontakter oss slik at vi kan besvare dine spørsmål eller oppklare eventuelle misforståelser. 
                        </Box>

                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>7 Cookies</Box>
                        <Box variant='body'  mt={1}>
                            Vi bruker ikke cookies på våre nettsider. Din arbeidsøkt vil kun lagres lokalt i din nettleser så lenge du ikke avslutter økten. Ved avsluttet økt blir den informasjonen du har lagt inn slettet.
                        </Box>
                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>8 Endringer i personvernerklæringen</Box>
                        <Box variant='body'  mt={1}>
                            Vi vil kunne oppdatere personvernerklæringen fra tid til annen. Du vil få beskjed om endringer av betydning. Du vil alltid finne siste versjon av vår personvernerklæring på nettsiden. 
                        </Box>
                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>9 Kontaktinformasjon</Box>
                        <Box variant='body'  mt={1}>
                            Hvis du ønsker å komme i kontakt med oss, kan du benytte følgende kontaktinformasjon:
                        </Box>
                        <Box variant='body'  mt={1}>
                            Kontaktperson: Helga Øvsthus Tønder (daglig leder)
                        </Box>
                    
                        <Box variant='body'  mt={1}>
                            E-postadresse: helga@toyenunlimited.no
                        </Box>
                        <Box variant='body'  mt={1}>
                            Adresse: Kolstadgata 1, 0652 Oslo
                        </Box>


                    </Box>
                </Paper>
            </Container>

        </div>
    )
}