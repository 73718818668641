import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

import group47 from "../Images/Landing/group47.png";
import illustrasjoner13 from "../Images/Landing/illustrasjoner13.png";
import ellipse30 from "../Images/Landing/ellipse30.svg";
import illustrasjoner15 from "../Images/Landing/illustrasjoner15.svg";
import vector2 from "../Images/Landing/vector2.svg";
import illustrasjoner14 from "../Images/Landing/illustrasjoner14.png";
import frame798 from "../Images/Main/frame798.svg";
import { Link } from "react-router-dom";

const styles = makeStyles({
  overview: {
    minHeight: "1570px",
    padding: "66px 163.5px 149px 163.5px",
    overflowY: "visible",
  },
  frame800: {
    position: "static",
    width: "100%",
    height: "1538.62px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  frame796: {
    position: "static",
    width: "1078px",
    height: "717.88px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    margin: "0px 17.5px 128px 17.5px",
    // eslint-disable-next-line
    ["@media (max-width:1100px)"]: {
      marginLeft: "-120px",
    },
  },
  frame453: {
    position: "static",
    width: "856px",
    height: "184px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    margin: "0px auto",
  },
  frame453title: {
    position: "static",
    width: "856px",
    height: "80px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "58px",
    lineHeight: "80px",
    textAlign: "center",
    fontFeatureSettings: "ss03 on",
    color: "#24243F",
    variant: "h1",
    margin: "8px 0px",
  },
  frame453info: {
    position: "static",
    width: "855px",
    height: "96px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "22px",
    lineHeight: "32px",
    textAlign: "center",
    fontFeatureSettings: "ss03 on",
    color: "#24243F",
    variant: "h4",
  },
  frame303: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    position: "relative",
    width: "1078px",
    height: "448.88px",
    padding: "0px",
    justifyContent: "space-between",
    marginTop: "-85PX",
    marginLeft: "90px",
    // eslint-disable-next-line
    ["@media (max-width:1110px)"]: {
      marginLeft: "-40px",
    },
  },
  frame450: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "static",
    width: "302px",
    height: "448.88px",
  },
  rectangle13: { marginRight: "65.42px", position: "absolute" },
  illustrationer13: {
    marginRight: "30.44px",
    position: "absolute",
    marginTop: "20.12px",
  },
  frame14: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "302px",
    height: "184px",
    marginTop: "263.12PX",
  },
  frame14map: {
    width: "290px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "48px",
    textAlign: "center",
    variant: "h3",
    fontFeatureSetting: "ss03 on",
  },
  frame14info: {
    marginTop: "8px",
    width: "290px",
    height: "72px",
    fontWeight: "normal",
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    fontFeatureSetting: "ss03 on",
  },
  //Track IT
  frame451: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "static",
    width: "302px",
    height: "448.88px",
  },
  ellipse30: { marginRight: "25.97px", position: "absolute" },
  illustrationer15: {
    marginRight: "24px",
    position: "absolute",
    marginTop: "15.88px",
  },
  frame451T: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "290px",
    height: "112px",
    marginTop: "264.88PX",
    color: "#000000",
    marginBottom: "24px",
  },
  frame451track: {
    width: "290px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "48px",
    textAlign: "center",
    variant: "h3",
    fontFeatureSetting: "ss03 on",
  },
  frame451info: {
    marginTop: "8px",
    width: "290px",
    height: "48px",
    fontWeight: "normal",
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    fontFeatureSetting: "ss03 on",
  },
  //*Tell IT
  frame452: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "static",
    width: "302px",
    height: "448.88px",
  },
  vector2: { marginLeft: "50.42px", position: "absolute" },
  illustrationer14: {
    marginRight: "110.78px",
    position: "absolute",
    marginTop: "25px",
  },
  frame452T: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "290px",
    height: "112px",
    marginTop: "263PX",
    color: "#000000",
    marginBottom: "24px",
  },
  frame452tell: {
    width: "290px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "48px",
    textAlign: "center",
    variant: "h3",
    fontFeatureSetting: "ss03 on",
  },
  frame452info: {
    marginTop: "8px",
    width: "290px",
    height: "48px",
    fontWeight: "normal",
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    fontFeatureSetting: "ss03 on",
  },
  frame450button: {
    borderRadius: "24px",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    variant: "body",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
    margin: "8px 0px",
  },
  frame799: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
  },
  frame799Title: {
    width: "616px",
    height: "32px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "22px",
    lineHeight: "32px",
    variant: "h4",
    fontFeatureSetting: "ss03 on",
    margin: "16px 0px",
  },
  frame799image: {
    position: "static",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-80px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1200px)"]: {
      marginLeft: "-120px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1130px)"]: {
      marginLeft: "-130px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1080px)"]: {
      marginLeft: "-150px",
      "max-width": "1000px",
    },
  },
});
export default function Overview() {
  const classes = styles();
  return (
  
    <div className={classes.overview}>
      <Grid item xs direction="column" className={classes.frame800}>
        <Grid order="0" item xs direction="column" className={classes.frame796}>
          <Grid order="0" item xs className={classes.frame453}>
            <Typography className={classes.frame453title}>
            Velkommen til Vis Det!
            </Typography>
            <Typography className={classes.frame453info}>
            Vis Det! er et praktisk verktøy som er utviklet for å hjelpe deg med å måle effekten arbeidet ditt har på samfunnet - steg for steg!
            </Typography>
          </Grid>
          <Grid order="1" item xs direction="row" className={classes.frame303}>
            {/*MAP IT column grid*/}
            <Grid item xs direction="column" className={classes.frame450}>
              <Grid item xs order="0" className={classes.rectangle13}>
                <img src={group47} alt="vector1" />
              </Grid>
              <Grid item xs order="1" className={classes.illustrationer13}>
                <img src={illustrasjoner13} alt="illustration" />
              </Grid>
              <Grid item xs order="2" className={classes.frame14}>
                <Typography className={classes.frame14map}>Kartlegg Det! </Typography>
                <Typography className={classes.frame14info}>
                Kartlegg historien til prosjektet ditt for å begynne å måle effekten av arbeidet ditt.

                </Typography>
              </Grid>
              <Grid item xs order="3">
                <Button
                  variant="contained"
                  className={classes.frame450button}
                  component={Link}
                  to="/Main/MapHome"
                >
                  Start
                </Button>
              </Grid>
            </Grid>
            {/*TRACK IT column grid*/}
            <Grid
              order="2"
              item
              xs
              direction="column"
              className={classes.frame451}
            >
              <Grid item xs order="0" className={classes.ellipse30}>
                <img src={ellipse30} alt="vector2" />
              </Grid>
              <Grid item xs order="1" className={classes.illustrationer15}>
                <img src={illustrasjoner15} alt="illustration 2" />
              </Grid>
              <Grid item xs order="2" className={classes.frame451T}>
                <Typography className={classes.frame451track}>
                  Undersøk Det!
                </Typography>
                <Typography className={classes.frame451info}>
                  Finn ut hva du skal måle og planlegg hvordan du kan komme i gang med datainnsamlingen.
                </Typography>
              </Grid>
              <Grid item xs order="3">
                <Button
                  variant="contained"
                  className={classes.frame450button}
                  component={Link}
                  to="/Main/TrackHome"
                >
                  Start
                </Button>
              </Grid>
            </Grid>
            {/*TELL IT column grid*/}
            <Grid item xs direction="column" className={classes.frame452}>
              <Grid item xs order="0" className={classes.vector2}>
                <img src={vector2} alt="vector 3" />
              </Grid>
              <Grid item xs order="1" className={classes.illustrationer14}>
                <img src={illustrasjoner14} alt="illustration 14" />
              </Grid>
              <Grid item xs order="2" className={classes.frame452T}>
                <Typography className={classes.frame452tell}>
                Fortell Det!
                </Typography>
                <Typography className={classes.frame452info}>
                Oppsummer og lag en kort rapport om prosjektets samfunnseffekt.
                </Typography>
              </Grid>
              <Grid item xs order="3">
                <Button
                  variant="contained"
                  className={classes.frame450button}
                  component={Link}
                  to="/Main/TellHome"
                >
                  Start
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid order="1" item xs direction="column" className={classes.frame799}>
          <Grid item xs order="0">
            <Typography noWrap className={classes.frame799Title}>
            Detaljer om verktøyet
            </Typography>
          </Grid>
          <Grid item xs order="1" position="static">
            <img
              src={frame798}
              alt=" Different section Summary"
              className={classes.frame799image}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
