import { makeStyles, Box, Paper, Typography, Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
//import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import frame642 from "../../Images/MapIt/MapStepFinal/frame642.svg";
import TrackFinishPdf from "./TrackFinishPdf.js";
import image55 from "../../Images/TrackIt/TrackItCongratulation/image55.png";
import ExitPopupTrackIt from "../ExitDialoge/ExitPopupTrackIt";

const styles = makeStyles({
  MapItCongratulation: {
    position: "relative",
    width: "1440px",
    height: "938px",
  },
  frame233: {
    position: "absolute",
    width: "594px",
    height: "927px",
    top: "10px",
    background: "linear-gradient(0deg, #EFEFEF, #EFEFEF), #F9F9F9",
    zIndex: 2,
  },
  frame642: {
    position: "absolute",
    left: "628px",
    top: "15px",
  },
  frame704: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "524px",
    height: "692px",
    left: "735px",
    top: "245px",
  },
  frame835: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "524px",
    height: "320px",
    margin: "0px 0px 191px 0px",
  },
  frame835info1: {
    position: "relative",
    width: "523px",
    height: "32px",
    variant: "h4",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#24243F",
    marginBottom: "24px",
  },
  frame835info2: {
    position: "relative",
    width: "523px",
    height: "96px",
    variant: "body",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    marginBottom: "16px",
  },
  frame835info3: {
    position: "relative",
    width: "523px",
    height: "120px",
    variant: "body",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    marginBottom: "16px",
  },
  frame702: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "524px",
    height: "244px",
    margin: "0px 0px 48px 0px",
  },
  image43: {
    position: "absolute",
    width: "467.1px",
    height: "661.01",
    left: "63px",
    top: "144px",
  },
  imageStoryBox: {
    position: "absolute",
    width: "514px",
    height: "357.11",
    left: "-695px",
    top: "-300px",
  },
  imageProject: {
    position: "relative",
    top: "130px",
    left: "63px",
    width: "467.1px",
    height: "661.01px",
    filter: "drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.25))",
  },
  imageStory: {
    width: "514px",
    height: "357.11",
  },

  frame66Button: {
    position: "relative",
    border: "none",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#2D86D9",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    textTransform: "none",
    marginBottom: "16px",
    boxShadow: "none",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  frame662: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "524px",
    height: "156px",
  },
  line: {
    position: "static",
    width: "524px",
    height: "2px",
    border: "2px solid #2D86D9",
    background: "#2D86D9",
  },
  frame528: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "470px",
    height: "154px",
    padding: "30px",
    background: "#F4F6F9",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
  },
  frame703: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "524px",
    height: "48px",
    marginTop: "48px",
    justifyContent: "space-between",
  },
  frame703button1: {
    borderRadius: "24px",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    variant: "body",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "black",
    backgroundColor: "white",
  },
  frame703button2: {
    borderRadius: "24px",
    textTransform: "none",
    width: "230px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    variant: "body",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
  },
});

export default function TrackItCongratulation() {
  const classes = styles();

  return (
    <div className={classes.MapItCongratulation}>
      <Paper className={classes.frame233}>
        <img
          id="image55"
          src={image55}
          alt="Plan for Tracking"
          className={classes.imageProject}
        />
      </Paper>
      <Box className={classes.frame642}>
        <img src={frame642} alt="frame Congratulations" />
      </Box>
      <Box className={classes.frame704}>
        <Box className={classes.frame835}>
          <Box component={Typography} className={classes.frame835info1}>
            Din plan for måling av data
          </Box>
          <Box component={Typography} className={classes.frame835info2}>
            ´Din plan for måling av data´er et dokument som oppsummerer hva du
            vil måle, og hvilke første steg du kan ta for å samle inn data.
            Dette er en strålende start for å komme i gang med å samle inn
            riktig type data og operasjonalisere din effektmålingsinnsats.
          </Box>
          <Box component={Typography} className={classes.frame835info3}>
            Videre kan du skrive ut dette og bruke det til å:
            <ul style={{ paddingLeft: "24px", marginTop: "0px" }}>
              <li>
                Identifisere hvem som skal være ansvarlig for datainnsamlingen
              </li>
              <li>Diskutere hvilke ressurser du trenger</li>
              <li>
                Krav til programvare eller abonnementer for datainnsamling
                (f.eks. SurveyMonkey eller Typeform).
              </li>
            </ul>
          </Box>
        </Box>
        <Box className={classes.frame703}>
          <ExitPopupTrackIt />
          <Button
            variant="contained"
            className={classes.frame703button1}
            component={Link}
            to="/Main/TrackItStep2_7"
          >
            Tilbake
          </Button>
          <TrackFinishPdf />
        </Box>
      </Box>
    </div>
  );
}
