import React, { Component } from 'react';
import { Button, withStyles } from '@material-ui/core';
import jsPDF from 'jspdf';
import rectangle221 from '../../Images/TellIt/TellFinishPdf/rectangle221.jpg';
import  frame834 from '../../Images/MapIt/MapFinishPdf/frame834.jpg';
import ellipse30 from '../../Images/TrackIt/TrackStep1Pdf/ellipse30.png'
import Illustrationer15 from '../../Images/TrackIt/TrackStep1Pdf/Illustrationer15.png'

const styles = (theme) => ({
    downloadButton: {
        width: "210px",
        height: "48px",
        border: "2px solid #BBBECC",
        borderRadius: "24px",
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "24px",
        padding: "10px 20px",
        color: "#24243F",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "white",
        },
        marginBottom: '50px'
      },
    frame703button2: {
        borderRadius: "24px",
        textTransform:"none",
        width:"230px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"14px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F'
    }
})

class TellFinishPdf extends Component {
    trackStep14Data;

     constructor(props) {
        super(props);
        this.trackStep14Data = JSON.parse(localStorage.getItem('trackStep14'));
        this.trackStep13Data = JSON.parse(localStorage.getItem('trackStep13'));
        this.state = {
            impact :  "",
            outcome : "",
            indicators  : []
        }
        this.pdfGenerate = this.pdfGenerate.bind(this);
     };
     componentDidMount(){
        if (localStorage.getItem('trackStep14') !== null ) {
            this.setState ( {
                impact :  this.trackStep14Data.impact,
                outcome : this.trackStep14Data.outcome,
                indicators  : this.trackStep14Data.indicators
            })
        } else {
            this.setState ({
                impact : "",
                outcome : "",
                indicators : []   
            })
        };
     }
    pdfGenerate = () => {


        const date = new Date().toLocaleDateString();

        var doc = new jsPDF ('portrait','px', 'a4','false');
        doc.addImage(rectangle221,'JPG',19,19,350,190);
        doc.addImage(ellipse30,'PNG',310,40,100,90);
        doc.addImage(Illustrationer15,'PNG',290,50,110,130);

        doc.setFont(undefined,"bold").setFontSize(12).text(40,130,'UTFORSK DET: STEG 1');
        var projectName1 = doc.splitTextToSize("Prosjektindikatorer",300)
        doc.setFont('h3',"normal").setFontSize(32).text(40,160,projectName1);

        doc.setFont(undefined,"normal").setFontSize(8).text(40,230,'Basert på ditt effektområde, den ene endringen du vil måle, her er indikatorene for sporing.');
        
        doc.setFont(undefined,"normal").setFontSize(8).text(40,250,'Prosjektets effektområde:');
        var impact1 = doc.splitTextToSize(this.props.impact2, 300);
        doc.setFont(undefined,"normal").setFontSize(12).text(40,265,impact1);

        doc.setFont(undefined,"normal").setFontSize(8).text(40,285,'Endringen du valgte å måle:');
        var outcome1 = doc.splitTextToSize(this.props.outcome3, 300);
        doc.setFont(undefined,"normal").setFontSize(12).text(40,300,outcome1);

        doc.setFont(undefined,"normal").setFontSize(8).text(40,320,'Dine indikatorer for måling:');
       // let indicator11 = this.props.indicator4[0];
       /* var length = indicator11.length;  // find an array length
        var str = '';
        for(var i=0; i< length; i++){
            str += indicator11[i];  // concat Array value to a string variable
            if(i < (length-1) ){
                str += "";  // add separator
            }
        }
        */
        var indicator1 = doc.splitTextToSize(this.props.indicator4[0],250);
        doc.setFont(undefined,"normal").setFontSize(12).text(40,335,'1. ');
        doc.setFont(undefined,"normal").setFontSize(12).text(48,335,indicator1);

        var indicator2 = doc.splitTextToSize(this.props.indicator4[1],250);
        doc.setFont(undefined,"normal").setFontSize(12).text(40,355,'2. ');
        doc.setFont(undefined,"normal").setFontSize(12).text(48,355,indicator2);

        var indicator3 = doc.splitTextToSize(this.props.indicator4[2],250);
        doc.setFont(undefined,"normal").setFontSize(12).text(40,375,'3. ');
        doc.setFont(undefined,"normal").setFontSize(12).text(48,375,indicator3);

        doc.setFont(undefined,"bold").setFontSize(10).text(40,420,'Effektområde');
        var area = doc.splitTextToSize("Et effektområde beskriver feltet du arbeider i. Basert på hvordan offentlige tjenester leveres, kan sosiale prosjekter kategoriseres innenfor seks effektområder.",280);
        doc.setFont(undefined,"normal").setFontSize(8).text(120,420,area);

        doc.setFont(undefined,"bold").setFontSize(10).text(40,450,'Endring');
        var outcomes = doc.splitTextToSize("En endring er et mål på forandring som skjer i livsløpet til et prosjekt skapt helt eller delvis av organisasjonen. Denne endringen kan være for mennesker, lokalsamfunn, økonomi eller omgivelsene.",280);
        doc.setFont(undefined,"normal").setFontSize(8).text(120,450,outcomes);

        doc.setFont(undefined,"bold").setFontSize(10).text(40,480,'Indikatorer');
        var indicators = doc.splitTextToSize("Indikatorer forteller om noe har skjedd eller endret seg. F.eks. røde, oransje eller gule blader på trærne er en indikator på høst. Å finne de indikatorene du trenger å måle handler om å identifisere hvordan du skal vite at et mål er oppnådd.",280);
        doc.setFont(undefined,"normal").setFontSize(8).text(120,480,indicators);


        doc.line(40, 580,410, 580);
        doc.addImage(frame834,'JPG',48,592,12,15);
        doc.setFont(undefined,"bold").setFontSize(10).text(63,600, 'Vis Det!');
        doc.setFont(undefined,"bold").setFontSize(8).setTextColor('#656C88').text(91,600, 'laget av Norge Unlimited');
        doc.setFont(undefined,"normal").setFontSize(6).setTextColor('#000000').text(48,610, 'visdet@toyenunlimited.no | www.norgeunlimited.no');
        doc.setFont(undefined,"normal").setFontSize(6).text(370,610, 'Lastet ned '+ date);
        doc.save('UndersøkDetSteg1.pdf');
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Button
                  variant="contained"
                  className={classes.downloadButton}
                  onClick={this.pdfGenerate}
                > 
                 Last ned sammendrag
                </Button>
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true}) (TellFinishPdf)