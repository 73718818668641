import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Popover,
  makeStyles,
} from "@material-ui/core";
import Illustrasjoner10 from "../../Images/Main/Illustrasjoner10.png";
import closePopover from "../../Images/Main/ci_close-big.svg";
import EllipseDudeFull from "../../Images/MapIt/MapStep3/EllipseDudeFull.svg";
import DudeArrow from "../../Images/MapIt/MapStep3/DudeArrow.svg";
import DudeArrow2 from "../../Images/MapIt/MapStep3/DudeArrow2.svg";
import DudeEmptyEllipse from "../../Images/MapIt/MapStep3/DudeEmptyEllipse.svg";

const styles = makeStyles({
  mapItStep1: {
    maxWidth: "1440px",
    // minHeight: "900px",
    // height: "830px",
    // padding: "165px 163.5px 149px 163.5px",
    // overflowY: "visible",
  },
  frame747: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "absolute",
    width: "1440px",
    left: "0px",
    top: "0px",
  },
  frame746: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "155px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-145px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-274px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-346px",
      marginTop: "50px",
    },
  },
  frame745: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    height: "611px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "72px",
  },
  frame743: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0px 70px 0px 70px",
    bottom: "0px",
    position: "sticky",
    width: "1440px",
    height: "85px",
    top: "1526px",

    flexGrow: "1",
    marginBottom: "-100px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "166px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "283px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "329px",
    },
  },
  frame190: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "524px",
    height: "48px",
    left: "735px",
    top: "37px",
    flex: "none",
    order: "1",
    flexGrow: "0",

    // eslint-disable-next-line
    ["@media (min-width:1440px)"]: {
      marginLeft: "30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-10px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-140px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-255px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-300px",
      width: "440px",
    },
  },
  frame235: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "264px",
    height: "48px",
    left: "260px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  nextButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "10px",
    position: "static",
    left: "144px",
    top: "0px",
    background: "#24243F",
    borderRadius: "24px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#24243F",
    },
  },
  backButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",

    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "120px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },

  exitButton: {
    position: "static",
    width: "120px",
    height: "48px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  frame203: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "229px",
    height: "56px",
    left: "101px",
    top: "29px",

    flex: "none",
    order: "1",
    flexGrow: "0",

    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      width: "200px",
    },
  },
  frame203buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",

    position: "static",
    width: "75px",
    height: "24px",
    left: "0px",
    top: "32px",

    borderRadius: "8px",

    flex: "none",
    order: "1",
    flexGrow: "0",
    marginBottom: "12px",
  },
  popupActiveTrue: {
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",

    fontSize: "16px",
    lineHeight: "20px",

    display: "flex",
    alignItems: "center",

    color: "#2D86D9",

    flex: "none",
    order: "0",
    flexGrow: "0",
    marginLeft: "-8px",
    
    textTransform: "none",
    textDecoration: "underline",
    width: '120px'
  },
  popupactivefalse: {
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",

    fontSize: "16px",
    lineHeight: "20px",

    display: "flex",
    alignItems: "center",

    color: "#24243F",

    flex: "none",
    order: "0",
    flexGrow: "0",
    marginLeft: "-8px",
    //marginTop: "17px",
    textTransform: "none",
    textDecoration: "none",
    width: '120px'
  },
  frame203Button: {
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",

    fontSize: "16px",
    lineHeight: "14px",

    display: "flex",
    alignItems: "center",

    color: "#2D86D9",

    flex: "none",
    order: "0",
    flexGrow: "0",
    marginLeft: "-8px",
    //marginTop: "17px",
    textTransform: "none",
    textDecoration: "none",
  },
  frame203Text: {
    position: "static",
    left: "229px",
    top: "24px",
    right: "0px",
    bottom: "0px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",

    color: "#24243F",

    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  frameillustrasjoner10: {
    position: "static",
    left: "85px",
    top: "85px",
    right: "0px",
    bottom: "0px",

    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "10px 10px 0px 16px",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      marginLeft: "-10px",
    },
  },
  exampleGuide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "0px",

    position: "static",
    width: "330px",
    height: "85px",
    left: "70px",
    top: "0px",

    flex: "none",
    order: "0",
    flexGrow: "0",
  },

  frame744: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px 181px 0px 403px",

    position: "static",
    width: "1440px",
    height: "483px",
    left: "0px",
    top: "0px",

    flex: "none",
    order: "1",
    flexGrow: "0",
    margin: "43px 0px",
  },
  frame274: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "524px",
    height: "483px",
    left: "735px",
    top: "0px",

    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "-40px",
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "0px",
    },
  },
  frame271: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "524px",
    height: "307px",
    left: "0px",
    top: "176px",

    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "20px",
  },
  textField: {
    margin: "8px auto",
  },
  group2: {
    position: "static",
    width: "492px",
    height: "163px",
    left: "16px",
    top: "16px",

    flex: "none",
    order: "0",
    alignSelf: "stretch",
    flexGrow: "1",
    margin: "0px 10px",
  },

  frame210: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",

    position: "static",
    width: "524px",
    height: "32px",
    left: "0px",
    top: "0px",
    margin: "24px 0",
  },
  PeopleAndComm: {
    width: "200px",
    height: "24px",
    fontSize: "16px",
    lineHeight: "24px",

    color: "#24243F",

    position: "ABSOLUTE",
    marginLeft: "45px",
  },
  group3: {
    position: "static",
    height: "32px",
    left: "0px",
    top: "0px",

    flex: "none",
    order: "0",
    flexGrow: "1",
    margin: "0px 0px",
  },
  Ellipse30: {
    marginLeft: "-16px",
    marginBottom: "8px",
  },
  Ellipse31: {
    left: "0px",
    top: "0px",
  },
  frame233: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "524px",
    height: "128px",
    left: "0px",
    top: "0px",

    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "48px 0px",
  },
  whatIsYourProjects: {
    position: "static",
    width: "524px",
    height: "24px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    margin: "16px auto",
  },
  whatIsTheMost: {
    position: "static",
    width: "524px",
    height: "24px",
    left: "0px",

    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    margin: "30px 0 10px 0",
  },
  step1: {
    position: "static",
    width: "524px",
    height: "32px",
    left: "0px",
    top: "0px",
    fontSize: "24px",
    lineHeight: "32px",

    color: "#000000",
  },
  frame234: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "302px",
    height: "72px",
    left: "403px",
    top: "0px",

    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px 30px",
  },
  aboutYourProject: {
    position: "static",
    width: "302px",
    height: "48px",
    left: "0px",
    top: "24px",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#24243F",
  },
  AboutStep1: {
    fontWeight: "bold",
    position: "static",
    width: "50px",
    height: "24px",
    left: "0px",
    top: "0px",
    fontSize: "16px",
    lineHeight: "32px",
    color: "#24243F",
  },
  frame590: {
    width: "453.32px",
    height: "290.09px",
    left: "16px",
    top: "132px",
  },
  MapStep1Vector: {
    position: "absolute",
    left: "42px",
    top: "141.19px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-150px",
      //width: '373px',
      left: "127px",
      top: "77px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-100px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-150px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
  },

  frame263: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "45px",
    width: "561px",
    height: "636px",
  },
  frame625: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "471px",
    height: "208px",
    left: "45px",
    top: "45px",
  },
  illustrasjoner10popover: {},
  popoverText1: {
    fontWeight: "bold",
    position: "static",
    width: "471px",
    height: "72px",
    left: "0px",
    top: "136px",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#24243F",
    marginTop: "16px",
  },
  popoverText2Header: {
    fontWeight: "bold",
    position: "static",
    width: "471px",
    height: "72px",
    left: "0px",
    top: "136px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    marginTop: "16px",
  },
  popoverText2: {
    position: "static",
    width: "471px",
    height: "72px",
    left: "0px",
    top: "136px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    marginBottom: "20px",
  },
  popoverText3Header: {
    fontWeight: "bold",
    position: "static",
    width: "471px",
    height: "72px",
    left: "45px",
    top: "285px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    marginTop: "32px",
  },
  popoverText4Header: {
    fontWeight: "bold",
    position: "static",
    width: "471px",
    height: "72px",
    left: "45px",
    top: "285px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    marginTop: "32px",
    marginBottom: "10px",
  },
  popoverText3: {
    position: "static",
    width: "471px",
    height: "24px",
    left: "45px",
    top: "285px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
  },
  popoverText4: {
    position: "static",
    width: "471px",
    height: "144px",
    left: "45px",
    top: "285px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
  },
  popoverText5: {
    position: "static",
    width: "471px",
    height: "100px",
    left: "45px",
    top: "285px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
  },
  closePopover: {
    position: "static",
    marginBottom: "61px",
    marginLeft: "362px",
    cursor: "pointer",
  },
  exitpopover: {
    width: "644px",
    height: "313px",
    padding: "40px",
  },
  frame195: {
    width: "564px",
    height: "33px",
  },
  closeExitPopover: {
    position: "static",
    marginBottom: "61px",
    cursor: "pointer",
  },
  frame193: {
    width: "564px",
    height: "233px",
  },
  frame192: {
    width: "564px",
    height: "137px",
  },
  exitPopoverText1: {
    fontWeight: "bold",
    position: "static",
    width: "524px",
    height: "33px",
    left: "0px",
    top: "136px",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#24243F",
  },
  frameExit235: {
    width: "264px",
    height: "48px",
    marginTop: "48px",
    marginBottom: "48px",
    marginLeft: "310px",
  },
  popoverRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
  },
  addMoreButton: {
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#2D86D9",
    flex: "none",
    order: "0",
    flexGrow: "0",
    textTransform: "none",
    textDecoration: "none",
  },
  removeButton: {
    position: "static",
    fontSize: "16px",
    lineHeight: "24px",

    alignItems: "flex-end",
    color: "#2D86D9",
    flex: "none",
    order: "0",
    flexGrow: "0",
    textTransform: "none",
    textDecoration: "none",
  },
  frameLinks: {
    display: "flex",
  },
  popupHeading1: {
    fontWeight: "bold",
    marginTop: "20px",
  },
  popupText1: {
    marginTop: "20px",
  },
  circleImage: {
    paddingLeft: '30px'
  },
  arrow: {
    paddingTop: '10px'
  }
});

export default function DudePopup3() {
  const classes = styles();

  const [anchor, setAnchor] = useState();
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);

  const handleOpenPopover = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchor(null);
  };
  const activate1 = () => {
    setActive1(true);
    setActive2(false);
    setActive3(false);
  };
  const activate2 = () => {
    setActive1(false);
    setActive2(true);
    setActive3(false);
  };
  const activate3 = () => {
    setActive1(false);
    setActive2(false);
    setActive3(true);
  };
  return (
    <Grid order="1" item xs className={classes.frame203buttonContainer}>
      <Button
        className={classes.frame203Button}
        //aria-describedby={id}
        onClick={handleOpenPopover}
      >
        Klikk her
      </Button>
      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        marginThreshold={20}
        className="popover"
      >
        <Grid item xs className={classes.frame263}>
          <Grid item xs className={classes.frame625}>
            <Grid item xs justifyContent="space-between">
              <img
                src={Illustrasjoner10}
                alt="illustration10"
                className={classes.illustrasjoner10popover}
              />

              <img
                src={closePopover}
                alt="close illustraion"
                className={classes.closePopover}
                onClick={handleClosePopover}
              />
            </Grid>
            <Typography className={classes.popoverText1}>
            Tidslinjer og milepæler
            </Typography>
            <span className={classes.popoverText2Header}>
            Prosjekteksempel: {" "}
            </span>
            <span className={classes.popoverText2}>
              ASPIRE Youth ble grunnlagt for å utvide mulighetene til ungdom gjennom erfaring, veiledning og sterke rollemodeller.
            </span>
            <Grid container>
              <Grid item xs>
               {active1 &&  <img
                  src={EllipseDudeFull}
                  alt="EllipseDudeFull"
                  className={classes.circleImage}
                  onClick={activate1}
                />}
                {!active1 && 
                  <img
                  src={DudeEmptyEllipse}
                  alt="DudeEmptyEllipse"
                  className={classes.circleImage}
                  onClick={activate2}
                />
                }
                <Button className={active1 ? classes.popupActiveTrue : classes.popupactivefalse} onClick={activate1}>
                Milepæl 1: På kort sikt
                </Button>
              </Grid>

              <Grid item xs>
                <img
                  src={DudeArrow}
                  alt="DudeArrow"
                  className={classes.arrow}
                />
              </Grid>
              <Grid item xs>
              {active2 &&  <img
                  src={EllipseDudeFull}
                  alt="EllipseDudeFull"
                  className={classes.circleImage}
                  onClick={activate1}
                />}
                {!active2 && 
                  <img
                  src={DudeEmptyEllipse}
                  alt="DudeEmptyEllipse"
                  className={classes.circleImage}
                  onClick={activate2}
                />
                }
                <Button  className={active2 ? classes.popupActiveTrue : classes.popupactivefalse} onClick={activate2}>
                Milepæl 2: På mellomlang sikt
                </Button>
              </Grid>
              <Grid item xs>
                <img
                  src={DudeArrow2}
                  alt="DudeArrow2"
                  className={classes.arrow}
                />
              </Grid>
              <Grid item xs>
              {active3 &&  <img
                  src={EllipseDudeFull}
                  alt="EllipseDudeFull"
                  className={classes.circleImage}
                  onClick={activate1}
                />}
                {!active3 && 
                  <img
                  src={DudeEmptyEllipse}
                  alt="DudeEmptyEllipse"
                  className={classes.circleImage}
                  onClick={activate2}
                />
                }
                <Button  className={active3 ? classes.popupActiveTrue : classes.popupactivefalse} onClick={activate3}>
                Milepæl 3: På lang sikt
                </Button>
              </Grid>
            </Grid>
            {active1 && (
              <>
                <Typography className={classes.popupHeading1}>
                Når prosjektet har startet, hvilke resultater forventer du å se på kort sikt? Disse kortsiktige resultatene kan ofte tallfestes.
                </Typography>
                <Typography className={classes.popupText1}>
                Vi har registrert 16 ungdommer som en del av prosjektet. I løpet av de første 8 månedene (kort sikt) vil alle ungdommer på programmet vårt 1) ha regelmessige møter med en mentor 2) deltatt på minst 2 workshops 3) deltatt i en gruppeutfordring og 4) ha søkt om praksisplass.
                </Typography>
              </>
            )}
            {active2 && (
              <>
                <Typography className={classes.popupHeading1}>
                Når du går videre, hvilke resultater må prosjektet ditt oppnå på mellomlang sikt for å sikre at det kan oppnå sine langsiktige resultater?
                </Typography>
                <Typography className={classes.popupText1}>
                Etter det første skoleåret (midtveis) forventer vi at ungdom på programmet vil vise 1) forbedret oppmøte på skolen 2) mer motivasjon for å gjøre det bra på skolen og 3) økt tillit til egne evner.
                </Typography>
              </>
            )}
            {active3 && (
              <>
                <Typography className={classes.popupHeading1}>
                Når du planlegger prosjektet ditt, hva er noen av de langsiktige endringene du ønsker å oppnå? Tenk på målgruppen din og spør deg selv hvilken konkret og målbar forskjell prosjektet ditt vil gi dem.
                </Typography>
                <Typography className={classes.popupText1}>
                1) Redusert frafall i deltakende skoler 2) Deltakerne oppfyller kravene til videre utdanning, opplæring eller arbeid 3) Økt tillit til deres egen evne til å lykkes.
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
}
