import {
  Grid,
  Typography,
  TextField,
  Box,
  InputLabel,
  FormControl,
  Select,
  withStyles,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import React, { Component } from "react";
import "../../App.css";
import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { Link } from "react-router-dom";
import MainDialoge from "./MainDialoge";

const styles = (theme) => ({
  frame804: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "856px",
    height: "749px",
    top: "86px",
    marginBottom: "64px",
    zIndex: 0,
  },
  //* 1. Grid name : About us
  frame803: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "856px",
    height: "168px",
    color: "#24243F",
  },
  frame234: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "214px",
    height: "48px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "32px",
    lineHeight: "48px",
    fontFeatureSetting: "ss03 on",
    variant: "h3",
  },
  frame274: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "524px",
    height: "168px",
    left: "118px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    fontFeatureSetting: "ss03 on",
    variant: "body",
  },
  frame274Info: {},
  //* 2. Grid name : Contact
  frame802: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "856px",
    height: "517px",
    marginTop: "64px",
    color: "#24243F",
  },
  frame276: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "214px",
    height: "48px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "32px",
    lineHeight: "48px",
    fontFeatureSetting: "ss03 on",
    variant: "h3",
  },
  frame606: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    width: "524px",
    height: "517px",
    left: "118px",
  },
  frame604: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "445px",
    margin: "0px 0px 24px 0px",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 260,
    maxWidth: 300,
  },
  frame213: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    width: "468px",
    height: "96px",
  },
  frame235: {
    position: "static",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    width: "264px",
    height: "48px",
    margin: "-24px 0px 0px 260px",
  },
  frame235button: {
    borderRadius: "24px",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    variant: "body",
    justifyContent: "center",
    alignItems: "center",
    margin: "32px 0px",
    padding: "10px 20px",
    color: "black",
    backgroundColor: "white",
  },
});

const CustomColorCheckbox = withStyles({
  root: {
    color: "#E5E5E5",
    "&$checked": {
      color: "#7375eb",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class Contact extends Component {
  mainContactData;
  MapStep32Data;
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      section: "",
      message: "",
      share: false,
      postSubmitted: false,
    };

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeSection = this.onChangeSection.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onChangeShare = this.onChangeShare.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }
  // Form event and function
  onChangeName(e) {
    this.setState({ name: e.target.value });
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  onChangeSection(e) {
    this.setState({ section: e.target.value });
  }
  onChangeMessage(e) {
    this.setState({ message: e.target.value });
  }
  onChangeShare(e) {
    this.setState({ share: e.target.checked });
  }

  onSubmit(e) {
    e.preventDefault();
  }

  componentWillUpdate(nextProps, nextState) {
    localStorage.setItem("mainContact", JSON.stringify(nextState));
  }
  componentDidMount() {
    this.mainContactData = JSON.parse(localStorage.getItem("mainContact"));
    if (localStorage.getItem("mainContact")) {
      this.setState({
        name: this.mainContactData.name,
        email: this.mainContactData.email,
        section: this.mainContactData.section,
        message: this.mainContactData.message,
      });
    } else {
      this.setState({
        name: "",
        email: "",
        section: "",
        message: "",
        share: false,
      });
    }

  }


  //*form reset function
  resetForm() {
    this.setState({
      name: "",
      email: "",
      section: "",
      message: "",
      share: false,
    });
  }

  /*  
  //*Select Menu Box Handling Function
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  //* Checkbox state and change handling function
  const [share, setShare] = React.useState(false);

  const handleChangeShare = () => {
    setShare(!share);
  };
  //*form reset function
  const resetForm = () => {
    document.getElementById("contact-form").reset();
  };
  */
  render() {
    const { classes } = this.props;
    const frame274text =
      "I Norge Unlimited tror vi at de som står nærmest et samfunnsproblem er de som er best egnet til å løse det. VIS DET! sikrer at alle som vil skape positive samfunnsendring har verktøyene de trenger for å vise effekten av sitt arbeid. Vi har undersøkt de beste metodene rundt om i verden og prøvd å forenkle dem så mye som mulig. Den digitale versjonen av VIS DET! har blitt til takket være den uvurderlige ekspertisen til våre venner i Accenture.";

    return (
      <div className="contact">
        <Box className={classes.frame804}>
          <Box className={classes.frame803}>
            <Box component={Typography} className={classes.frame234}>
              Om oss
            </Box>
            <Box component={Typography} className={classes.frame274}>
              {frame274text}
            </Box>
          </Box>
          <Box className={classes.frame802}>
            <Box component={Typography} className={classes.frame276}>
              Kontakt
            </Box>
            <Box component={Typography} className={classes.frame606}>
              <form id="contact-form" className={classes.frame806}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.boxStyle}
                      autoComplete="Name"
                      name="Name"
                      variant="outlined"
                      fullWidth
                      id="name"
                      label="Navn"
                      autoFocus
                      value={this.state.name}
                      onChange={this.onChangeName}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="E-post"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      required
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Del
                      </InputLabel>
                      <Select
                        native
                        value={this.state.section}
                        onChange={this.onChangeSection}
                        label="Please select"
                      >
                        <option aria-label="None" value="" />
                        <option value={"Map It"}>Kertlegg det</option>
                        <option value={"Track It"}>Undersøk det</option>
                        <option value={"Tell It"}>Fortell det</option>
                        <option value={"Other"}>Annet</option>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      multiline
                      rows={4}
                      name="message"
                      label="Melding"
                      type="message"
                      id="message"
                      autoComplete="message"
                      value={this.state.message}
                      onChange={this.onChangeMessage}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      style={{ display: "table" }}
                      control={
                        <div style={{ display: "table-cell" }}>
                          <CustomColorCheckbox
                            value={this.state.share}
                            onChange={this.onChangeShare}
                            name="checkMap"
                            color="primary"
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            style={{ transform: "scale(1.5)" }}
                          />
                        </div>
                      }
                      label={
                        <Box ml={3} className={classes.frame213}>
                          <Typography
                            variant="body2"
                            style={{
                              color: "#24243F",
                              fontSize: "16px",
                              lineHeight: "24px",
                              fontWeight: "normal",
                            }}
                          >
                            Jeg vil gjerne dele en kopi av svarene mine med
                            teamet i Norge Unlimited. Jeg forstår at dette kan
                            hjelpe dem med å svare på eventuelle spørsmål jeg
                            har.
                          </Typography>
                          <Typography
                            component={Link}
                            to="/Privacy"
                            style={{
                              color: "#1976D2",
                              fontSize: "16px",
                              lineHeight: "24px",
                              fontWeight: "bold",
                              textDecoration: "none",
                            }}
                            target="_blank" rel="noopener noreferrer"
                          >
                            Se vår personvernerklæring.
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>
                  <Grid item xs className={classes.frame235}>
                    <Button
                      variant="contained"
                      className={classes.frame235button}
                      onClick={this.resetForm}
                    >
                      Slett tekst
                    </Button>
                    <MainDialoge />
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(Contact);
