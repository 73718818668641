import React, { Component } from 'react'
import { withStyles, Typography, Grid, Box, Button} from '@material-ui/core';
import { Link } from 'react-router-dom'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//Emailjs library
import emailjs from 'emailjs-com';
import {  toast } from 'react-toastify';

const styles= (theme) => ({
    frame193:{
        width:'579px',
        height:'708px',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-end',
        justifyContents:'flex-end',
        padding:'0px',
        background: '#FFFFFF',
        border: '1px solid #24243F',
        boxSizing: 'border-box',
        borderRadius: '12px',
        Padding:'40px'
    },
    frame235button: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"14px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        margin:'32px 0px',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F',
        marginLeft:'24px'
      },
    frame195:{
        width:'564px',
        height:'32px',
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-start',
        justifyContents:'space-between',
        padding:'0px',
        margin:'16px 0px'
    },
    closeIcon:{
        marginLeft:'240px',
        cursor: "pointer", 
    },
    frame193Title:{
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '33px',
        variant:'subtitle',
        color:'#000000',
        
    },
    frame193info1:{
        variant:'body',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color:'#24243F',
        marginTop:'16px'
    },
    frame235button2: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"16px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        margin:'0px 24px',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F',
        marginBottom:'16px'
    },
})

class MainDialog extends Component {
    userData;
    MapStep32Data;
    TrackStep14Data;
    TellStep11Data;
    TellStep21Data;
    constructor(props) {
        super(props);
        this.MapStep32Data = JSON.parse(localStorage.getItem("mapStep32"));
        this.TrackStep14Data = JSON.parse(localStorage.getItem("trackStep14"));
        this.TellStep11Data = JSON.parse(localStorage.getItem("tellStep11"));
        this.TellStep21Data = JSON.parse(localStorage.getItem("tellStep21"));

        this.state = {
             open: false,
             projectName: "",
             mainActivity:"",
             workArea:"",
             impact:"",
             outcome:"",
             indicators:[],
             tellProject:"",
             tellOutput:""
        }

        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.emailService = this.emailService.bind(this);
    }
    //PopUp Open function
    handleClickOpen(event) {
        this.setState({open:true});
    }
    //Popup Close function
    handleClose(event) {
        this.setState({open: false});
    };

    componentDidMount(){
        if (localStorage.getItem("mapStep32") === null) {
            this.setState({
                projectName:"",
                mainActivity:"",
                workArea:"",
            })
        } else {
          this.setState({
                projectName: this.MapStep32Data.projectName,
                mainActivity: this.MapStep32Data.mainActivity,
                workArea: this.MapStep32Data.workArea
          })
        };

        if (localStorage.getItem("trackStep14") === null) {
            this.setState({
                impact:"",
                outcome:"",
                indicators:[],
            })
        } else {
          this.setState({
                impact: this.TrackStep14Data.impact,
                outcome: this.TrackStep14Data.outcome,
                indicators: this.TrackStep14Data.indicators,
          })
        };
        if (localStorage.getItem("tellStep11") === null) {
            this.setState({
                tellProject:"",
            })
        } else {
          this.setState({
                tellProject: this.TellStep11Data.projectName,
        })
        };

        if (localStorage.getItem("tellStep21") === null) {
            this.setState({
                tellOutput:""
            })
        } else {
          this.setState({
                tellOutput: this.TellStep21Data.outputsAndResults
        })
        };
    };
    emailService = e => {
        var mainContactData = JSON.parse(localStorage.getItem('mainContact'));
        var email= "";
        var message = "";
        var name = "";
        var section = "";
        var share = false;
        var project = "";
        var activity = "";
        var work = "";
        var impact="";
        var outcome = "";
        var indicators = [];
        var tellProject = "";
        var tellOutput = "";

        if (mainContactData == null) {
            mainContactData = ""
        }
        else{
            email = mainContactData.email;
            message = mainContactData.message;
            name = mainContactData.name;
            section = mainContactData.section;
            share = mainContactData.share;
        };
        //Validation for checking if user has clicked on checkbox and based on that setting the state of sharing variable
        if (share === false) {
            project = "";
            activity = "";
            work = "";
            impact="";
            outcome = "";
            indicators = [];
            tellProject = "";
            tellOutput = "";
    
        } else {
            project = this.state.projectName;
            activity = this.state.mainActivity;
            work = this.state.workArea;
            impact = this.state.impact;
            outcome = this.state.outcome;
            indicators = this.state.indicators;
            tellProject = this.state.tellProject;
            tellOutput = this.state.tellOutput;

        };

        const templateParams = {
            from_name: name,
            to_name: "Team",
            to_email: "visdet@toyenunlimited.no",
            message: message,
            userEmail: email,
            section:   section,
            share:share,
            project: project,
            activity: activity,
            work: work,

            impact : impact,
            outcome : outcome,
            indicators : indicators,
            tellProject : tellProject,
            tellOutput : tellOutput

          };
          
        emailjs.send("service_1eqxy04", "template_u7z9whd", templateParams,"user_iSg6PpR3UP45tdGG8Q14F" )
          .then(
            function(response) {
              toast.success("Your message has successfully sent!", {
                position: toast.POSITION.BOTTOM_CENTER
              });
              console.log("SUCCESS!", response.status, response.text);
            },
            function(err) {
              toast.error("Your message was not able to be sent");
            }
          );
    }  

    render() {
        const {classes} = this.props;
        var mainContactData = JSON.parse(localStorage.getItem('mainContact'));
        var email= "";
        var message = "";
        var section = "";
        if (mainContactData == null) {
            mainContactData = ""
        }
        else{
            email = mainContactData.email;
            message = mainContactData.message;
            section = mainContactData.section;
        }

        return (
            <div >
                <Button variant="contained" 
                    className={classes.frame235button} 
                    onClick={() => { this.handleClickOpen(); this.emailService();}}
                    disabled={!(email && message && section)}
                >
                    Start
                </Button>
                <Dialog  open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" 
                    style=
                        {{border: '1px solid #24243F',
                            boxSizing: 'border-box',
                            borderRadius: '12px'}} >
                    <DialogContent >
                        <Box padding='20px' overflow='visible'>
                            <Grid  item xs direction='row' className={classes.frame195}>
                                <Grid item xs>
                                    <Typography noWrap className={classes.frame193Title} >Sendt inn! </Typography>
                                </Grid>
                                <Grid item xs >
                                    <CloseOutlinedIcon className={classes.closeIcon} onClick={() =>{this.handleClose()}}/>
                                </Grid>
                            </Grid>
                            <hr color='#BBBECC' margin='16px 0px' />
                            <DialogContentText className={classes.frame193info1}>
                            Takk for meldingen! Teamet i Norge Unlimited svarer deg innen 3-4 virkedager.
                            </DialogContentText>
                        </Box>                        
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' className={classes.frame235button2} component={Link} to="/Main" onClick={this.handleClose}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>                
            </div>
        )
    }
}
export default withStyles(styles, {withTheme: true }) (MainDialog);