import React from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import illustrasjoner04 from "../Images/Footer/illustrasjoner04.svg";
import logoFooter from "../Images/Footer/logoFooter.svg";

const style = makeStyles({
  rectangle98: {
    background: "#26503E",
    position: "relative",
    display: "flex",
    top: "51px",
    width: "100%",
    height: "233px",
    flexDirection: "column",
    alignItems: "flex-start",
    // eslint-disable-next-line
    ["@media (max-width:800px)"]: {
      height: "200px",
      position: "absolute",
    },
  },
  illustrationer04: {
    position: "relative",
    display: "flex",
    top: "-55px",
    marginLeft: "150px",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      left: "40px",
      bottom: "30px",
      width: "200px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1200px)"]: {
      marginLeft: "90px",
    },
  },
  frame23: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around",
    position: "relative",
    width: "428px",
    height: "59px",
    top: "50px",
    marginLeft: "20px",
    // eslint-disable-next-line
    ["@media (max-width:950px)"]: {
      width: "300px",
    },
    // eslint-disable-next-line
    ["@media (max-width:750px)"]: {
      width: "200px",
    },
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      marginLeft: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1200px)"]: {
      marginLeft: "0px",
    },
  },
  frame23info: {
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "24px",
    fontFeatureSetting: "ss03 on",
    variant: "h5",
    color: "#FFFFFF",
    // eslint-disable-next-line
    ["@media (max-width:930px)"]: {
      "line-height": "1.5em",
      height: "3em",
      overflow: "hidden",
    },
  },
  frame525: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around",
    alignContent: "space-between",
    gap: "0.5rem",
    padding: "0px",
    position: "absolute",
    width: "101px",
    height: "95px",
    left: "81.81%",
    right: "11.18%",
    top: "76px",
  },
  frame525info: {
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    color: "#FFFFFF",
    justifyContent: "space",
    textDecoration: "inherit",
    "&:hover": {
      textDecoration: "underline",
      color: "#000000",
    },
  },
  logo: {
    width: "22px",
    height: "22px",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      display: "",
    },
  },
  logotext: {
    textDecoration: "inherit",
    color: "white",
    fontSize: 25,
    //fontWeight: 550,
    marginLeft: "17px",
    "&:hover": {
      textDecoration: "underline",
      color: "black",
    },
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      display: "inline-block",
    },
  },
  
  contact: {
    textDecoration: "inherit",
    color: "inherit",
    fontSize: 16,
    fontWeight: 550,
    lineHeight: "24px",
    marginLeft: "1106.7px",
    "&:hover": {
      textDecoration: "underline",
      color: "#f1d39e",
    },
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      display: "inline-block",
    },
  },
});

export default function LandingFooter() {
  const classes = style();
  return (
    <footer className="footer">
      <div className={classes.rectangle98}>
        <Grid container>
          <Grid item>
            <img
              src={illustrasjoner04}
              alt="illustration"
              className={classes.illustrationer04}
            />
          </Grid>
          <Grid item className={classes.frame23}>
            <Grid item order="0">
            <img src={logoFooter} alt="logo" className={classes.logo} />
            <Typography
              noWrap
              className={classes.logotext}
              component={Link}
              to="/"
            >
              Vis Det!{" "}
            </Typography>
            </Grid>
            <Grid item order="1">
              <Typography className={classes.frame23info}>
              Et verktøy lagd av Norge Unlimited{" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.frame525}>
            <Typography
              className={classes.frame525info}
              component={Link}
              to="/LandingContact"
            >
              Kontakt oss
            </Typography>
            <Typography
              className={classes.frame525info}
              component={Link}
              to="/Terms"
              target="_blank" rel="noopener noreferrer"
            >
             Brukervilkår
            </Typography>
            <Typography
              noWrap
              className={classes.frame525info}
              component={Link}
              to="/Privacy"
              target="_blank" rel="noopener noreferrer"
            >
             Personvernerklæring
            </Typography>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}
