import React, { Component } from "react";
import "../../App.css";
import {
  withStyles,
  Typography,
  Grid,
  Box,
  Button,
  Container,
} from "@material-ui/core";
import landing1 from "../Images/Landing/landing1.png";
import scrollDownLine from "../Images/Landing/scrollDownLine.png";
import group51 from "../Images/Landing/group51.png";
import ellipse54 from "../Images/Landing/ellipse54.png";
import ellipse64 from "../Images/Landing/ellipse64.png";
import ellipse55 from "../Images/Landing/ellipse55.png";
import ellipse53 from "../Images/Landing/ellipse53.svg";
import ellipse66 from "../Images/Landing/ellipse66.png";
import maskGroup from "../Images/Landing/maskGroup.png";
import line64 from "../Images/Landing/line64.png";
import illustrasjoner21 from "../Images/Landing/illustrasjoner21.png";
import group47 from "../Images/Landing/group47.png";
import illustrasjoner13 from "../Images/Landing/illustrasjoner13.png";
import ellipse30 from "../Images/Landing/ellipse30.svg";
import illustrasjoner15 from "../Images/Landing/illustrasjoner15.svg";
import vector2 from "../Images/Landing/vector2.svg";
import illustrasjoner14 from "../Images/Landing/illustrasjoner14.png";
import ellipse65 from "../Images/Landing/ellipse65.svg";
import externalLink from "../Images/Landing/externalLink.svg";
import illustrasjoner16 from "../Images/Landing/illustrasjoner16.svg";
import scrollUpLine from "../Images/Landing/scrollUpLine.svg";
import group52 from "../Images/Landing/group52.svg";

import SignUp from "./SignUp.js";
import LandingHeader from "../Header/LandingHeader.js";
import LandingFooter from "../Footer/LandingFooter.js";

const styles = (theme) => ({
  frame283: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    position: "relative",
  },
  image1: {
    position: "relative",
    "max-width": "1298px",
    width: "100%",
  },
  frame290: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "408px",
    color: "white",
    width: "100%",
    height: "264px",
    // eslint-disable-next-line
    ["@media (max-width:1270px)"]: {
      top: "350px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1200px)"]: {
      top: "280px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1100px)"]: {
      top: "250px",
    },
    // eslint-disable-next-line
    ["@media (max-width:950px)"]: {
      top: "200px",
    },
  },
  frame290welcome: {
    width: "690px",
    height: "80px",
    fontWeight: "bold",
    fontSize: "60px",
    lineHeight: "80px",
    textAlign: "center",
    fontFeatureSetting: "ss03 on",
    variant: "h1",
  },
  frame290info: {
    width: "635px",
    height: "96px",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "32px",
    textAlign: "center",
    fontFeatureSetting: "ss03 on",
    margin: "8px 0px",
  },
  frame290button: {
    borderRadius: "24px",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    justifyContent: "center",
    alignItems: "center",
    margin: "32px 0px",
    padding: "10px 20px",
    color: "black",
    backgroundColor: "white",
    // eslint-disable-next-line
    ["@media (max-width:1270px)"]: {
      margin: "25px 0px",
    },
  },
  frame821: {
    position: "absolute",
    width: "100%",
    height: "192.39px",
    top: "710px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //left: "601px",
    // eslint-disable-next-line
    ["@media (max-width:1270px)"]: {
      marginTop: "-15px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1250px)"]: {
      marginTop: "-55px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1180px)"]: {
      marginTop: "-110px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1200px)"]: {
      marginTop: "-100px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1100px)"]: {
      marginTop: "-150px",
    },
  },
  frame821scroll: {
    width: "80px",
    height: "15px",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "15px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.2em",
    color: "white",
    margin: "0px 18px 0px 18px",
  },
  scrollDown: {
    position: "absolute",
    margin: "26px 46.6px 0px 52.5px",
    zIndex: 1,
  },
  frame51: {
    top: "50px",
    position: "absolute",
  },
  frame814: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    width: "1000px",
    height: "3669.2px",
    left: "149px",
    top: "895px",
    position: "static",
  },
  ellipse54: {
    position: "absolute",
    top: "695px",
    left: "107px",
  },
  ellipse64: {
    position: "absolute",
    top: "1831px",
    left: "520px",
  },
  ellipse55: {
    position: "absolute",
    top: "2180px",
    left: "-38px",
  },
  // styling property for sub-main 3rd grid 'order=0'
  frame809: {
    position: "static",
    width: "922px",
    height: "906px",
    top: "895px",
    margin: "128px 39px 64px 39px ",
    // eslint-disable-next-line
    ["@media (max-width:1000px)"]: {
      marginLeft: "-250px",
    },
  },
  // eslint-disable-next-line
  ["@media (max-width:1165px)"]: {
    frame807: {
      marginLeft: "-120px",
      marginTop: "-150px",
    },
    frame691: {
      marginLeft: "-120px",
      marginTop: "-150px",
    },
    ellipse54: {
      marginLeft: "-120px",
      marginTop: "-150px",
    },
    illustrationer21: {
      marginLeft: "-120px",
      marginTop: "-150px",
    },
    frame806: {
      marginLeft: "-120px",
      marginTop: "-150px",
    },
    ellipse64: {
      marginLeft: "-120px",
      marginTop: "-150px",
    },
    frame693: {
      marginLeft: "-90px",
      marginTop: "-150px",
    },
    ellipse55: {
      marginLeft: "-150px",
      marginTop: "-150px",
    },
    frame805: {
      marginLeft: "-150px",
      marginTop: "-150px",
    },
    ellipse53: {
      marginLeft: "-120px",
      marginTop: "-150px",
    },
    frame808: {
      marginLeft: "-120px",
      marginTop: "-150px",
    },
    maskGroup: {
      marginLeft: "-150px",
      marginTop: "-150px",
    },
    frame694: {
      marginLeft: "-100px",
      marginTop: "-150px",
    },
    frame620: {
      marginLeft: "-180px",
      marginTop: "-150px",
    },
    frame812: {
      marginLeft: "-150px",
      marginTop: "-150px",
    },
  },
  frame807: {
    position: "absolute",
    width: "173.67px",
    height: "178px",
    top: "895px",
    left: "188px",
  },
  ellipse66: {
    position: "absolute",
  },
  line64: {
    position: "absolute",
    margin: "105px 84.67px 0px 55px",
  },
  frame691: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "698px",
    height: "304px",
    top: "1030px",
    left: "310px",
    color: "#24243F",
  },
  frame691about: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "48px",
    lineHeight: "64px",
    fontFeatureSetting: "ss03 on",
    color: "#24243F",
    margin: "0px 0px 48px 0px",
  },
  frame691Info1: {
    width: "698px",
    height: "105px",
    fontWeight: "bold",
    fontSize: "23px",
    lineHeight: "32px",
    fontFeatureSetting: "ss03 on",
    variant: "h4",
    margin: "0px 0px 30px 0px",
  },
  frame691Info2: {
    width: "698px",
    height: "72px",
    fontWeight: "bold ",
    fontSize: "16px",
    lineHeight: "24px",
    fontFeatureSetting: "ss03 on",
    variant: "body",
  },
  illustrationer21: {
    position: "absolute",
    width: "574px",
    height: "447px",
    top: "1354px",
    left: "536px",
  },
  // styling property for sub-main 3rd grid 'order=1'
  frame810: {
    position: "static",
    width: "947px",
    height: "1073.22px",
    marginLeft: "26.5px",
    top: "1865px",
  },
  frame806: {
    position: "absolute",
    width: "173.67px",
    height: "178px",
    top: "1880px",
    left: "210px",
  },
  ellipse67: {
    position: "absolute",
  },
  line66: {
    position: "absolute",
    margin: "105px 84.67px 0px 55px",
  },
  frame693: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "698px",
    height: "360px",
    top: "2019px",
    left: "288.5px",
    color: "#24243F",
  },
  frame693work: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "48px",
    lineHeight: "64px",
    fontFeatureSetting: "ss03 on",
    variant: "h2",
    color: "#24243F",
    margin: "0px 0px 48px 0px",
  },
  frame693Info1: {
    width: "698px",
    height: "128px",
    fontWeight: "bold",
    fontSize: "23px",
    lineHeight: "32px",
    fontFeatureSetting: "ss03 on",
    variant: "h4",
    margin: "0px 0px 28px 0px",
  },
  frame693Info2: {
    width: "698px",
    height: "96px",
    fontSize: "16px",
    lineHeight: "24px",
    fontFeatureSetting: "ss03 on",
    variant: "body",
    fontWeight: "bold",
    marginTop: '20px',
    marginBottom: '-20px'
  },
  frame805: {
    position: "absolute",
    width: "941px",
    height: "451.22px",
    top: "2487px",
    left: "181.5px",
  },
  //
  frame445: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    width: "290px",
    height: "401.12px",
    margin: "0px 651px 50.1px 0px",
  },
  rectangle13: { marginRight: "69.42px", position: "absolute" },
  illustrationer13: {
    marginRight: "55.54px",
    position: "absolute",
    marginTop: "29.12px",
  },
  frame428: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "290px",
    height: "128px",
    marginTop: "273.12PX",
  },
  frame428map: {
    width: "290px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "48px",
    textAlign: "center",
    variant: "h4",
    fontFeatureSetting: "ss03 on",
  },
  frame428info: {
    marginTop: "8px",
    width: "290px",
    height: "72px",
    fontWeight: "normal",
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    fontFeatureSetting: "ss03 on",
  },
  frame443: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    width: "290px",
    height: "376.88px",
    marginTop: "53px",
    marginLeft: "326px",
  },
  ellipse30: { marginRight: "41.97px", position: "absolute" },
  illustrationer15: {
    marginRight: "40px",
    position: "absolute",
    marginTop: "20.88px",
  },
  frame443T: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "290px",
    height: "112px",
    marginTop: "264.88PX",
    color: "#000000",
  },
  frame443track: {
    width: "290px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "48px",
    textAlign: "center",
    variant: "h4",
    fontFeatureSetting: "ss03 on",
  },
  frame443info: {
    marginTop: "8px",
    width: "290px",
    height: "48px",
    fontWeight: "normal",
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    fontFeatureSetting: "ss03 on",
  },
  frame609: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    width: "290px",
    height: "357.22px",
    marginTop: "94px",
    marginLeft: "651px",
  },
  vector2: { marginRight: "38.84px", position: "absolute" },
  illustrationer14: {
    marginRight: "127.78px",
    position: "absolute",
    marginTop: "0px",
  },
  frame609T: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "290px",
    height: "112px",
    marginTop: "245.22PX",
    color: "#000000",
  },
  frame609tell: {
    width: "290px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "48px",
    textAlign: "center",
    variant: "h4",
    fontFeatureSetting: "ss03 on",
  },
  frame609info: {
    marginTop: "8px",
    width: "290px",
    height: "48px",
    fontWeight: "normal",
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    fontFeatureSetting: "ss03 on",
  },
  // styling property for sub-main 4th grid 'order=2'
  //* 3 Grid name:something for everyone *//
  frame811: {
    position: "static",
    width: "1000px",
    height: "1091.98px",
    top: "3002.22px",
    margin: "0px 0px 64px 26.5px",
  },
  ellipse65: {
    position: "absolute",
    top: "3031px",
    left: "836px",
  },
  ellipse53: {
    position: "absolute",
    top: "3147px",
    left: "163px",
  },
  frame808: {
    position: "absolute",
    width: "173.67px",
    height: "178px",
    top: "3020.22px",
    left: "200px",
  },
  ellipse68: {
    position: "absolute",
  },
  line67: {
    position: "absolute",
    margin: "105px 84.67px 0px 55px",
  },
  frame694: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "809px",
    height: "288px",
    top: "3162.22px",
    left: "284px",
    color: "#24243F",
  },
  frame694title: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "48px",
    lineHeight: "64px",
    fontFeatureSetting: "ss03 on",
    variant: "h2",
    color: "#24243F",
    margin: "0px 0px 48px 0px",
  },
  frame438: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "809px",
    height: "176px",
    marginTop: "112px",
  },
  frame438info1: {
    width: "809px",
    height: "966px",
    fontWeight: "bold",
    fontSize: "23px",
    lineHeight: "32px",
    fontFeatureSetting: "ss03 on",
    variant: "h4",
  },
  frame438info2: {
    width: "809px",
    height: "64px",
    fontWeight: "bold",
    fontSize: "23px",
    lineHeight: "32px",
    fontFeatureSetting: "ss03 on",
    variant: "h4",
  },
  maskGroup: {
    position: "absolute",
    width: "515px",
    height: "619.98px",
    top: "3474.22px",
    left: "149px",
  },
  frame620: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "469px",
    height: "332.25px",
    top: "3672.22px",
    left: "680px",
    color: "#000000",
  },
  frame620info1: {
    width: "469px",
    height: "340px",
    fontStyle: "italic",
    fontWeight: "normal",
    fontSize: "32px",
    lineHeight: "48px",
    fontFeatureSetting: "ss03 on",
    variant: "body",
    margin: "0px 0px 16px 0px",
  },
  frame439: {
    width: "332.94px",
    height: "28.25px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  frame439info1: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "24px",
    fontFeatureSetting: "ss03 on",
    textAlign: "center",
    variant: "h5",
    color: "#656C88",
    margin: "0px 8px 0px 0px",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: "#243074",
    },
  },
  frame812: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    position: "absolute",
    width: "577px",
    height: "406px",
    top: "4158.2px",
    left: "360.5px",
  },
  illustrationer16: {
    position: "absolute",
    width: "208px",
    height: "278px",
  },
  frame616: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "relative",
    width: "577px",
    height: "112px",
    marginTop: "300px",
  },
  frame616info: {
    width: "577px",
    height: "32px",
    variant: "h4",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "32px",
    textAlign: "center",
    fontFeatureSetting: "ss03 on",
    color: "#000000",
    margin: "0px 0px 32px 0px",
  },
  frame616Button: {
    borderRadius: "24px",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#FFFFFF",
    backgroundColor: "#24243F",
  },
  frame813: {
    position: "absolute",
    width: "103px",
    height: "199px",
    top: "4472px",
    right: "0px",
  },
  group52: {
    position: "absolute",
  },
  scrollDownLine: {
    position: "absolute",
    left: "56.5px",
    top: "86px",
  },
  frame813Action: {
    position: "absolute",
    width: "100px",
    height: "15px",
    display: "flex",
    alignItems: "center",
    top: "184px",
    left: "3px",
  },
  frame813text: {
    variant: "body",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "15px",
    letterSpacing: "0.2em",
    color: "#000000",
    textTransform: "none",
  },
});

class LandingHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    // const open = Boolean(anchorEl);
    // const id = open ? "simple-popover" : undefined;
  }
  handleClickOpen(event) {
    this.setState({ open: true });
  }
  handleClose(event) {
    this.setState({ open: false });
  }

  render() {
    const { classes } = this.props;
    /* Function to scroll page up */
    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    /*Function to scroll down*/
    const scrollDown = () => {
      window.scroll({
        top: document.body.offsetHeight,
        left: 0,
        behavior: "smooth",
      });
    };
    return (
      <div className="App">
        <LandingHeader />
        <Container className="content">
          <div className="landing">
            <Grid container justifyContent="center">
              <Grid
                item
                xs
                container
                direction="column"
                className={classes.frame283}
              >
                <Grid item xs order="0">
                  <Grid item xs>
                    <img
                      src={landing1}
                      alt="Project"
                      className={classes.image1}
                    />
                  </Grid>
                  <Grid item xs className={classes.frame290}>
                    <Typography className={classes.frame290welcome}>
                    Velkommen til VIS DET!
                    </Typography>
                    <Typography className={classes.frame290info}>
                    VIS DET! er et praktisk verktøy som er utviklet for å hjelpe deg med å måle effekten arbeidet ditt har på samfunnet - steg for steg!
                    </Typography>
                    <SignUp />
                  </Grid>
                  <Grid item xs className={classes.frame821}>
                    <Typography className={classes.frame821scroll}>
                    Gå videre
                    </Typography>
                    <img
                      src={scrollDownLine}
                      alt="line"
                      className={classes.scrollDown}
                      onClick={scrollDown}
                    />
                    <img
                      src={group51}
                      alt="group 51"
                      className={classes.frame51}
                    />
                  </Grid>
                </Grid>

                <Grid
                  order="1"
                  item
                  xs
                  className={classes.frame814}
                  direction="column"
                >
                  {/*1 Grid name: What is this about? */}
                  <Grid
                    order="0"
                    item
                    xs
                    direction="column"
                    className={classes.frame809}
                  >
                    <Grid item xs>
                      <img
                        src={ellipse54}
                        alt="ellipse"
                        className={classes.ellipse54}
                      />
                    </Grid>

                    <Grid item xs>
                      <Box className={classes.frame807}>
                        <img
                          src={ellipse66}
                          alt="ellipse66"
                          className={classes.ellipse66}
                        />
                        <img
                          src={line64}
                          alt="line 64"
                          className={classes.line64}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs className={classes.frame691}>
                      <Typography noWrap className={classes.frame691about}>
                      Hva handler dette om?
                      </Typography>
                      <Typography className={classes.frame691Info1}>
                      Hvis du er opptatt av å skape positive samfunnsendringer med din organisasjon eller virksomhet - så er det like viktig å forstå, måle og fortelle om denne effekten - som å ha en ryddig økonomi. 
                      </Typography>
                      <Typography className={classes.frame691Info2}>
                      VIS DET! er et verktøy som hjelper deg med å måle effekten ditt arbeid har på samfunnet. Vektøyet er utviklet gjennom fire års tett samarbeid med  sosiale entreprenører, grunnleggere av ideelle organisasjoner og lokale initiativer, og folk som jobber i offentlige tjenester i Norge.
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <img
                        src={illustrasjoner21}
                        alt="illustration 21"
                        className={classes.illustrationer21}
                      />
                    </Grid>
                    <Grid item xs>
                      <img
                        src={ellipse64}
                        alt="ellipse"
                        className={classes.ellipse64}
                      />
                    </Grid>
                  </Grid>
                  {/*2 Grid name:How does it work and different sections? */}
                  <Grid
                    order="1"
                    item
                    xs
                    direction="column"
                    className={classes.frame810}
                  >
                    <Grid item xs>
                      <img
                        src={ellipse55}
                        alt="ellipse"
                        className={classes.ellipse55}
                      />
                    </Grid>
                    <Grid order="0" item xs className={classes.frame806}>
                      <Box>
                        <img
                          src={ellipse66}
                          alt="ellipse66"
                          className={classes.ellipse67}
                        />
                        <img
                          src={line64}
                          alt="line 64"
                          className={classes.line66}
                        />
                      </Box>
                    </Grid>
                    <Grid order="1" item xs className={classes.frame693}>
                      <Typography noWrap className={classes.frame693work}>
                      Hvordan fungerer det?
                      </Typography>
                      <Typography className={classes.frame693Info1}>
                      At du vet at arbeidet deres fungerer er ikke nok. Du må også samle inn data som viser forskjellen du gjør i samfunnet.
                        <Typography className={classes.frame693Info1}>
                        VIS DET! tar deg gjennom  tre steg som gjør det enklere for deg å undersøke og vise andre hvilken effekt arbeidet ditt faktisk har!
                        </Typography>
                      </Typography>
                      <Typography
                        className={classes.frame693Info2}
                        component="div"
                      >
                        Når vi setter i gang et prosjekt har vi visse forventninger til hvordan ting vil fungere, men det kan bli annerledes i virkeligheten. Gjennom å  {" "}
                        <Box
                          display="inline"
                          fontStyle="italic"
                          fontWeight="fontWeightRegular"
                        >
                          kartlegge
                        </Box>{" "}
                        antagelsene våre og visualise reisen vår, kan vi begynne å{" "}
                        <Box
                          display="inline"
                          fontStyle="italic"
                          fontWeight="fontWeightRegular"
                        >
                          undersøke
                        </Box>{" "}
                        hvilke endringer prosjektet vårt faktisk skaper. Dette hjelper oss med å tenke gjennom, måle og til slutt {" "}
                        <Box
                          display="inline"
                          fontStyle="italic"
                          fontWeight="fontWeightRegular"
                        >
                       fortelle 
                        </Box>{" "}
                        andre om effekten av arbeidet vårt.
                      </Typography>
                    </Grid>
                    <Grid
                      order="2"
                      item
                      xs
                      direction="row"
                      className={classes.frame805}
                    >
                      {/*MAP IT column grid*/}
                      <Grid
                        item
                        xs
                        direction="column"
                        className={classes.frame445}
                      >
                        <Grid item xs order="0" className={classes.rectangle13}>
                          <img src={group47} alt="vector1" />
                        </Grid>
                        <Grid
                          item
                          xs
                          order="1"
                          className={classes.illustrationer13}
                        >
                          <img src={illustrasjoner13} alt="illustration" />
                        </Grid>

                        <Grid item xs order="2" className={classes.frame428}>
                          <Typography className={classes.frame428map}>
                          Kartlegg det! 
                          </Typography>
                          <Typography className={classes.frame428info}>
                          Kartlegg historien til prosjektet ditt 
for å begynne å måle effekten av arbeidet ditt

                          </Typography>
                        </Grid>
                      </Grid>
                      {/*TRACK IT column grid*/}
                      <Grid
                        order="2"
                        item
                        xs
                        direction="column"
                        className={classes.frame443}
                      >
                        <Grid item xs order="0" className={classes.ellipse30}>
                          <img src={ellipse30} alt="vector2" />
                        </Grid>
                        <Grid
                          item
                          xs
                          order="1"
                          className={classes.illustrationer15}
                        >
                          <img src={illustrasjoner15} alt="illustration 2" />
                        </Grid>
                        <Grid item xs order="2" className={classes.frame443T}>
                          <Typography className={classes.frame443track}>
                          Undersøk det!
                          </Typography>
                          <Typography className={classes.frame443info}>
                          Finn ut hva du skal måle og planlegg hvordan du kan komme i gang med datainnsamlingen.
                          </Typography>
                        </Grid>
                      </Grid>
                      {/*TELL IT column grid*/}
                      <Grid
                        item
                        xs
                        direction="column"
                        className={classes.frame609}
                      >
                        <Grid item xs order="0" className={classes.vector2}>
                          <img src={vector2} alt="vector 3" />
                        </Grid>
                        <Grid
                          item
                          xs
                          order="1"
                          className={classes.illustrationer14}
                        >
                          <img src={illustrasjoner14} alt="illustration 14" />
                        </Grid>
                        <Grid item xs order="2" className={classes.frame609T}>
                          <Typography className={classes.frame609tell}>
                          Fortell det!
                          </Typography>
                          <Typography className={classes.frame609info}>
                          Oppsummer og lag en kort rapport om prosjektets samfunnseffekt.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*3 Grid name:Something for everyone */}
                  <Grid
                    order="2"
                    item
                    xs
                    direction="column"
                    className={classes.frame811}
                  >
                    <Grid item xs order="0">
                      <img
                        src={ellipse65}
                        alt="ellipse"
                        className={classes.ellipse65}
                      />
                      <img
                        src={ellipse53}
                        alt="ellipse"
                        className={classes.ellipse53}
                      />
                    </Grid>
                    <Grid order="1" item xs className={classes.frame808}>
                      <Box>
                        <img
                          src={ellipse66}
                          alt="ellipse66"
                          className={classes.ellipse68}
                        />
                        <img
                          src={line64}
                          alt="line 64"
                          className={classes.line67}
                        />
                      </Box>
                    </Grid>
                    <Grid order="2" item xs className={classes.frame694}>
                      <Grid item xs order="0">
                        <Typography className={classes.frame694title}>
                        Noe for alle!
                        </Typography>
                      </Grid>
                      <Grid item xs order="1" className={classes.frame438}>
                        <Typography className={classes.frame438info1}>
                        Uansett om du er en lokal aktivist på prosjektstadiet eller en etablert organisasjon eller virksomhet, kan du dra nytte av å måle samfunnseffekten av arbeidet ditt.
                        </Typography>
                        <Typography className={classes.frame438info2}>
                        Dette verktøyet  inneholder enkle øvelser  som gjør det lettere for deg å forstå, måle og fortelle om effekten arbeidet ditt har på samfunnet.
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid order="3" item xs direction="row">
                      <Grid item xs>
                        <img
                          src={maskGroup}
                          alt="ellipse66"
                          className={classes.maskGroup}
                        />
                      </Grid>
                      <Grid
                        item
                        xs
                        className={classes.frame620}
                        direction="column"
                      >
                        <Grid order="0" item xs>
                          <Typography className={classes.frame620info1}>
                          “Å bruke VIS DET! var som å gå i terapi med prosjektet mitt! Det hjalp meg med å forklare meningen med det vi gjør, og det var motiverende å fokusere på hvordan vi skaper varige endringer.”
                          </Typography>
                        </Grid>
                        <Grid order="1" item xs className={classes.frame439}>
                          <a
                            className={classes.frame439info1}
                            target="_blank"
                            href="https://www.njokobok.com/"
                            rel="noopener noreferrer"
                          >
                            Youssou Diop | Njokobok
                          </a>
                          <img src={externalLink} alt="external link" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*4 Grid name:Are you curious */}
                  <Grid
                    order="3"
                    item
                    xs
                    direction="column"
                    className={classes.frame812}
                  >
                    <Grid
                      item
                      xs
                      order="0"
                      className={classes.illustrationer16}
                    >
                      <img src={illustrasjoner16} alt="illustration 16" />
                    </Grid>
                    <Grid item xs order="0" className={classes.frame616}>
                      <Typography className={classes.frame616info}>
                      Er du nysgjerrig? La oss sette i gang!
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <SignUp />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  order="2"
                  item
                  xs
                  className={classes.frame813}
                  direction="column"
                >
                  {/*1 Grid name: What is this about? */}
                  <Grid order="0" item xs className={classes.group52}>
                    <img src={group52} alt="illustration 16" />
                  </Grid>
                  <Grid order="1" item xs className={classes.scrollDownLine}>
                    <img src={scrollUpLine} alt="illustration 16" />
                  </Grid>
                  <Grid order="2" item xs className={classes.frame813Action}>
                    <Button
                      noWrap
                      variant="text"
                      className={classes.frame813text}
                      onClick={scrollTop}
                    >
                      Tilbake til toppen
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
        <LandingFooter />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(LandingHome);
