import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Popover,
  makeStyles,
} from "@material-ui/core";
import Illustrasjoner10 from "../../Images/Main/Illustrasjoner10.png";
import image12 from "../../Images/TellIt/TellStep1/image12.svg";
import closePopover from "../../Images/Main/ci_close-big.svg";

const styles = makeStyles({
  

  frame203buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    position: "static",
    width: "85px",
    height: "24px",
    left: "0px",
    top: "32px",
    borderRadius: "8px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginBottom: "12px",
  },
  frame203button: {
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#2D86D9",
    flex: "none",
    order: "0",
    flexGrow: "0",
    marginLeft: "-8px",
    marginTop: "17px",
    textTransform: "none",
    textDecoration: "none",
  },

  frame263: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "45px",
    borderRadius: "20px",
    backgroundColor: "#F9FAFE",
    width: "561px",
    height: "950px",
  },
  frame625: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "471px",
    height: "208px",
    left: "45px",
    top: "45px",
  },
  popoverText1: {
    fontWeight: "bold",
    position: "static",
    width: "471px",
    height: "72px",
    left: "0px",
    top: "136px",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#24243F",
    marginTop: "16px",
  },
  popoverText2Header: {
    fontWeight: "bold",
    position: "static",
    width: "471px",
    height: "72px",
    left: "0px",
    top: "136px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    marginTop: "16px",
  },
  popoverText2: {
    position: "static",
    width: "471px",
    height: "72px",
    left: "0px",
    top: "136px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
  },
  closePopover: {
    position: "static",
    marginBottom: "61px",
    marginLeft: "362px",
    cursor: "pointer",
  },
  popover: {
    top: "668px",
    left: "32px",
    borderRadius: "20px",
  },
  navHeader: {
    fontSize: "16px",
    lineHeight: "24px",
    marginRight: "20px",
    marginTop: '40px'
  },
  exampleHeader: {
    fontSize: "32px",
    lineHeight: "48px",
    color: "#24243F",
    margin: "20px 0",
  },
  exampleParagraph: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    height: "180px",
  },
  exampleParagraph4: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    height: "220px",
  },
  image12: {
    marginTop: "10px",
  },
  exampleLink: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2D86D9",
    marginLeft: "-8px",
    marginTop: "17px",
    textTransform: "none",
    textDecoration: "none",
  },
});

export default function DudePopup1() {
  const classes = styles();

  const [anchor, setAnchor] = useState();
  const [example1, setExample1] = useState(true);
  const [example2, setExample2] = useState(false);
  const [example3, setExample3] = useState(false);

  const handleExample1 = () => {
    setExample1(true);
    setExample2(false);
    setExample3(false);
    
  };

  const handleExample2 = () => {
    setExample1(false);
    setExample2(true);
    setExample3(false);
    
  };
  const handleExample3 = () => {
    setExample1(false);
    setExample2(false);
    setExample3(true);
    
  };

 
  const handleOpenPopover = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchor(null);
  };

  return (
    <Grid order="1" item xs className={classes.frame203buttonContainer}>
      <Button
        className={classes.frame203button}
        //aria-describedby={id}
        onClick={handleOpenPopover}
      >
        Klikk her
      </Button>
      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        marginThreshold={20}
        className={classes.popover}
      >
        <Grid item xs className={classes.frame263}>
          <Grid item xs className={classes.frame625}>
            <Grid justifyContent="space-between">
              <img
                src={Illustrasjoner10}
                alt="illustration10"
                className={classes.illustrasjoner10popover}
              />

              <img
                src={closePopover}
                alt="close illustraion"
                className={classes.closePopover}
                onClick={handleClosePopover}
              />
            </Grid>
            <Typography className={classes.popoverText1}>
            Heispitch
            </Typography>

            <span className={classes.popoverText2}>
            En heispitch er et godt format for å skrive en kort og tydelig introduksjon av ditt prosjekt.
            </span>
          </Grid>
          <Grid container>
            <Typography
              className={classes.navHeader}
              style={{
                borderBottom: example1 ? "2px solid black" : "0px solid black",
                fontWeight: example1 ? "bold" : "none",
              }}
              onClick={handleExample1}
            >
              Eksempel 1 
            </Typography>
            <Typography
              className={classes.navHeader}
              style={{
                borderBottom: example2 ? "2px solid black" : "0px solid black",
                fontWeight: example2 ? "bold" : "none",
              }}
              onClick={handleExample2}
            >
          Eksempel 2
            </Typography>
            <Typography
              className={classes.navHeader}
              style={{
                borderBottom: example3 ? "2px solid black" : "0px solid black",
                fontWeight: example3 ? "bold" : "none",
              }}
              onClick={handleExample3}
            >
             Eksempel 3
            </Typography>
          
          </Grid>
          {example1 && (
            <>
              <Typography className={classes.exampleHeader}>
                MIO - Suad Abdi
              </Typography>
              <Typography className={classes.exampleParagraph}>
              MIO er en bruker- og pårørendeorganisasjon for minoriteter som sliter med rus og psykiske utfordringer. MIO driver med informasjonsarbeid for fagfeltet for å sikre målgruppen god og tilrettelagt behandling. MIO jobber også for å redusere skam og tabuer knyttet opp mot rus og psykiske lidelser blant minoriteter med ikke-vestlig bakgrunn.
              </Typography>
              <Button
                className={classes.exampleLink}
                href="https://mioorg.no/"
                target="_blank"
              >
                https://mioorg.no/
              </Button>

              <img
                src={image12}
                alt="close illustraion"
                className={classes.image12}
              />
            </>
          )}
          {example2 && (
            <>
              <Typography className={classes.exampleHeader}>
              HALIMO & Co - Halimo Ali
              </Typography>
              <Typography className={classes.exampleParagraph}>
              Minoritetskvinner med lav eller ingen utdanning står i fare for å havne utenfor arbeidslivet og samfunnet. Halimo & Co skaper en arena hvor minoritetskvinner får mulighet til å utvide og oppgradere sin kompetanse innen design, mønster og søm. De jobber for å etablere en systue i Oslo med bærekraftige lokale tekstilkolleksjoner.
              </Typography>
              <Button
                className={classes.exampleLink}
                href="https://www.halimoco.com/ "
                target="_blank"
              >
                https://www.halimoco.com/ 
              </Button>

              <img
                src={image12}
                alt="close illustraion"
                className={classes.image12}
              />
            </>
          )}
            {example3 && (
            <>
              <Typography className={classes.exampleHeader}>
              NYBY - Fredrik Gulowsen
              </Typography>
              <Typography className={classes.exampleParagraph}>
              Nyby er en plattform for ressurssamarbeid innen helse- og sosialtjenester. De fleste kommuner sliter med knapphet på tid og ressurser. Nyby hjelper dem med å få kontakt med godkjente frivillige for å hjelpe til med praktiske oppgaver og bidra til økt inkludering, redusert ensomhet og en lavterskel vei inn i arbeidslivet.     </Typography>
              <Button
                className={classes.exampleLink}
                href="https://nyby.no/  "
                target="_blank"
              >
                https://nyby.no/  
              </Button>

              <img
                src={image12}
                alt="close illustraion"
                className={classes.image12}
              />
            </>
          )}
            
        </Grid>
      </Popover>
    </Grid>
  );
}
