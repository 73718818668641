import { makeStyles, Box, Paper, Typography, Button, ClickAwayListener } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
//import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import frame642 from '../../Images/MapIt/MapStepFinal/frame642.svg';
import projectNarrative1 from '../../Images/MapIt/MapStepFinal/projectNarrative1.svg';
import storyOfChange from '../../Images/MapIt/MapStepFinal/storyOfChange.svg';
import ExitPopup from '../ExitDialoge/ExitPopup.js'
import MapFinishPdf from './MapFinishPdf.js';
import endringshistorie from '../../Images/MapIt/MapStepFinal/endringshistorie.pdf';

const styles = makeStyles({
    MapItCongratulation: {
        position:'relative',
        width:'1440px',
        height:'1100px',
    },
    frame233:{
        position:'absolute',
        width:'594px',
        height:'1088px',
        top:'10px',
        background:'linear-gradient(0deg, #EFEFEF, #EFEFEF), #F9F9F9',
        
    },
    frame642:{
        position:'absolute',
        left:'628px',
        top:'15px'
    },
    frame704:{
        position:'absolute',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        width:'524px',
        height:'692px',
        left:'735px',
        top:'239px'
    },
    frame835:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        justifyContent:'space-between',
        width:'524px',
        height:'320px',
        margin:'0px 0px 32px 0px'
    },
    frame835info1:{
        position:'relative',
        width:'524px',
        height:'96px',
        fontStyle:'normal',
        fontWeight:'normal',
        fontSize:'16px',
        lineHeight:'24px',
        color:'#24243F',
        marginBottom:'16px'
    },
    frame702:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        width:'524px',
        height:'244px',
        margin:'0px 0px 48px 0px'
    },
    image43:{
        position:'absolute',
        width: '467.1px',
        height: '661.01',
        left: '63px',
        top:'144px'
    },
    imageProjectBox:{
        position:'absolute',
        width: '514px',
        height: '357.11',
        left: '-670px',
        top:'-450px'
    },
    imageStoryBox:{
        position:'absolute',
        width: '514px',
        height: '357.11',
        left: '-695px',
        top:'-300px'
    },
    imageProject:{
        width: '467.1px',
        height: '661.01px',
    },
    imageStory:{
        width: '514px',
        height: '450 px',
    },
    
    frame66Button:{
        position:'relative',
        border:'none',
        fontStyle:'normal',
        fontWeight:'normal',
        fontSize:'20px',
        lineHeight:'24px',
        color:'#2D86D9',
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-start',
        textTransform: "none",
        marginBottom:'16px',
        boxShadow: "none",
        cursor:"pointer",
        "&:hover": {
            boxShadow: "none",
            backgroundColor: "white",
          },
    },
    frame662:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        width:'524px',
        height:'156px',
    },
    line:{
        position:'static',
        width:'524px',
        height:'2px',
        border:'2px solid #2D86D9',
        background:'#2D86D9'
    },
    frame528:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        width:'470px',
        height:'154px',
        padding:'30px',
        background:'#F4F6F9',
        fontStyle:'normal',
        fontWeight:'normal',
        fontSize:'16px',
        lineHeight:'24px',
    },
    frame703:{
        position:'relative',
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-start',
        width:'524px',
        height:'48px',
        marginTop:'80px',
        justifyContent:'space-between'
    },
    frame703button1: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"14px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 20px',
        color:'black',
        backgroundColor:'white'
    },
    frame703button2: {
        borderRadius: "24px",
        textTransform:"none",
        width:"230px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"14px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F'
    },
    downloadButton:{
        borderRadius: "24px",
        textTransform:"none",
        width:"191px",
        height:"48px",
        fontWeight: '',
        fontSize:"15px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 20px',
        marginTop:'0px',

      },
      link: {
          textDecoration: 'none',
          text: 'white',
          color:'#2D86D9'
      }
});


export default function MapItCongratulation() {
    const classes = styles();
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(true);

    const handleClick1 = () => {
        setOpen1((prev) => !prev);
    };

    const handleClickAway1 = () => {
        setOpen1(false);
    };

    const handleClick2 = () => {
        setOpen2((prev) => !prev);
    };

    const handleClickAway2 = () => {
        setOpen2(false);
    };
    
    return (
        <div className={classes.MapItCongratulation}>
            <Paper className={classes.frame233}>
                
            </Paper>
            <Box className={classes.frame642}>
                <img src={frame642} alt="frame Congratulations" />
            </Box>
            <Box className={classes.frame704}>
                <Box className={classes.frame835}>
                    <Box component={Typography} className={classes.frame835info1}>
                    Dette er en milepæl! Basert på svarene dine har vi laget et visuelt kart kalt Din endringshistorie. Vi har også samlet svarene dine i en tekstbasert versjon kalt Din prosjektbeskrivelse. Her kan du laste ned begge dokumentene.
                    </Box>
                    <Box component={Typography} className={classes.frame835info1}>
                       
                        Begge disse dokumentene hjelper deg å huske prosjektets prioriteringer og se om de overordnede målene fremdeles er gjeldende. Det er viktig å oppdatere dem etter hvert som du får mer innsikt i resultatene og effektene av prosjektet ditt.
                    </Box>
                    <Box component={Typography} className={classes.frame835info1}>
                    Du kan bruke dokumentene til å:

                        <ul style={{paddingLeft:'24px',marginTop:'0px'}}>
                            <li>skrive gode finansierings-søknader</li>
                            <li>utvikle kommunikasjonsmateriell som publikasjoner eller presentasjoner</li>
                        </ul>
                    </Box>
                </Box>
                <Box className={classes.frame702}>
                    <ClickAwayListener onClickAway={handleClickAway1}>
                        <Box sx={{ position: 'relative' }}>
                            <button  className={classes.frame66Button} onClick={handleClick1}>
                            Visuell versjon: Din endringshistorie
                            </button>
                            {open1 ? (
                                <Box>
                                    <Box className={classes.imageStoryBox}>
                                        <img  id="myImage2" src={storyOfChange} alt="story Of Change" className={classes.imageStory} />
                                    </Box>
                                    <Box component={Typography} className={classes.frame662} id="message1">
                                        <hr className={classes.line} ></hr>
                                        <Box component={Typography} className={classes.frame528}>
                                            Dette er en visuell historie som beskriver hva du søker 
                                            å oppnå med prosjektet ditt. Dette kartet kan brukes som grunnlag for 
                                            å identifisere hva du bør måle for å vite om de tiltenkte endringene har skjedd.
                                            (Denne delen er under utvikling. Din endringshistorie vil komme i en enklere versjon)
                                            <a href={endringshistorie} target="_blank" rel="noopener noreferrer" download className={classes.link}>
                                                
                                            Last ned
                                                
                                            </a>
                                        </Box>
                                    </Box>
                                </Box>
                            ) :null }
                        </Box>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleClickAway2}>
                        <Box mt={9} sx={{ position: 'relative' }}>
                            <button  mt={3} component={Button} className={classes.frame66Button} onClick={handleClick2}>
                            Tekstversjon: Din prosjektbeskrivelse
                            </button>
                            {open2 ? (
                                <Box>
                                    <Box className={classes.imageProjectBox}>
                                        <img  id="myImage2" src={projectNarrative1} alt="Project Narrative" className={classes.imageProject} />
                                    </Box>
                                    
                                    <Box component={Typography} className={classes.frame662} >
                                        <hr className={classes.line} ></hr>
                                        <Box component={Typography} className={classes.frame528}>
                                        Dette er en tekstversjon som oppsummerer hva du har tenkt å oppnå med prosjektet.  Dette dokumentet fungerer som en introduksjon til formålet med aktivitetene dine og de tiltenkte fordelene for dine målgrupper.
                                        </Box>
                                    </Box>
                                </Box>
                            ) :null }
                        </Box>
                    </ClickAwayListener>
                </Box >
                <Box className={classes.frame703}>
                    <ExitPopup/>
                    <Button 
                        variant="contained" 
                        className={classes.frame703button1} 
                        component={Link}   
                        to="/Main/MapItStep3_5"                                               
                        >
                        Tilbake
                    </Button>
                    <MapFinishPdf />                 
                </Box>
            </Box>
        </div>
    )
}
