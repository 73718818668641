import '../../App.css';
import MainHeader from '../Header/MainHeader.js'
import MainFooter from '../Footer/MainFooter.js'
import { BrowserRouter as Router,Route, Switch,useRouteMatch } from 'react-router-dom';
import MapHome from '../MapIt/MapHome.js';
import TrackHome from '../TrackIt/TrackHome.js';
import TellHome from '../TellIt/TellHome.js';
import Overview from './Overview.js';
import Contact from '../ContactUs/Contact.js'
import Terms from '../Privacy/Terms.js';
import Privacy from '../Privacy/Privacy.js';
import LandingHome from '../Landing/LandingHome.js';
import ScrollToTop from '../ScrollToTop';
import MapItStep1 from "../MapIt/MapStep1/MapStep1.js";
import MapItStep1_2 from "../MapIt/MapStep1/MapStep1_2.js";
import MapItStep1_3 from "../MapIt/MapStep1/MapStep1_3.js";
import MapItStep1_4 from "../MapIt/MapStep1/MapStep1_4.js";
import MapItStep2 from '../MapIt/MapStep2/MapStep2';
import MapItStep3_1 from "../MapIt/MapStep3/MapStep3_1.js";
import MapItStep3_2 from "../MapIt/MapStep3/MapStep3_2.js";
import MapItStep3_3 from "../MapIt/MapStep3/MapStep3_3.js";
import MapItStep3_4 from "../MapIt/MapStep3/MapStep3_4.js";
import MapItStep3_5 from "../MapIt/MapStep3/MapStep3_5.js";
import MapStep1Pdf from '../MapIt/MapStep1/MapStep1Pdf';
import MapItCongratulation from '../MapIt/MapStepFinal/MapItCongratulation';
import TrackItStep1 from "../TrackIt/TrackStep1/TrackStep1.js";
import TrackItStep1_2 from "../TrackIt/TrackStep1/TrackStep1_2.js";
import TrackItStep1_3 from "../TrackIt/TrackStep1/TrackStep1_3.js";
import TrackItStep1_4 from "../TrackIt/TrackStep1/TrackStep1_4.js";
import TrackItStep2_1 from "../TrackIt/TrackStep2/TrackStep2_1.js";
import TrackItStep2_2 from "../TrackIt/TrackStep2/TrackStep2_2.js";
import TrackItStep2_3 from "../TrackIt/TrackStep2/TrackStep2_3.js";
import TrackItStep2_4 from "../TrackIt/TrackStep2/TrackStep2_4.js";
import TrackItStep2_5 from "../TrackIt/TrackStep2/TrackStep2_5.js";
import TrackItStep2_6 from "../TrackIt/TrackStep2/TrackStep2_6.js";
import TrackItStep2_7 from "../TrackIt/TrackStep2/TrackStep2_7.js";
import MapFinalPdf from '../MapIt/MapStepFinal/MapFinalPdf';
import TellItStep1_1 from "../TellIt/TellStep1/TellStep1_1.js";
import TrackItCongratulation from '../TrackIt/TrackStepFinal/TrackItCongratulation';
import TellItStep1_2 from "../TellIt/TellStep1/TellStep1_2.js";
import TellItStep2_1 from "../TellIt/TellStep2/TellStep2_1.js";
import TellItStep2_2 from "../TellIt/TellStep2/TellStep2_2.js";
import TellItStep3_1 from "../TellIt/TellStep3/TellStep3_1.js";
import TellItStep3_2 from "../TellIt/TellStep3/TellStep3_2.js";
import TellItStep4_1 from "../TellIt/TellStep4/TellStep4_1.js";
import TellItCongratulation from '../TellIt/TellStepFinal/TellItCongratulation';
//import TellItStep3_2 from "../TellIt/TellStep3/TellStep3_2.js";
function Main() {
  const { path } = useRouteMatch();
  return (
    <>
    <Router >
      <ScrollToTop />
      <Switch>
          <div className='mainApp'>
         <MainHeader/>
            <div className="mainContent">
                <Route exact path={`${path}/`} component={Overview}/>
                <Route exact path={`${path}/MapHome`} component={MapHome}/>
                <Route exact path={`${path}/MapItStep1`} component={MapItStep1}/>
                <Route exact path={`${path}/MapItStep1_2`} component={MapItStep1_2}/>
                <Route exact path={`${path}/MapItStep1_3`} component={MapItStep1_3}/>
                <Route exact path={`${path}/MapItStep1_4`} component={MapItStep1_4}/>
                <Route exact path={`${path}/MapStep1Pdf`} component={MapStep1Pdf}/>
                <Route exact path={`${path}/MapItStep2`} component={MapItStep2}/>
                <Route exact path={`${path}/MapItStep3_1`} component={MapItStep3_1}/>
                <Route exact path={`${path}/MapItStep3_2`} component={MapItStep3_2}/>
                <Route exact path={`${path}/MapItStep3_3`} component={MapItStep3_3}/>
                <Route exact path={`${path}/MapItStep3_4`} component={MapItStep3_4}/>
                <Route exact path={`${path}/MapItStep3_5`} component={MapItStep3_5}/>
                <Route exact path={`${path}/MapFinalPdf`} component={MapFinalPdf} />
                <Route exact path={`${path}/MapItCongratulation`} component={MapItCongratulation} />
                <Route exact path={`${path}/TrackHome`} component={TrackHome}/>
                <Route exact path={`${path}/TellHome`} component={TellHome}/>
                <Route exact path={`${path}/Contact`} component={Contact}/>
                <Route exact path={`${path}/Terms`} component={Terms}/>
                <Route exact path={`${path}/Privacy`} component={Privacy}/>
                <Route exact path='/Landing' component={LandingHome}/>
                <Route exact path={`${path}/Overview`} component={Overview}/>
                <Route exact path={`${path}/TrackItStep1`} component={TrackItStep1}/>
                <Route exact path={`${path}/TrackItStep1_2`} component={TrackItStep1_2}/>
                <Route exact path={`${path}/TrackItStep1_3`} component={TrackItStep1_3}/>
                <Route exact path={`${path}/TrackItStep1_4`} component={TrackItStep1_4}/>
                <Route exact path={`${path}/TrackItStep2_1`} component={TrackItStep2_1}/>
                <Route exact path={`${path}/TrackItStep2_2`} component={TrackItStep2_2}/>
                <Route exact path={`${path}/TrackItStep2_3`} component={TrackItStep2_3}/>
                <Route exact path={`${path}/TrackItStep2_4`} component={TrackItStep2_4}/>
                <Route exact path={`${path}/TrackItStep2_5`} component={TrackItStep2_5}/>
                <Route exact path={`${path}/TrackItStep2_6`} component={TrackItStep2_6}/>
                <Route exact path={`${path}/TrackItStep2_7`} component={TrackItStep2_7}/>
                <Route exact path={`${path}/TrackItCongratulation`} component={TrackItCongratulation} />
                <Route exact path={`${path}/TellItStep1_1`} component={TellItStep1_1}/>
                <Route exact path={`${path}/TellItStep1_2`} component={TellItStep1_2}/>
                <Route exact path={`${path}/TellItStep2_1`} component={TellItStep2_1}/>
                <Route exact path={`${path}/TellItStep2_2`} component={TellItStep2_2}/>
                <Route exact path={`${path}/TellItStep3_1`} component={TellItStep3_1}/>
                <Route exact path={`${path}/TellItStep3_2`} component={TellItStep3_2}/>
                <Route exact path={`${path}/TellItStep4_1`} component={TellItStep4_1}/>
                <Route exact path={`${path}/TellItCongratulation`} component={TellItCongratulation} />

            </div>
               
            <MainFooter/>
          </div>
      </Switch>
    
    

    </Router>
    </>
  );
};
export default Main;