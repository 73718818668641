import React, { Component } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import Vectortrack2 from "../../Images/TrackIt/TrackStep2/Vectortrack2.svg";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import Ellipse30Track4 from "../../Images/TrackIt/TrackStep2//Ellipse30Track4.svg";
import Ellipse31 from "../../Images/Main/Ellipse31.png";
import DudeTrackIt from "../../Images/TrackIt/TrackStep1/DudeTrackIt.svg";
import Vector from "../../Images/TrackIt/TrackStep2/Vector.svg";
import VectorXblack from "../../Images/TrackIt/TrackStep2/VectorXblack.svg";
import DudePopup1 from './DudePopup1';
import ExitPopupTrackIt from "../ExitDialoge/ExitPopupTrackIt";

const styles = (theme) => ({
  innerContent: {
    width: "100%",
    //Changes by Kishan
    height: "1070px",
    // height: '100%',
    "overflow-x": "visible",
    "overflow-y": "auto",
  },
  frame747: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "1440px",
    left: "0px",
    top: "0px",
    marginTop: "250px",
  },
  frame746: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "50px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-145px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-274px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-310px",
      marginTop: "50px",
    },
  },
  frame745: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    height: "564px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "72px",
  },
  frame743: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0px 70px 0px 70px",
    bottom: "0px",
    position: "sticky",
    width: "1440px",
    height: "85px",
    top: "1526px",
    flexGrow: "1",
    marginBottom: "-300px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "166px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "283px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "329px",
    },
  },
  frame190: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "524px",
    height: "48px",
    left: "735px",
    top: "37px",
    flex: "none",
    order: "1",
    flexGrow: "0",

    // eslint-disable-next-line
    ["@media (min-width:1440px)"]: {
      marginLeft: "30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-10px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-140px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-255px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-300px",
      width: "440px",
    },
  },
  frame235: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "264px",
    height: "48px",
    left: "260px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  nextButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "10px",
    position: "static",
    left: "144px",
    top: "0px",
    background: "#24243F",
    borderRadius: "24px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#24243F",
    },
  },
  backButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "120px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  downloadButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "214px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  exploreButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "141px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
    marginRight: "150px",
  },
  exitButton: {
    position: "static",
    width: "120px",
    height: "48px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  frame203: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "229px",
    height: "56px",
    left: "101px",
    top: "29px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      width: "200px",
    },
  },
  frame203buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    position: "static",
    width: "75px",
    height: "24px",
    left: "0px",
    top: "32px",
    borderRadius: "8px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginBottom: "12px",
  },
  frame203button: {
   
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#2D86D9",
    flex: "none",
    order: "0",
    flexGrow: "0",
    marginLeft: "-8px",
    marginTop: "17px",
    textTransform: "none",
    textDecoration: "none",
  },
  frame203Text: {
   
    position: "static",
    left: "229px",
    top: "24px",
    right: "0px",
    bottom: "0px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  FrameDudeTrackIt: {
    position: "static",
    left: "85px",
    top: "85px",
    right: "0px",
    bottom: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "10px 10px 0px 16px",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      marginLeft: "-10px",
    },
  },
  outerFrameDudeTrackIt: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "0px",
    position: "static",
    width: "330px",
    height: "85px",
    left: "70px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },

  frame744: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px 150px 0px 365px",
    position: "static",
    width: "1440px",
    height: "515px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "43px",
  },
  frame274: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "483px",
    left: "735px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "-40px",
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "0px",
    },
  },
  frame233: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "128px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "48px 0px",
  },
  Paragraph1: {
   
    position: "static",
    width: "524px",
    height: "168px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "30px",
  },
  Paragraph2: {
   
    position: "static",
    width: "524px",
    height: "110px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "50px",
    marginBottom: "10px",
  },

  step1: {
   
    fontWeight: "bold",
    position: "static",
    width: "524px",
    height: "32px",
    left: "0px",
    top: "0px",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
  },
  frame234: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "302px",
    height: "72px",
    left: "403px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px 30px",
  },
  header1: {
   
    position: "static",
    width: "302px",
    height: "48px",
    left: "0px",
    top: "24px",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#24243F",
  },
  headerStep: {
   
    fontWeight: "bold",
    position: "static",
    width: "50px",
    height: "24px",
    left: "0px",
    top: "0px",
    fontSize: "16px",
    lineHeight: "32px",
    color: "#24243F",
  },
  frame590: {
    width: "364.34px",
    height: "262.36px",
    // left: "96px",
    // top: "2.19px",
  },
  Frame590Vector: {
    position: "absolute",
    left: "96px",
    top: "2.19px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-150px",
      left: "127px",
      top: "77px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-100px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-150px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
  },
  popover: {
    pointerEvents: "none",
  },

  popoverOuterContent: {
    position: "absolute",
    width: "319px",
    height: "130px",
    left: "1073px",
    top: "512px",
    background: "#F0DCE4",
    borderRadius: "10px",
  },
  popoverText: {
    fontWeight: "bold",
    position: "static",
    width: "269px",
    height: "102px",

    fontSize: "14px",
    lineHeight: "17px",
    color: "#000000",
  },
  Ellipse30: {
    marginLeft: "-23px",
    marginBottom: "0px",
  },
  Ellipse31: {
    left: "0px",
    top: "0px",
  },
 
  subHeader1: {
    width: "475px",
    height: "24px",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    marginLeft: "-197px",
    marginTop: "3px",
    marginBottom: "45px",
  },
  subHeader2: {
   
    width: "524px",
    height: "24px",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    marginTop: "30px",
  },
  subText1: {
   
    fontWeight: "bold",
  },
  formControl: {
    marginTop: "30px",
    width: "255px",
  },
  contentButtons: {
    marginTop: "155px",
  },
  frame210: {
    marginTop: "50px",
  },
  box1Text: {
   
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    margin: "-2px 10px 0 10px",
    border: "none",
    height: "40px",
  },
  vector: {
    marginBottom: "-3px",
    marginRight: "-5px",
    marginLeft: "7px",
  },
  boxContainer: {
    height: "440px",
    margin: "5px 0",
  },
  optionBoxes: {
    marginRight: "16px",
    marginTop: "8px",
    height: "40px",
    background: "#E3E5E9",
    borderRadius: "20px",

    padding: "10px 20px 10px 20px",
    cursor: "pointer",

    "&:hover": {
      background: "#BCDDF7",
    },
  },

  addYourOwn: {
    marginRight: "10px",
    marginTop: "8px",
    background: "white",
    height: "40px",
    borderRadius: "20px",
    margin: "18px",
    padding: "7px 10px 10px 10px",
    cursor: "pointer",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
  },
  inputBox: {
    height: "40px",
    background: "#E3E5E9",
    borderRadius: "20px",
    textDecoration: "none",
    border: "none",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginRight: "10px",
    marginTop: "8px",
  },
  textInput: {
    padding: "10px 20px 10px 0px",
  },
  addBox: {
   
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    margin: "-2px 10px 0 10px",
    border: "none",
    backgroundImage: `URL(${Vector})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundOrigin: "content-box",
  },

  chosenBoxes: {
    marginRight: "10px",
    marginTop: "8px",
    height: "20px",
    background: "#BBBECC",
    borderRadius: "20px",

    padding: "10px 20px 10px 20px",
    verticalAlign: "center",
    cursor: "pointer",
    backgroundImage: `URL(${VectorXblack})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundOrigin: "content-box",
  },

  inputField: {
    marginRight: "-32px",
    boxSizing: "border-box",
    borderRadius: "20px",
    height: "40px",
    background: "#E3E5E9",
    //width: '100%',
    textDecoration: "none",
    border: "none",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "left",
    fontSize: "14px",
    paddingLeft: "20px",
    lineHeight: "24px",
    "&:active": {
      border: "none",
    },
  },
});

class TrackStep2_4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: "static",
      userArray: ["Spørreundersøkelser"],
      toolsAndMethods: ["Deltakende forskning", "Intervju", "Spørreskjema", "Fokusgruppe"],

      currentSelection: "",
    };
    this.changeDisplay = this.changeDisplay.bind(this);
    this.onAddItem = this.onAddItem.bind(this);
    this.getUserInput = this.getUserInput.bind(this);
    this.onUpdateUserInput = this.onUpdateUserInput.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }
  changeDisplay = () => {
    let { display } = this.state;
    this.setState({ display: display === "static" ? "input" : "static" });
  };
  getUserInput = (e) => {
    this.setState((prevState) => ({
      currentSelection: e.target.value,
    }));
  };
  onUpdateUserInput = (e) => {
    let { display } = this.state;
    this.setState((prevState) => ({
      userArray: [...prevState.userArray, this.state.currentSelection],
      display: display === "input" ? "static" : "input",
    }));
  };

  onAddItem = (e, index) => {
    this.setState((prevState) => ({
      userArray: [...prevState.userArray, e.target.innerHTML],
      toolsAndMethods: this.state.toolsAndMethods.filter(
        (i) => i !== e.target.innerHTML
      ),
    }));
  };
  removeItem(e, index) {
    let temp = e.target.innerText;
    this.setState((prevState) => ({
      userArray: this.state.userArray.filter((i) => i !== temp),
      toolsAndMethods: [...prevState.toolsAndMethods, temp],
    }));
  }
  componentWillUpdate(nextProps, nextState) {
    localStorage.setItem(
      "trackStep24",
      JSON.stringify({
        targets: nextState.userArray,
        toolsAndMethods: nextState.toolsAndMethods,
      })
    );
  }

  componentDidMount() {
    this.trackStep2_4Data = JSON.parse(localStorage.getItem("trackStep24"));
    if (localStorage.getItem("trackStep24")) {
     
      this.setState({
        userArray: this.trackStep2_4Data.targets,
        toolsAndMethods: this.trackStep2_4Data.toolsAndMethods,
      });
    } else {
      this.setState({
        targets: [""],
      });
    }
  }
  render() {
    const { classes } = this.props;
    let { display } = this.state;
    let { toolsAndMethods } = this.state;
    let { userArray } = this.state;

    return (
      <div className={classes.innerContent}>
        <Grid item xs className={classes.frame590}>
          <img
            src={Vectortrack2}
            alt="Vectortrack2"
            className={classes.Frame590Vector}
          />
        </Grid>
        <Grid item xs className={classes.frame747}>
          <Grid item xs className={classes.frame746}>
            <Grid item xs className={classes.frame745}>
              <Grid item xs className={classes.frame744}>
                <Grid item xs className={classes.frame274}>
                  <Grid item xs className={classes.frame233}>
                    <Grid container xs className={classes.frame210}>
                      <Grid item xs>
                        <img
                          src={Ellipse31}
                          alt="Ellipse31"
                          className={classes.Ellipse31}
                        />
                        <img
                          src={Ellipse30Track4}
                          alt="Ellipse30"
                          className={classes.Ellipse30}
                        />{" "}
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.subHeader1}>
                          Select as many tools and methods as relevant
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* dynamic data to come from prev page / client info
                      
                      blue background added on hover (not on select) in app.css*/}
                    <Grid
                      container
                      className={classes.boxContainer}
                      spacing={2}
                    >
                      {userArray.map((target, i) => (
                        <Grid
                          key={i}
                          className={classes.chosenBoxes}
                          value={target}
                          onClick={this.removeItem}
                        >
                          <span value={target} className={classes.textInput}>
                            {target}
                          </span>
                        </Grid>
                      ))}
                      {display === "static" && (
                        <Grid item className={classes.addYourOwn}>
                          <Typography
                            className={classes.addBox}
                            onClick={this.changeDisplay}
                          >
                            <span className={classes.textInput}>
                              Legg til egendefinert{" "}
                            </span>
                          </Typography>
                        </Grid>
                      )}
                      {display === "input" && (
                        <div className={classes.inputBox}>
                          <input
                          maxlength="50"
                            className={classes.inputField}
                            style={{ width: "500px" }}
                         
                            aria-label="empty textarea"
                            placeholder="Legg til egendefinert"
                        
                            type="text"
                            
                            id="aim"
                            value={this.state.currentSelection}
                            onChange={this.getUserInput}
                             // KISHAN help to make this work
                             onkeypress={
                              (this.style = {
                                width:
                                  (this.state.currentSelection.length + 1) * 8 +
                                  "px",
                              })
                            }
                          />
                          <img
                            src={Vector}
                            alt="Plus"
                            className={classes.vector}
                            onClick={this.onUpdateUserInput}
                          />
                        </div>
                      )}
                    </Grid>

                    {toolsAndMethods.length !== 0 && (
                      <Typography className={classes.Paragraph3}>
                        Feel free to select from below:
                      </Typography>
                    )}

                    <Grid container className={classes.boxContainer}>
                      {toolsAndMethods.map((target, i) => (
                        <Grid
                          item
                          className={classes.optionBoxes}
                          key={i}
                          value={target}
                          //className={classes.box1Text}
                          onClick={this.onAddItem}
                        >
                          {target}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs className={classes.frame234}>
                  <Typography noWrap className={classes.headerStep}>
                    Steg 2
                  </Typography>

                  <Typography className={classes.header1}>
                     Plan for måling <br />av data
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs className={classes.frame743}>
              <Grid item xs className={classes.frame190}>
                <Grid order="1" item xs className={classes.frameExitButton}>
                  <ExitPopupTrackIt />
                </Grid>
                <Grid item xs className={classes.frame235}>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235TertiaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.backButton}
                      component={Link}
                      to="/Main/TrackItStep2_3"
                    >
                      Tilbake
                    </Button>
                  </Grid>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235PrimaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.nextButton}
                      component={Link}
                      to="/Main/TrackItStep2_5"
                      type="submit"
                    >
                      Neste
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs className={classes.outerFrameDudeTrackIt}>
                <Grid order="0" item xs className={classes.FrameDudeTrackIt}>
                  <img
                    src={DudeTrackIt}
                    alt="illustrationMan"
                    className={classes.DudeTrackIt}
                  />
                </Grid>
                <Grid item xs className={classes.frame203}>
                  <Typography className={classes.frame203Text}>
                    Spørsmål?
                  </Typography>

                  <DudePopup1 />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TrackStep2_4);
