import React, { Component } from "react";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import Vectortrack2 from "../../Images/TrackIt/TrackStep2/Vectortrack2.svg";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import Ellipse30Track2 from "../../Images/TrackIt/TrackStep2/Ellipse30Track2.svg";
import Ellipse31 from "../../Images/Main/Ellipse31.png";
import DudeTrackIt from "../../Images/TrackIt/TrackStep1/DudeTrackIt.svg";
import ExitPopupTrackIt from "../ExitDialoge/ExitPopupTrackIt";

const styles = (theme) => ({
  innerContent: {
    width: "100%",
    //Changes by Kishan
    height: "1070px",
    // height: '100%',
    "overflow-x": "visible",
    "overflow-y": "auto",
  },
  frame747: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "1440px",
    left: "0px",
    top: "0px",
    marginTop: "250px",
  },
  frame746: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "50px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-145px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-274px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-310px",
      marginTop: "50px",
    },
  },
  frame745: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    height: "564px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "72px",
  },
  frame743: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0px 70px 0px 70px",
    bottom: "0px",
    position: "sticky",
    width: "1440px",
    height: "85px",
    top: "1526px",
    flexGrow: "1",
    marginBottom: "-300px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "166px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "283px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "329px",
    },
  },
  frame190: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "524px",
    height: "48px",
    left: "735px",
    top: "37px",
    flex: "none",
    order: "1",
    flexGrow: "0",

    // eslint-disable-next-line
    ["@media (min-width:1440px)"]: {
      marginLeft: "30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-10px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-140px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-255px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-300px",
      width: "440px",
    },
  },
  frame235: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "264px",
    height: "48px",
    left: "260px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  nextButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "10px",
    position: "static",
    left: "144px",
    top: "0px",
    background: "#24243F",
    borderRadius: "24px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#24243F",
    },
  },
  backButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "120px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  downloadButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "214px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  exploreButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "141px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
    marginRight: "150px",
  },
  exitButton: {
    position: "static",
    width: "120px",
    height: "48px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  frame203: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "229px",
    height: "56px",
    left: "101px",
    top: "29px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      width: "200px",
    },
  },
  frame203buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    position: "static",
    width: "75px",
    height: "24px",
    left: "0px",
    top: "32px",
    borderRadius: "8px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginBottom: "12px",
  },
  frame203button: {
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#2D86D9",
    flex: "none",
    order: "0",
    flexGrow: "0",
    marginLeft: "-8px",
    marginTop: "17px",
    textTransform: "none",
    textDecoration: "none",
  },
  frame203Text: {
    position: "static",
    left: "229px",
    top: "24px",
    right: "0px",
    bottom: "0px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  FrameDudeTrackIt: {
    position: "static",
    left: "85px",
    top: "85px",
    right: "0px",
    bottom: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "10px 10px 0px 16px",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      marginLeft: "-10px",
    },
  },
  outerFrameDudeTrackIt: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "0px",
    position: "static",
    width: "330px",
    height: "85px",
    left: "70px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },

  frame744: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px 150px 0px 365px",
    position: "static",
    width: "1440px",
    height: "515px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "43px",
  },
  frame274: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "483px",
    left: "735px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "-40px",
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "0px",
    },
  },
  frame233: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "128px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "48px 0px",
  },
  Paragraph1: {
    position: "static",
    width: "524px",
    height: "168px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "30px",
    marginBottom: "20px",
  },
  Paragraph2: {
    position: "static",
    width: "524px",
    height: "48px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "30px",
    marginBottom: "40px",
  },

  step1: {
    fontWeight: "bold",
    position: "static",
    width: "524px",
    height: "32px",
    left: "0px",
    top: "0px",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
  },
  frame234: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "302px",
    height: "72px",
    left: "403px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px 30px",
  },
  header1: {
    position: "static",
    width: "302px",
    height: "48px",
    left: "0px",
    top: "24px",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#24243F",
  },
  headerStep: {
    fontWeight: "bold",
    position: "static",
    width: "50px",
    height: "24px",
    left: "0px",
    top: "0px",
    fontSize: "16px",
    lineHeight: "32px",
    color: "#24243F",
  },
  frame590: {
    width: "364.34px",
    height: "262.36px",
    // left: "96px",
    // top: "2.19px",
  },
  Frame590Vector: {
    position: "absolute",
    left: "96px",
    top: "2.19px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-150px",
      left: "127px",
      top: "77px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-100px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-150px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
  },
  popover: {
    pointerEvents: "none",
  },

  popoverOuterContent: {
    position: "absolute",
    width: "319px",
    height: "130px",
    left: "1073px",
    top: "512px",
    background: "#F0DCE4",
    borderRadius: "10px",
  },
  popoverText: {
    fontWeight: "bold",
    position: "static",
    width: "269px",
    height: "102px",

    fontSize: "14px",
    lineHeight: "17px",
    color: "#000000",
  },
  Ellipse30: {
    marginLeft: "-16px",
    marginBottom: "12px",
  },
  Ellipse31: {
    left: "0px",
    top: "0px",
  },

  subHeader1: {
    width: "250px",
    height: "24px",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    marginLeft: "-197px",
    marginTop: "4px",
  },
  subHeader2: {
    width: "524px",
    height: "24px",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    marginTop: "30px",
  },
  subText1: {
    fontWeight: "bold",
  },
  formControl: {
    marginTop: "30px",
    width: "255px",
  },
  contentButtons: {
    marginTop: "155px",
  },
  frame210: {
    marginTop: "50px",
  },
  box1: {
    //background: '#E3E5E9',
    borderRadius: "20px",
    margin: "8px 0",
    padding: "10px",
    //cursor: 'pointer',
    textTransform: "none",
    background: "#BCDDF7",
  },
  box1Text: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    margin: "0 10px",
  },
  box2: {
    //width: "164px",
    height: "40px",
    background: "#E3E5E9",
    borderRadius: "20px",
    margin: "8px 0",
    padding: "10px",
    cursor: "pointer",
  },
  box2Text: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    margin: "0 10px",
  },
  box3: {
    //width: "164px",
    height: "40px",
    background: "#E3E5E9",
    borderRadius: "20px",
    margin: "8px 0",
    padding: "10px",
    cursor: "pointer",
  },
  box3Text: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    margin: "0 10px",
  },
  listItem: {
    marginBottom: "10px",
  },
  liText1: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    marginBottom: "10px",
  },
});

class TrackStep2_2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: "static",
      specificOutcomeOptions: [
        "Trener regelmessig",
        " Adresserer problemer med misburuk av rusmidler",
        "Spiser og sover godt",

        "Forbedret motstandsdyktighet",
        "Er generelt tilfreds med livet",
        "I stand til å håndtere mental helse",

        "Har passende bosituasjon i god stand",
        "Forbedret tilgang til tjenester og fasiliteter",
        "Forbedret tilgang til finansielle produkter og tjenester",

        "Forbedret støtte for familiemedlemmer, partnere, hovedforsørgere og venner av dem med spesifikke behov",
        "Forbedret støtte for familier, barn og unge",
        "Blir er mer aktivt medlem av lokalsamfunnet",

        "Har et passende arbeid",
        "Har opprettholdt arbeidsforhold",
        "Forbedret mellommenneskelige og sosiale ferdigheter",

        "Er i passende utdanning eller opplæring	",
        "Forbedrede karakterer",
        "Forbedret generell forberedelse til arbeid",
      ],
      indicators: {
        0: [
          "5-18 år - 60 minutter moderat til intens trening hver dag",
          "16-64 år - 150 minutter moderat aerob trening hver uke",
          "65+ år - 150 minutter moderat aktivitet hver uke",
        ],
        1: [
          " Røyker ikke",
          "Ikke helseskadelig inntak av alkohol (mer enn 4 ganger per uke) eller drikker store mengder (mer enn 7 eneter hver gang de drikker)",
          "Bruker ikke andre rusmidler",
        ],
        2: [
          "Spiser næringsrik mat hver dag",
          "Spiser 5 porsjoner frukt og grønt hver dag",
          "Har regelmessig og tilstrekkelig nattesøvn",
        ],
        3: [
          "Har utviklet forbedret motstandsdyktighet, mot, og besluttsomhet (inkludert pågangsmot til å lykkes)",
          "Har utviklet kapasitet til å hanskes med vanskeligheter og stress",
          "Har redusert stress",
        ],
        4: [
          "Rapporterer forbedret livssituasjon og trivsel",
          "Føler de fyller livet med mening",
          "Egenvurdering av livskvalitet",
        ],
        5: [
          "Erfarer en reduksjon i problemer med mental helse",
          "Kan opprettholde god mental helse",
          "Kan håndtere sin mentale tilstand",
        ],
        6: [
          "Har et godt vedlikeholdt hjem",
          "Har en bopel utstyrt med alle nødvendige møbler og utstyr",
          "Er tilfreds med bosituasjon",
        ],
        7: [
          "Forbedret tilgang på butikker som selger rimelige nødvendige produkter og tjenester",
          "Forbedret tilgang på god og rimelig offentlig transport",
          "Forbedret tilgang på samfunnsinfrastruktur og –ressurser",
        ],
        8: [
          "Forbedret tilgang på tilrettelagt økonomisk rådgivning",
          "Bruker lån/kreditt på en informert og passende måte",
          "Har en bankkonto",
        ],
        9: [
          "Familiemedlemmer, partnere, forsørgere og venner av de med spesifikke behov har tilgang til og bruker tilstrekkelig spesialistråd, rådgivning og støtte",
          "Familiemedlemmer, partnere, forsørgere og venner av de med spesifikke behov er tilfreds med støtten som gis",
          "Familiemedlemmer, partnere og forsørgere føler de er i stand til å leve sine egne liv",
        ],
        10: [
          "Har tilgang på barnevernstjenester",
          "Har tilgang på foreldrekurs og veiledning",
          "Forbedrede relasjoner mellom foreldre og barn",
        ],
        11: [
          "Tid dedikert til frivillig eller ubetalt jelp eller arbeid for lokale, nasjonale eller internasjonale organisasjoner eller veldedighet",
          "Deltar i avgjørelser i lokalsamfunnet",
          "Har økt interaksjon med lokale kampanjer",
        ],
        12: [
          "Er i en deltidsstilling (mindre enn 16 timer per uke)",
          "Har en fulltidsstilling (mer enn 35 timer per uke)",
          "Annet nivå av oppmøte (i antall timer per uke)",
        ],
        13: [
          "I 6, 12, og 24 måneder",
          "Har permanent arbeidskontrakt",
          "Trives på jobb",
        ],
        14: [
          "Har utviklet kommunikasjonsferdigheter",
          "Har utviklet samarbeidsevner og ferdigheter innen relasjonsbygging",
          "Har utviklet ferdigheter innen selvbevissthet",
        ],
        15: [
          "Er i fulltids utdanning eller opplæring",
          "Er i deltids utdanning eller opplæring",
          "Andre nivåer av oppmøte (i antall timer per uke)",
        ],
        16: [
          "Har startet høyere grad av utdanning (diplom, grad osv.)",
          "Har fullført en høyere grad av utdanning (diplom, grad osv.)",
          "Nivå av oppmøte oppnådd",
        ],
        17: [
          "Forbedret tallforståelse",
          "Forbedrede leseferdigheter",
          "Forbedrede IKT-ferdigheter",
        ],
      },
      outcome: "",
      outcomes: [],
      checked1: false,
      thisIndicators: [],
      values: "",
    };
  }
  trackStep2_2Data;

  componentWillUpdate(nextProps, nextState) {
    localStorage.setItem(
      "trackStep22",
      JSON.stringify({
        outcome: nextState.outcome,
        outcomes: nextState.outcomes,
        thisIndicators: nextState.thisIndicators,
      })
    );
  }
  componentDidMount() {
    let tempImpact = JSON.parse(localStorage.getItem("trackStep21"));

    //this.trackStep2_2Data = JSON.parse(localStorage.getItem("trackStep22"))

    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[0]) {
      this.setState({
        thisIndicators: this.state.indicators[0],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[1]) {
      this.setState({
        thisIndicators: this.state.indicators[1],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[2]) {
      this.setState({
        thisIndicators: this.state.indicators[2],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[3]) {
      this.setState({
        thisIndicators: this.state.indicators[3],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[4]) {
      this.setState({
        thisIndicators: this.state.indicators[4],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[5]) {
      this.setState({
        thisIndicators: this.state.indicators[5],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[6]) {
      this.setState({
        thisIndicators: this.state.indicators[6],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[7]) {
      this.setState({
        thisIndicators: this.state.indicators[7],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[8]) {
      this.setState({
        thisIndicators: this.state.indicators[8],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[9]) {
      this.setState({
        thisIndicators: this.state.indicators[9],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[10]) {
      this.setState({
        thisIndicators: this.state.indicators[10],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[11]) {
      this.setState({
        thisIndicators: this.state.indicators[11],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[12]) {
      this.setState({
        thisIndicators: this.state.indicators[12],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[13]) {
      this.setState({
        thisIndicators: this.state.indicators[13],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[14]) {
      this.setState({
        thisIndicators: this.state.indicators[14],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[15]) {
      this.setState({
        thisIndicators: this.state.indicators[12],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[16]) {
      this.setState({
        thisIndicators: this.state.indicators[13],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
    if (tempImpact.chosenOutcome === this.state.specificOutcomeOptions[17]) {
      this.setState({
        thisIndicators: this.state.indicators[14],
        outcome: tempImpact.chosenOutcome,
        outcomes: tempImpact.outcomes,
      });
    }
  }
  render() {
    const { classes } = this.props;
    const { outcome } = this.state;
    const { indicators } = this.state;
    const { specificOutcomeOptions } = this.state;
    return (
      <div className={classes.innerContent}>
        <Grid item xs className={classes.frame590}>
          <img
            src={Vectortrack2}
            alt="Vectortrack2"
            className={classes.Frame590Vector}
          />
        </Grid>
        <Grid item xs className={classes.frame747}>
          <Grid item xs className={classes.frame746}>
            <Grid item xs className={classes.frame745}>
              <Grid item xs className={classes.frame744}>
                <Grid item xs className={classes.frame274}>
                  <Grid item xs className={classes.frame233}>
                    <Grid container xs className={classes.frame210}>
                      <Grid item xs>
                        <img
                          src={Ellipse31}
                          alt="Ellipse31"
                          className={classes.Ellipse31}
                        />
                        <img
                          src={Ellipse30Track2}
                          alt="Ellipse30"
                          className={classes.Ellipse30}
                        />{" "}
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.subHeader1}>
                        Gjør deg klar for å måle!
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography className={classes.Paragraph1}>
                    Du har valgt å måle følgende endringer:
                    </Typography>

                    <Box className={classes.box1}>
                      <Typography className={classes.box1Text}>
                        {outcome}
                      </Typography>
                    </Box>

                    <Typography className={classes.Paragraph2}>
                    Det er en god tommelfingerregel å ha minst tre indikatorer for å måle en endring. Det betyr at vi vil måle følgende indikatorer:
                 
                      <span>
                        <ol className={classes.subText1}>
                          {outcome === specificOutcomeOptions[0] &&
                            indicators[0].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[1] &&
                            indicators[1].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[2] &&
                            indicators[2].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[3] &&
                            indicators[3].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[4] &&
                            indicators[4].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[5] &&
                            indicators[5].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[6] &&
                            indicators[6].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[7] &&
                            indicators[7].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[8] &&
                            indicators[8].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[9] &&
                            indicators[9].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[10] &&
                            indicators[10].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[11] &&
                            indicators[11].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[12] &&
                            indicators[12].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[13] &&
                            indicators[13].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[14] &&
                            indicators[14].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[15] &&
                            indicators[15].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[16] &&
                            indicators[16].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                          {outcome === specificOutcomeOptions[17] &&
                            indicators[17].map((item) => (
                              <li
                                className={classes.liText1}
                                key={Math.random()}
                              >
                                {item}
                              </li>
                            ))}
                        </ol>
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs className={classes.frame234}>
                  <Typography noWrap className={classes.headerStep}>
                    Steg 2
                  </Typography>

                  <Typography className={classes.header1}>
                     Plan for måling <br />av data
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs className={classes.frame743}>
              <Grid item xs className={classes.frame190}>
                <Grid order="1" item xs className={classes.frameExitButton}>
                  <ExitPopupTrackIt />
                </Grid>
                <Grid item xs className={classes.frame235}>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235TertiaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.backButton}
                      component={Link}
                      to="/Main/TrackItStep2_1"
                    >
                      Tilbake
                    </Button>
                  </Grid>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235PrimaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.nextButton}
                      component={Link}
                      to="/Main/TrackItStep2_3"
                      type="submit"
                    >
                      Neste
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs className={classes.outerFrameDudeTrackIt}>
                <Grid order="0" item xs className={classes.FrameDudeTrackIt}>
                  <img
                    src={DudeTrackIt}
                    alt="illustrationMan"
                    className={classes.DudeTrackIt}
                  />
                </Grid>
                <Grid item xs className={classes.frame203}>
                  <Typography className={classes.frame203Text}>
                    Spørsmål?
                  </Typography>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.frame203buttonContainer}
                  >
                    <Button
                      className={classes.frame203button}
                      component={Link}
                      to="/Main/Contact"
                    >
                       Kontakt Norge Unlimited
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TrackStep2_2);
