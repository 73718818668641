import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import LandingContact from "./Components/ContactUs/LandingContact.js";
import LandingHome from "./Components/Landing/LandingHome.js";
import Main from "./Components/Main/Main.js";
import ScrollToTop from "./Components/ScrollToTop";
import Terms from './Components/Privacy/Terms.js';
import Privacy from "./Components/Privacy/Privacy.js";

function App() {
  return (
    
      <Router>
        <ScrollToTop />
        <Switch>
          <div>
            <Route exact path="/" component={LandingHome} />
            <Route exact path="/LandingContact" component={LandingContact} />
            <Route  path="/Main">
              <Main />
            </Route>
            <Route  path="/Terms" component={Terms}/>
            <Route  path="/Privacy" component={Privacy}/>

          </div>
        </Switch>
      </Router>
   
  );
}
export default App;
