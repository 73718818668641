import React, { Component } from 'react';
import { Button, withStyles } from '@material-ui/core';
import jsPDF from 'jspdf';
import rectangle221 from '../../Images/TellIt/TellFinishPdf/rectangle221.jpg';
import  frame834 from '../../Images/MapIt/MapFinishPdf/frame834.jpg';
import ellipse30 from '../../Images/TrackIt/TrackStep1Pdf/ellipse30.png'
import Illustrationer15 from '../../Images/TrackIt/TrackStep1Pdf/Illustrationer15.png'

import  Illustrationer05 from '../../Images/MapIt/MapFinishPdf/Illustrationer05.jpg'
import { Link } from 'react-router-dom'


const styles = (theme) => ({
    downloadButton: {
        width: "190px",
        height: "48px",
        border: "2px solid #BBBECC",
        borderRadius: "24px",
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "10px 20px",
        color: "#24243F",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "white",
        },
        marginBottom: '50px'
    },
    frame703button2: {
        borderRadius: "24px",
        textTransform:"none",
        width:"230px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"14px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F'
    },
    frame235button3: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'normal',
        fontSize:"16px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        margin:'0px 24px',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F',
        marginBottom:'16px',
        border: "2px solid #BBBECC",
        boxSizing: "border-box",
        "&:hover": {
            backgroundColor: "#24243F",
        },
  
    },

})

class TrackItPdf extends Component {
    trackStep14Data;

     constructor(props) {
        super(props);
        this.trackStep22Data = JSON.parse(localStorage.getItem('trackStep22'));
        this.trackStep23Data = JSON.parse(localStorage.getItem('trackStep23'));
        this.trackStep24Data = JSON.parse(localStorage.getItem('trackStep24'));
        this.trackStep25Data = JSON.parse(localStorage.getItem('trackStep25'));
        this.trackStep26Data = JSON.parse(localStorage.getItem('trackStep26'));

        this.state = {
            outcome :  "",
            thisIndicators  : [],
            targets1: [],
            targets2: [],
            whenToCollectData:"",
            inputField:[],
        }
        this.pdfGenerate = this.pdfGenerate.bind(this);
     };
     componentDidMount(){
        if (localStorage.getItem('trackStep22') === null ) {
            this.setState ( {
                outcome :  "",
                thisIndicators  : [],
                })
        } else {
            this.setState ({
                outcome : this.trackStep22Data.outcome,
                thisIndicators  : this.trackStep22Data.thisIndicators,
            })
        };

        if (localStorage.getItem('trackStep23') === null ) {
            this.setState ( {
                targets1: [],
            })
        } else {
            this.setState ({
                targets1 : this.trackStep23Data.targets,
            })
        };

        if (localStorage.getItem('trackStep24') === null ) {
            this.setState ( {
                targets2: [],
            })
        } else {
            this.setState ({
                targets2 : this.trackStep24Data.targets,
            })
        };

        if (localStorage.getItem('trackStep25') === null ) {
            this.setState ( {
                whenToCollectData : "",
            })
        } else {
            this.setState ({
                whenToCollectData : this.trackStep25Data.whenToCollectData,
            })
        };

        if (localStorage.getItem('trackStep26') === null ) {
            this.setState ( {
                inputField: [],
            })
        } else {
            this.setState ({
                inputField : this.trackStep26Data.inputField,
            })
        };

        
     }
    pdfGenerate = () => {
  
        const date = new Date().toLocaleDateString();

        var doc = new jsPDF ('portrait','px', 'a4','false');
        doc.addImage(rectangle221,'JPG',19,19,350,190);
        doc.addImage(ellipse30,'PNG',310,40,100,90);
        doc.addImage(Illustrationer15,'PNG',290,50,110,130);

        doc.setFont(undefined,"bold").setFontSize(12).text(40,130,'UTFORSK DET');
        var projectName1 = doc.splitTextToSize("Plan for måling av data",280)
        doc.setFont('h3',"normal").setFontSize(32).text(40,160,projectName1);

        doc.setDrawColor(249, 249, 249).setFillColor(249, 249, 249).rect(40, 230, 370, 45,'FD');        
        var column1 = doc.splitTextToSize("Endringene vi måler for", 150);
        doc.setFont(undefined,"bold").setFontSize(9.11).text(55,245,column1);

        var column2 = doc.splitTextToSize("Indikatorene vi måler", 50);
        doc.setFont(undefined,"bold").setFontSize(9.11).text(115,245,column2);

        var column3 = doc.splitTextToSize("Hvem vil du hente dataene fra?", 50);
        doc.setFont(undefined,"bold").setFontSize(9.11).text(180,245,column3);
 
        var column4 = doc.splitTextToSize("Hvordan vil du måle det?", 50);
        doc.setFont(undefined,"bold").setFontSize(9.11).text(235,245,column4);

        var column5 = doc.splitTextToSize("Når vil du samle inn data?", 50);
        doc.setFont(undefined,"bold").setFontSize(9.11).text(295,245,column5);

        var column6 = doc.splitTextToSize("Tilleggsnotater", 50);
        doc.setFont(undefined,"bold").setFontSize(9.11).text(360,245,column6);
        doc.setDrawColor(0).setFillColor(187, 190, 204).line(40, 285,410, 285);

        doc.setFont(undefined,"normal").setFontSize(9.).text(40,295,'1');

        var outcome1 = doc.splitTextToSize(this.state.outcome, 45);
        doc.setFont(undefined,"normal").setFontSize(9.11).text(55,295,outcome1);

        var indicator1 = doc.splitTextToSize(this.state.thisIndicators[0],50);
        doc.setFont(undefined,"normal").setFontSize(9.11).text(115,295,'1.');
        doc.setFont(undefined,"normal").setFontSize(9.11).text(120,295,indicator1);

        var indicator2 = doc.splitTextToSize(this.state.thisIndicators[1],50);
        doc.setFont(undefined,"normal").setFontSize(9.11).text(115,385,'2. ');
        doc.setFont(undefined,"normal").setFontSize(9.11).text(120,385,indicator2);

        var indicator3 = doc.splitTextToSize(this.state.thisIndicators[2],50);
        doc.setFont(undefined,"normal").setFontSize(9.11).text(115,460,'3. ');
        doc.setFont(undefined,"normal").setFontSize(9.11).text(120,460,indicator3);

        
        var targets11 = doc.splitTextToSize(this.state.targets1, 50);
        doc.setFont(undefined,"normal").setFontSize(9.11).text(180,295,targets11);

        var targets2 = doc.splitTextToSize(this.state.targets2, 50);
        doc.setFont(undefined,"normal").setFontSize(9.11).text(235,295,targets2);

        var data = doc.splitTextToSize(this.state.whenToCollectData, 50);
        doc.setFont(undefined,"normal").setFontSize(9.11).text(295,295,data);

        var fields = doc.splitTextToSize(this.state.inputField, 50);
        doc.setFont(undefined,"normal").setFontSize(9.11).text(360,295,fields);

        doc.addImage(Illustrationer05,'JPG',40,540,32,32);
        doc.setFont(undefined,"normal").setTextColor('#24243F').setFontSize(8).text(76,550,"Hva er en plan for måling av data?");
        var narrative = doc.splitTextToSize("Dette er dokument som oppsummerer hva du vil måle, og hvilke første steg du kan utføre for å samle inn data. Dette er en god måte å komme i gang med å samle inn riktig type data og operasjonalisere din effektmåling. For mer informasjon, kan du se UTFORSK DET.", 270);
        doc.setFont(undefined,"normal").setTextColor('#000000').setFontSize(6).text(76,558,narrative);

        doc.setDrawColor(0).line(40, 580,410, 580);
        doc.addImage(frame834,'JPG',48,592,12,15);
        doc.setFont(undefined,"bold").setFontSize(10).text(63,600, 'Vis Det!');
        doc.setFont(undefined,"bold").setFontSize(8).setTextColor('#656C88').text(91,600, 'laget av Norge Unlimited');
        doc.setFont(undefined,"normal").setFontSize(6).setTextColor('#000000').text(48,610, 'visdet@toyenunlimited.no | www.norgeunlimited.no');
        doc.setFont(undefined,"normal").setFontSize(6).text(370,610, 'Lastet ned '+ date);
        doc.save('UndersøkDet!.pdf');
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Button
                  variant="contained"
                  className={classes.frame235button3} 
                  onClick={() => this.pdfGenerate()}
                  component={Link} 
                  to="/Main"
                > 
            Last ned 
                </Button>

            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true}) (TrackItPdf)