import React from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import logoFooter from "../Images/Footer/logoFooter.svg";

const style = makeStyles({
  footerFrame: {
    "max-width": "1440px",
    marginLeft: "70px",
    marginRight: "70px",
    height: '85px',
    maxHeight: '85px',
    alignItems: 'center',
    alignContent: 'center',
  },
  logoFrame: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    position: "static",
    height: "35px",
  },
  logo: {
    width: "19.64px",
    height: "24px",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      display: "",
    },
  },
  logotext: {
    textDecoration: "inherit",
    color: "white",
    fontSize: 20,
    fontWeight: "normal",
    marginLeft: "17px",
    "&:hover": {
      textDecoration: "underline",
      color: "#f1d39e",
    },
  },
  frame525: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    position: "stat",
    width: "100%",
    height: "24px",
    marginLeft: "679px",
    marginRight: "0px",
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "444px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1024px)"]: {
      marginLeft: "444px",
    },
  },
  frame525info: {
    textDecoration: "inherit",
    color: "white",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "24px",
    marginRight: "16px",
    "&:hover": {
      textDecoration: "underline",
      color: "#000000",
    },
  },
});

export default function MainFooter() {
  const classes = style();
  return (
    <footer className="mainFooter">
      <Grid  container direction="row" className={classes.footerFrame}>
        <Grid item xs className={classes.logoFrame}>
          <img src={logoFooter} alt="logo" className={classes.logo} />
          <Typography
            noWrap
            className={classes.logotext}
            component={Link}
            to="/Main"
          >
            Vis Det!{" "}
          </Typography>
        </Grid>
        <Grid item xs className={classes.frame525}>
          <Typography
            noWrap
            className={classes.frame525info}
            component={Link}
            to="/Main/Contact"
          >
            Kontakt oss
          </Typography>
          <Typography
            noWrap
            className={classes.frame525info}
            component={Link}
            to="/Main/Terms"
            target="_blank" rel="noopener noreferrer"
          >
            Brukervilkår
          </Typography>
          <Typography
            noWrap
            className={classes.frame525info}
            component={Link}
            to="/Main/Privacy"
            target="_blank" rel="noopener noreferrer"
          >
            Personvernerklæring
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}
