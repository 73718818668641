import { makeStyles, Box, Paper, Typography, Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
//import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import frame642 from '../../Images/MapIt/MapStepFinal/frame642.svg';
import image54 from '../../Images/TellIt/TellItCongratulation/image54.png';
import ExitPopupTellIt from '../ExitDialoge/ExitPopupTellIt';
import TellFinishPdf from './TellFinishPdf';

const styles = makeStyles({
    MapItCongratulation: {
        position:'relative',
        width:'1440px',
        height:'938px',
    },
    frame233:{
        position:'absolute',
        width:'594px',
        height:'927px',
        top:'10px',
        background:'linear-gradient(0deg, #EFEFEF, #EFEFEF), #F9F9F9',
        zIndex:2
    },
    frame642:{
        position:'absolute',
        left:'628px',
        top:'15px'
    },
    frame704:{
        position:'absolute',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        width:'524px',
        height:'692px',
        left:'735px',
        top:'245px'
    },
    frame835:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        justifyContent:'space-between',
        width:'523px',
        height:'250px',
        margin:'0px 0px 135px 0px'
    },
    frame835info1:{
        position:'relative',
        width:'523px',
        height:'28px',
        variant:'h4',
        fontStyle:'normal',
        fontWeight:'bold',
        fontSize:'24px',
        lineHeight:'32px',
        color:'#24243F',
        marginBottom:'16px'
    },
    frame835info2:{
        position:'relative',
        width:'523px',
        height:'48px',
        variant:'body',
        fontStyle:'normal',
        fontWeight:'normal',
        fontSize:'16px',
        lineHeight:'24px',
        color:'#24243F',
        //marginBottom:'16px'
    },
    frame835info3:{
        position:'relative',
        width:'523px',
        height:'120px',
        variant:'body',
        fontStyle:'normal',
        fontWeight:'normal',
        fontSize:'16px',
        lineHeight:'24px',
        color:'#24243F',
        //marginBottom:'16px'
    },
    frame835info4:{
        position:'relative',
        width:'523px',
        height:'96px',
        variant:'body',
        fontStyle:'normal',
        fontWeight:'normal',
        fontSize:'16px',
        lineHeight:'24px',
        color:'#24243F',
        marginBottom:'16px'
    },
    frame702:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        width:'524px',
        height:'244px',
        margin:'0px 0px 48px 0px'
    },
    image43:{
        position:'absolute',
        width: '467.1px',
        height: '661.01',
        left: '63px',
        top:'144px'
    },
    imageStoryBox:{
        position:'absolute',
        width: '514px',
        height: '357.11',
        left: '-695px',
        top:'-300px'
    },
    imageProject:{
        position:'relative',
        top:'130px',
        left:'63px',
        width: '467.1px',
        height: '661.01px',
        filter:'drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.25))'
        
    },
    imageStory:{
        width: '514px',
        height: '357.11',
    },
    
    frame66Button:{
        position:'relative',
        border:'none',
        fontStyle:'normal',
        fontWeight:'normal',
        fontSize:'20px',
        lineHeight:'24px',
        color:'#2D86D9',
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-start',
        textTransform: "none",
        marginBottom:'16px',
        boxShadow: "none",
        cursor:"pointer",
        "&:hover": {
            boxShadow: "none",
            backgroundColor: "white",
          },
    },
    frame662:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        width:'524px',
        height:'156px',
    },
    line:{
        position:'static',
        width:'524px',
        height:'2px',
        border:'2px solid #2D86D9',
        background:'#2D86D9'
    },
    frame528:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        width:'470px',
        height:'154px',
        padding:'30px',
        background:'#F4F6F9',
        fontStyle:'normal',
        fontWeight:'normal',
        fontSize:'16px',
        lineHeight:'24px',
    },
    frame703:{
        position:'relative',
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-start',
        width:'524px',
        height:'48px',
        marginTop:'48px',
        justifyContent:'space-between'
    },
    frame703button1: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"14px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 20px',
        color:'black',
        backgroundColor:'white'
    },
    frame703button2: {
        borderRadius: "24px",
        textTransform:"none",
        width:"230px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"14px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F'
    }
});


export default function TellItCongratulation() {
    const classes = styles();

    
    return (
        <div className={classes.MapItCongratulation}>
            <Paper className={classes.frame233}>
                <img  id="image54" src={image54} alt="Plan for Tracking" className={classes.imageProject} />
            </Paper>
            <Box className={classes.frame642}>
                <img src={frame642} alt="frame Congratulations" />
            </Box>
            <Box className={classes.frame704}>
                <Box className={classes.frame835}>
                    <Box component={Typography} className={classes.frame835info1}>
                    Samfunnseffektrapporten din
                    </Box>
                    <Box component={Typography} className={classes.frame835info2}>
                    Dette er en milepæl. Husk å anerkjenne din suksess og fremgang. Når kan det bare gå en vei: oppover!
                    </Box>
                    <Box component={Typography} className={classes.frame835info3}>
                    Samfunnseffektrapporten er en énsides rapport som presenterer resultatene og kommuniserer verdien av aktivitetene dine. Å ha en kort rapport vil hjelpe deg med å formidle kvaliteten i arbeidet ditt til kunder, investorer og støttespillere. Fortsett å forbedre innsamlingen av 'gode nok' data for å ta best mulig beslutninger, og vær lydhør overfor tilbakemeldinger.                  
                    </Box>
                   
                </Box>
                <Box className={classes.frame703}>
                    <ExitPopupTellIt/>
                    <Button 
                        variant="contained" 
                        className={classes.frame703button1} 
                        component={Link}   
                        to="/Main/TellItStep4_1"                                               
                        >
                        Tilbake
                    </Button>
                    <TellFinishPdf/>               
                </Box>
            </Box>
        </div>
    )
}
