import React, { Component } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import Illustrasjoner10 from "../../Images/Main/Illustrasjoner10.png";
import Frame592 from "../../Images/TrackIt/TrackStep1/Frame592.svg";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import Ellipse1_2 from "../../Images/MapIt/MapStep1//Ellipse1_2.svg";
import Ellipse31 from "../../Images/Main/Ellipse31.png";
import DudePopup2 from "./DudePopup2";
import ExitPopupTrackIt from "../ExitDialoge/ExitPopupTrackIt";
import TrackStep1Pdf from "../TrackStep1Pdf/TrackStep1Pdf.js";

const styles = (theme) => ({
  innerContent: {
    width: "100%",
    //Changes by Kishan
    height: "1070px",
    // height: '100%',
    "overflow-x": "visible",
    "overflow-y": "auto",
  },
  frame747: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "1440px",
    left: "0px",
    top: "0px",
    marginTop: "250px",
  },
  frame746: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "50px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-145px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-274px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-310px",
      marginTop: "50px",
    },
  },
  frame745: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    height: "564px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "72px",
  },
  frame743: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0px 70px 0px 70px",
    bottom: "0px",
    position: "sticky",
    width: "1440px",
    height: "85px",
    top: "1526px",
    flexGrow: "1",
    marginBottom: "-300px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "166px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "283px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "329px",
    },
  },
  frame190: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "524px",
    height: "48px",
    left: "735px",
    top: "37px",
    flex: "none",
    order: "1",
    flexGrow: "0",

    // eslint-disable-next-line
    ["@media (min-width:1440px)"]: {
      marginLeft: "30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-10px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-140px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-255px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-300px",
      width: "440px",
    },
  },
  frame235: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "264px",
    height: "48px",
    left: "260px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  nextButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "10px",
    position: "static",
    left: "144px",
    top: "0px",
    background: "#24243F",
    borderRadius: "24px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#24243F",
    },
  },
  backButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "120px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  downloadButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "214px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  exploreButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "160px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
    marginRight: "150px",
  },
  frame203: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "229px",
    height: "56px",
    left: "101px",
    top: "29px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      width: "200px",
    },
  },
  frame203Text: {
    position: "static",
    left: "229px",
    top: "24px",
    right: "0px",
    bottom: "0px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  FrameDudeTrackIt: {
    position: "static",
    left: "85px",
    top: "85px",
    right: "0px",
    bottom: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "10px 10px 0px 16px",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      marginLeft: "-10px",
    },
  },
  outerFrameDudeTrackIt: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "0px",
    position: "static",
    width: "330px",
    height: "85px",
    left: "70px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },

  frame744: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px 150px 0px 365px",
    position: "static",
    width: "1440px",
    height: "515px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "43px",
  },
  frame274: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "483px",
    left: "735px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "-40px",
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "0px",
    },
  },
  frame233: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "128px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "48px 0px",
  },
  Paragraph1: {
    position: "static",
    width: "524px",
    height: "168px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "30px",
  },
  frame234: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "302px",
    height: "72px",
    left: "403px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px 30px",
  },
  header1: {
    position: "static",
    width: "302px",
    height: "48px",
    left: "0px",
    top: "24px",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#24243F",
  },
  headerStep: {
    fontWeight: "bold",
    position: "static",
    width: "50px",
    height: "24px",
    left: "0px",
    top: "0px",
    fontSize: "16px",
    lineHeight: "32px",
    color: "#24243F",
  },
  frame590: {
    width: "364.34px",
    height: "262.36px",
  },
  Frame590Vector: {
    position: "absolute",
    left: "96px",
    top: "2.19px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-150px",
      left: "127px",
      top: "77px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-100px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-150px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
  },
  Ellipse30: {
    marginLeft: "-30px",
    marginBottom: "0px",
  },
  Ellipse31: {
    left: "0px",
    top: "0px",
  },
  subHeader1: {
    width: "180px",
    height: "24px",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    marginLeft: "-218px",
    marginTop: "4px",
  },
  subHeader2: {
    width: "524px",
    height: "30px",
    fontSize: "16px",

    lineHeight: "24px",
    color: "#24243F",
    marginTop: "30px",
  },
  subText1: {
    marginTop: "15px",
    fontWeight: "bold",
  },
  libText1: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    marginBottom: "10px",
  },
  formControl: {
    marginTop: "30px",
    width: "255px",
  },
  contentButtons: {
    marginTop: "20px",
  },
});

class TrackStep1_4 extends Component {
  trackStep14Data;
  constructor(props) {
    super(props);

    this.state = {
      display: "static",
      outcomesOptions: {
        option1: [
          "Trener regelmessig	",
          "Adresserer problemer med misburuk av rusmidler",
          "Spiser og sover godt",
        ],
        option2: [
          "Forbedret motstandsdyktighet",
          "Er generelt tilfreds med livet",
          "I stand til å håndtere mental helse",
        ],
        option3: [
          "Har passende bosituasjon i god stand	",
          "Forbedret tilgang til tjenester og fasiliteter	",
          "Forbedret tilgang til finansielle produkter og tjenester	",
        ],
        option4: [
          "Forbedret støtte for familiemedlemmer, partnere, hovedforsørgere og venner av dem med spesifikke behov",
          "Forbedret støtte for familier, barn og unge",
          "Blir er mer aktivt medlem av lokalsamfunnet",
        ],
        option5: [
          "Har et passende arbeid	",
          "Har opprettholdt arbeidsforhold",
          "Forbedret mellommenneskelige og sosiale ferdigheter",
        ],
        option6: [
          "Er i passende utdanning eller opplæring	",
          "Forbedrede karakterer",
          "Forbedret generell forberedelse til arbeid",
        ],
      },
      specificOutcomeOptions: [
        "Trener regelmessig	",
        " Adresserer problemer med misburuk av rusmidler",
        "Spiser og sover godt",

        "Forbedret motstandsdyktighet",
        "Er generelt tilfreds med livet",
        "I stand til å håndtere mental helse",

        "Har passende bosituasjon i god stand	",
        "Forbedret tilgang til tjenester og fasiliteter	",
        "Forbedret tilgang til finansielle produkter og tjenester	",

        "Forbedret støtte for familiemedlemmer, partnere, hovedforsørgere og venner av dem med spesifikke behov",
        "Forbedret støtte for familier, barn og unge",
        "Blir er mer aktivt medlem av lokalsamfunnet",

        "Har et passende arbeid	",
        "Har opprettholdt arbeidsforhold",
        "Forbedret mellommenneskelige og sosiale ferdigheter",

        "Er i passende utdanning eller opplæring	",
        "Forbedrede karakterer",
        "Forbedret generell forberedelse til arbeid",
      ],
      indicatorOptions: {
        0: [
          "5-18 år - 60 minutter moderat til intens trening hver dag",
          "16-64 år - 150 minutter moderat aerob trening hver uke",
          "65+ år - 150 minutter moderat aktivitet hver uke",
        ],
        1: [
          " Røyker ikke",
          "Ikke helseskadelig inntak av alkohol (mer enn 4 ganger per uke) eller drikker store mengder (mer enn 7 eneter hver gang de drikker)",
          "Bruker ikke andre rusmidler",
        ],
        2: [
          "Spiser næringsrik mat hver dag",
          "Spiser 5 porsjoner frukt og grønt hver dag",
          "Har regelmessig og tilstrekkelig nattesøvn",
        ],
        3: [
          "Har utviklet forbedret motstandsdyktighet, mot, og besluttsomhet (inkludert pågangsmot til å lykkes)",
          "Har utviklet kapasitet til å hanskes med vanskeligheter og stress",
          "Har redusert stress",
        ],
        4: [
          "Rapporterer forbedret livssituasjon og trivsel",
          "Føler de fyller livet med mening",
          "Egenvurdering av livskvalitet",
        ],
        5: [
          "Erfarer en reduksjon i problemer med mental helse",
          "Kan opprettholde god mental helse",
          "Kan håndtere sin mentale tilstand",
        ],
        6: [
          "Har et godt vedlikeholdt hjem",
          "Har en bopel utstyrt med alle nødvendige møbler og utstyr",
          "Er tilfreds med bosituasjon",
        ],
        7: [
          "Forbedret tilgang på butikker som selger rimelige nødvendige produkter og tjenester",
          "Forbedret tilgang på god og rimelig offentlig transport",
          "Forbedret tilgang på samfunnsinfrastruktur og –ressurser",
        ],
        8: [
          "Forbedret tilgang på tilrettelagt økonomisk rådgivning",
          "Bruker lån/kreditt på en informert og passende måte",
          "Har en bankkonto",
        ],
        9: [
          "Familiemedlemmer, partnere, forsørgere og venner av de med spesifikke behov har tilgang til og bruker tilstrekkelig spesialistråd, rådgivning og støtte",
          "Familiemedlemmer, partnere, forsørgere og venner av de med spesifikke behov er tilfreds med støtten som gis",
          "Familiemedlemmer, partnere og forsørgere føler de er i stand til å leve sine egne liv",
        ],
        10: [
          "Har tilgang på barnevernstjenester",
          "Har tilgang på foreldrekurs og veiledning",
          "Forbedrede relasjoner mellom foreldre og barn",
        ],
        11: [
          "Tid dedikert til frivillig eller ubetalt jelp eller arbeid for lokale, nasjonale eller internasjonale organisasjoner eller veldedighet",
          "Deltar i avgjørelser i lokalsamfunnet",
          "Har økt interaksjon med lokale kampanjer",
        ],
        12: [
          "Er i en deltidsstilling (mindre enn 16 timer per uke)",
          "Har en fulltidsstilling (mer enn 35 timer per uke)",
          "Annet nivå av oppmøte (i antall timer per uke)",
        ],
        13: [
          "I 6, 12, og 24 måneder",
          "Har permanent arbeidskontrakt",
          "Trives på jobb",
        ],
        14: [
          "Har utviklet kommunikasjonsferdigheter",
          "Har utviklet samarbeidsevner og ferdigheter innen relasjonsbygging",
          "Har utviklet ferdigheter innen selvbevissthet",
        ],
        15: [
          "Er i fulltids utdanning eller opplæring",
          "Er i deltids utdanning eller opplæring",
          "Andre nivåer av oppmøte (i antall timer per uke)",
        ],
        16: [
          "Har startet høyere grad av utdanning (diplom, grad osv.)",
          "Har fullført en høyere grad av utdanning (diplom, grad osv.)",
          "Nivå av oppmøte oppnådd",
        ],
        17: [
          "Forbedret tallforståelse",
          "Forbedrede leseferdigheter",
          "Forbedrede IKT-ferdigheter",
        ],
      },
      impact: 0,
      outcome: 0,
      indicators: [],
      outcomes: [],
    };
  }

  componentWillUpdate(nextProps, nextState) {
    localStorage.setItem(
      "trackStep14",
      JSON.stringify({
        impact: nextState.impact,
        outcome: nextState.outcome,
        indicators: nextState.indicators,
        outcomes: nextState.outcomes,
      })
    );
  }
  componentDidMount() {
    let tempImpact = JSON.parse(localStorage.getItem("trackStep13"));

    for (
      let index = 0;
      index < this.state.specificOutcomeOptions.length;
      index++
    ) {
      if (this.state.specificOutcomeOptions[index] === tempImpact.outcome) {
        this.setState({
          indicators: this.state.indicatorOptions[index],
          impact: tempImpact.impact,
          outcome: tempImpact.outcome,
        });
      }
    }
    if (
      tempImpact.outcome === this.state.outcomesOptions.option1[0] ||
      tempImpact.outcome === this.state.outcomesOptions.option1[1] ||
      tempImpact.outcome === this.state.outcomesOptions.option1[2]
    ) {
      this.setState({
        outcomes: this.state.outcomesOptions.option1,
      });
    }
    if (
      tempImpact.outcome === this.state.outcomesOptions.option2[0] ||
      tempImpact.outcome === this.state.outcomesOptions.option2[1] ||
      tempImpact.outcome === this.state.outcomesOptions.option2[2]
    ) {
      this.setState({
        outcomes: this.state.outcomesOptions.option2,
      });
    }
    if (
      tempImpact.outcome === this.state.outcomesOptions.option3[0] ||
      tempImpact.outcome === this.state.outcomesOptions.option3[1] ||
      tempImpact.outcome === this.state.outcomesOptions.option3[2]
    ) {
      this.setState({
        outcomes: this.state.outcomesOptions.option3,
      });
    }
    if (
      tempImpact.outcome === this.state.outcomesOptions.option4[0] ||
      tempImpact.outcome === this.state.outcomesOptions.option4[1] ||
      tempImpact.outcome === this.state.outcomesOptions.option4[2]
    ) {
      this.setState({
        outcomes: this.state.outcomesOptions.option4,
      });
    }
    if (
      tempImpact.outcome === this.state.outcomesOptions.option5[0] ||
      tempImpact.outcome === this.state.outcomesOptions.option5[1] ||
      tempImpact.outcome === this.state.outcomesOptions.option5[2]
    ) {
      this.setState({
        outcomes: this.state.outcomesOptions.option5,
      });
    }
    if (
      tempImpact.outcome === this.state.outcomesOptions.option6[0] ||
      tempImpact.outcome === this.state.outcomesOptions.option6[1] ||
      tempImpact.outcome === this.state.outcomesOptions.option6[2]
    ) {
      this.setState({
        outcomes: this.state.outcomesOptions.option6,
      });
    }
  }
  render() {
    const { classes } = this.props;
    let { impact } = this.state;
    let { outcome } = this.state;
    const { indicatorOptions } = this.state;
    const { specificOutcomeOptions } = this.state;

    return (
      <div className={classes.innerContent}>
        <Grid item xs className={classes.frame590}>
          <img
            src={Frame592}
            alt="Vector162"
            className={classes.Frame590Vector}
          />
        </Grid>
        <Grid item xs className={classes.frame747}>
          <Grid item xs className={classes.frame746}>
            <Grid item xs className={classes.frame745}>
              <Grid item xs className={classes.frame744}>
                <Grid item xs className={classes.frame274}>
                  <Grid item xs className={classes.frame233}>
                    <Grid container xs className={classes.frame210}>
                      <Grid item xs>
                        <img
                          src={Ellipse31}
                          alt="Ellipse31"
                          className={classes.Ellipse31}
                        />
                        <img
                          src={Ellipse1_2}
                          alt="Ellipse30"
                          className={classes.Ellipse30}
                        />{" "}
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.subHeader1}>
                          Dine indikatorer
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography className={classes.Paragraph1}>
                      Basert på effektområdet ditt har du bestemt deg for
                      hvilken endring du skal måle og fått en liste over
                      indikatorer for måling. I neste steg kan du velge hvilke
                      metoder du vil bruke for å samle inn data og planlegge
                      hvem du vil samle inn data fra.
                    </Typography>

                    <Typography className={classes.subHeader2}>
                      Effektområdet for ditt prosjekt:{" "}
                    </Typography>
                    <span className={classes.subText1}>{impact} </span>

                    <Typography className={classes.subHeader2}>
                      Endringen du valgte:
                    </Typography>
                    <span className={classes.subText1}>{outcome}</span>

                    <Typography className={classes.subHeader2}>
                      Dine indikatorer for måling:
                    </Typography>
                    <span>
                      <ol className={classes.subText1}>
                        {outcome === specificOutcomeOptions[0] &&
                          indicatorOptions[0].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[1] &&
                          indicatorOptions[1].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[2] &&
                          indicatorOptions[2].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[3] &&
                          indicatorOptions[3].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[4] &&
                          indicatorOptions[4].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[5] &&
                          indicatorOptions[5].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[6] &&
                          indicatorOptions[6].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[7] &&
                          indicatorOptions[7].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[8] &&
                          indicatorOptions[8].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[9] &&
                          indicatorOptions[9].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[10] &&
                          indicatorOptions[10].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[11] &&
                          indicatorOptions[11].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[12] &&
                          indicatorOptions[12].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[13] &&
                          indicatorOptions[13].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[14] &&
                          indicatorOptions[14].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[15] &&
                          indicatorOptions[15].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[16] &&
                          indicatorOptions[16].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                        {outcome === specificOutcomeOptions[17] &&
                          indicatorOptions[17].map((item) => (
                            <li
                              className={classes.libText1}
                              key={Math.random()}
                            >
                              {item}
                            </li>
                          ))}
                      </ol>
                    </span>
                    <Grid container className={classes.contentButtons}>
                      <Grid item xs>
                        <TrackStep1Pdf impact2={impact} outcome3={outcome} indicator4={this.state.indicators}/>
                      </Grid>
                      <Grid item xs>
                        <Button
                          variant="contained"
                          className={classes.exploreButton}
                          component={Link}
                          to="/Main/TrackItStep1"
                          type="submit"
                        >
                          Utforsk på nytt
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs className={classes.frame234}>
                  <Typography noWrap className={classes.headerStep}>
                    Steg 1
                  </Typography>

                  <Typography className={classes.header1}>
                    Finn indikatorer
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs className={classes.frame743}>
              <Grid item xs className={classes.frame190}>
                <Grid order="1" item xs className={classes.frameExitButton}>
                  <ExitPopupTrackIt />
                </Grid>
                <Grid item xs className={classes.frame235}>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235TertiaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.backButton}
                      component={Link}
                      to="/Main/TrackItStep1_3"
                    >
                      Tilbake
                    </Button>
                  </Grid>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235PrimaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.nextButton}
                      component={Link}
                      to="/Main/TrackItStep2_1"
                      type="submit"
                    >
                      Neste
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs className={classes.outerFrameDudeTrackIt}>
                <Grid order="0" item xs className={classes.FrameDudeTrackIt}>
                  <img
                    src={Illustrasjoner10}
                    alt="illustrationMan"
                    className={classes.DudeTrackIt}
                  />
                </Grid>
                <Grid item xs className={classes.frame203}>
                  <Typography className={classes.frame203Text}>
                    Vil du vite mer om indikatorer?
                  </Typography>

                  <DudePopup2 />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TrackStep1_4);
