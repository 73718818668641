import * as React from 'react';
import { Modal, Box, Button, makeStyles } from '@material-ui/core';

import image57 from '../Images/MapIt/MapHome/image57.svg';
import image58 from '../Images/MapIt/MapHome/image58.svg';
import image582 from '../Images/MapIt/MapHome/image582.svg';


const styles = makeStyles({
    modalBox1:{
        position: 'absolute',
        width:'1200px',
        height:'800px',
        top: '60%',
        left: '51%',
        transform: 'translate(-50%, -50%)',
        p: 0,
        
    },
    modalBox21:{
        position: 'absolute',
        width:'600px',
        height:'900px',
        top: '70%',
        left: '30%',
        transform: 'translate(-50%, -50%)',
        p: 0,
    },
    modalBox22:{
      position: 'absolute',
      width:'600px',
      height:'900px',
      top: '47.25%',
      left: '150%',
      transform: 'translate(-50%, -50%)',
      p: 0,
  },

    previewButton1: {
        borderRadius: "24px",
        border: '2px solid #E3E5E9',
        boxSizing: 'border-box',
        textTransform:"none",
        width:"150px",
        height:"48px",
        fontWeight: 'normal',
        fontSize:"16px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 20px',
        color:'#24243F',
        backgroundColor:'white',
        margin:'0px 30px 0px 0px'
    },
    previewButton2: {
        borderRadius: "24px",
        border: '2px solid #E3E5E9',
        boxSizing: 'border-box',
        textTransform:"none",
        width:"150px",
        height:"48px",
        fontWeight: 'normal',
        fontSize:"16px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 20px',
        color:'#24243F',
        backgroundColor:'white',
        marginLeft: '-20px'
    },
});

export default function BasicModal() {
    const classes= styles();
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleOpen2 = () => setOpen2(true);
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(false);

  return (
    <div>
      <Button variant='outlined' onClick={handleOpen1} className={classes.previewButton1} >Forhåndsvisning</Button>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{overflow:'scroll'}}
      >
        <Box className={classes.modalBox21}>
          <img src={image58} alt="Prosjektbeskrivelse 1" className={classes.image57} />
          <img src={image582} alt="Prosjektbeskrivelse 2" className={classes.modalBox22} />
        </Box>

      </Modal>
      <Button variant='outlined' onClick={handleOpen2} className={classes.previewButton2}>Forhåndsvisning</Button>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{overflow:'scroll'}}
      > 
        <Box className={classes.modalBox1}>
          <img src={image57} alt=" preview 57" className={classes.image57} />
        </Box>
      </Modal>
    </div>
  );
}