import React, { Component } from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import Illustrasjoner10 from "../../Images/Main/Illustrasjoner10.png";
import Ellipse30 from "../../Images/Main/Ellipse30.png";
import EllipseFull from "../../Images/MapIt/Map1/EllipseFull.svg";
import MapStep1Vector from "../../Images/Main/MapStep1Vector.svg";
import { Link } from "react-router-dom";
import DudePopup3 from "./DudePopup3.js";
import { withStyles } from "@material-ui/styles";
import PopupMap8 from './PopupMap8.js';
import PopupMap10 from './PopupMap10.js';
import ExitPopupStep1 from "../ExitDialoge/ExitPopupStep1";

const styles = (theme) =>({
  mapItStep1: {
    maxWidth: "1440px",
    //Changes by Kishan -Date :01.10.2021
    height: "1070px",
    // minHeight: "900px",
    // height: "830px",
    // padding: "165px 163.5px 149px 163.5px",
    // overflowY: "visible",
  },
  frame747: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "absolute",
    width: "1440px",
    left: "0px",
    top: "0px",
    marginTop: '250px'
  },
  frame746: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "115px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-145px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-274px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-346px",
      marginTop: "50px",
    },
  },
  frame745: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    height: "538px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    //marginTop: "72px",
  },
  frame743: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0px 70px 0px 70px",
    bottom: "0px",
    position: "sticky",
    width: "1440px",
    height: "85px",
    top: "1526px",

    flexGrow: "1",
    marginBottom: "-300px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "166px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "283px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "329px",
    },
  },
  frame190: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "524px",
    height: "48px",
    left: "735px",
    top: "37px",
    flex: "none",
    order: "1",
    flexGrow: "0",

    // eslint-disable-next-line
    ["@media (min-width:1440px)"]: {
      marginLeft: "30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-10px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-140px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-255px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-300px",
      width: "440px",
    },
  },
  frame235: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "264px",
    height: "48px",
    left: "260px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  nextButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "10px",
    position: "static",
    left: "144px",
    top: "0px",
    background: "#24243F",
    borderRadius: "24px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#24243F",
    },
  },
  backButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",

    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "120px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },

  exitButton: {
    position: "static",
    width: "120px",
    height: "48px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  frame203: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "229px",
    height: "56px",
    left: "101px",
    top: "29px",

    flex: "none",
    order: "1",
    flexGrow: "0",

    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      width: "200px",
    },
  },
  frame203buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",

    position: "static",
    width: "75px",
    height: "24px",
    left: "0px",
    top: "32px",

    borderRadius: "8px",

    flex: "none",
    order: "1",
    flexGrow: "0",
    marginBottom: "12px",
  },
  frame203button: {
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",

    fontSize: "16px",
    lineHeight: "24px",

    display: "flex",
    alignItems: "center",

    color: "#2D86D9",

    flex: "none",
    order: "0",
    flexGrow: "0",
    marginLeft: "-8px",
    marginTop: "17px",
    textTransform: "none",
    textDecoration: "none",
  },
  frame203Text: {
    position: "static",
    left: "229px",
    top: "24px",
    right: "0px",
    bottom: "0px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",

    color: "#24243F",

    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  frameillustrasjoner10: {
    position: "static",
    left: "85px",
    top: "85px",
    right: "0px",
    bottom: "0px",

    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "10px 10px 0px 16px",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      marginLeft: "-10px",
    },
  },
  exampleGuide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "0px",

    position: "static",
    width: "330px",
    height: "85px",
    left: "70px",
    top: "0px",

    flex: "none",
    order: "0",
    flexGrow: "0",
  },

  frame744: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px 181px 0px 403px",

    position: "static",
    width: "1440px",
    height: "483px",
    left: "0px",
    top: "0px",

    flex: "none",
    order: "1",
    flexGrow: "0",
    //marginTop: "10px",
  },
  frame274: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "524px",
    height: "483px",
    left: "735px",
    top: "0px",

    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "-40px",
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "0px",
    },
  },
  frame271: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "524px",
    height: "307px",
    left: "0px",
    top: "176px",

    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "20px",
  },
  textField: {
    margin: "8px auto",
  },
  group2: {
    position: "static",
    width: "492px",
    height: "163px",
    left: "16px",
    top: "16px",

    flex: "none",
    order: "0",
    alignSelf: "stretch",
    flexGrow: "1",
    margin: "0px 10px",
  },

  frame210: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",

    position: "static",
    width: "524px",
    height: "32px",
    left: "0px",
    top: "0px",
    margin: "24px 0",
  },
  PeopleAndComm: {
    width: "200px",
    height: "24px",
    fontSize: "16px",
    lineHeight: "24px",

    color: "#24243F",

    position: "ABSOLUTE",
    marginLeft: "45px",
  },
  group3: {
    position: "static",
    height: "32px",
    left: "0px",
    top: "0px",

    flex: "none",
    order: "0",
    flexGrow: "1",
    margin: "0px 0px",
  },
  Ellipse30: {
    marginLeft: "-16px",
    marginBottom: "8px",
  },
  Ellipse31: {
    left: "0px",
    top: "0px",
  },
  frame233: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "524px",
    height: "128px",
    left: "0px",
    top: "0px",

    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "48px 0px",
  },
  whatIsYourProjects: {
    position: "static",
    width: "524px",
    height: "24px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    margin: "16px auto",
  },
  whatIsTheMost: {
    position: "static",
    width: "524px",
    height: "24px",
    left: "0px",

    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    margin: "30px 0 10px 0",
  },
  step1: {
    position: "static",
    width: "524px",
    height: "32px",
    left: "0px",
    top: "0px",
    fontSize: "24px",
    lineHeight: "32px",

    color: "#24243F",
  },
  frame234: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "302px",
    height: "72px",
    left: "403px",
    top: "0px",

    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px 30px",
  },
  aboutYourProject: {
    position: "static",
    width: "302px",
    height: "48px",
    left: "0px",
    top: "24px",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#24243F",
  },
  AboutStep1: {
    fontWeight: "bold",
    position: "static",
    width: "50px",
    height: "24px",
    left: "0px",
    top: "0px",
    fontSize: "16px",
    lineHeight: "32px",
    color: "#24243F",
  },
  frame590: {
    width: "453.32px",
    height: "290.09px",
    left: "16px",
    top: "132px",
  },
  MapStep1Vector: {
    position: "absolute",
    left: "42px",
    top: "41.19px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-150px",
      //width: '373px',
      left: "127px",
      top: "77px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-100px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-150px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
  },

  frame263: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "45px",
    width: "561px",
    height: "576px",
  },
  frame625: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "471px",
    height: "208px",
    left: "45px",
    top: "45px",
  },
  illustrasjoner10popover: {},
  popoverText1: {
    fontWeight: "bold",
    position: "static",
    width: "471px",
    height: "72px",
    left: "0px",
    top: "136px",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#24243F",
    marginTop: "16px",
  },
  popoverText2Header: {
    fontWeight: "bold",
    position: "static",
    width: "471px",
    height: "72px",
    left: "0px",
    top: "136px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    marginTop: "16px",
  },
  popoverText2: {
    position: "static",
    width: "471px",
    height: "72px",
    left: "0px",
    top: "136px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    marginBottom: "20px",
  },
  popoverText3Header: {
    fontWeight: "bold",
    position: "static",
    width: "471px",
    height: "72px",
    left: "45px",
    top: "285px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    marginTop: "32px",
  },
  popoverText3: {
    position: "static",
    width: "471px",
    height: "72px",
    left: "45px",
    top: "285px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
  },
  closePopover: {
    position: "static",
    marginBottom: "61px",
    marginLeft: "362px",
    cursor: "pointer",
  },
  popover: {
    // top: "668px",
    // left: "32px",
    // borderRadius: "20px",
    // backgroundColor: "#F9FAFE",
    // boxShadow: 'none'
  },
  exitpopover: {
    width: "644px",
    height: "313px",
    padding: "40px",
  },
  frame195: {
    width: "564px",
    height: "33px",
  },
  closeExitPopover: {
    position: "static",
    marginBottom: "61px",
    cursor: "pointer",
  },
  frame193: {
    width: "564px",
    height: "233px",
  },
  frame192: {
    width: "564px",
    height: "137px",
  },
  exitPopoverText1: {
    fontWeight: "bold",
    position: "static",
    width: "524px",
    height: "33px",
    left: "0px",
    top: "136px",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#24243F",
  },
  frameExit235: {
    width: "264px",
    height: "48px",
    marginTop: "48px",
    marginBottom: "48px",
    marginLeft: "310px",
  },
  popoverRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
  },
  addMoreButton: {
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#2D86D9",
    flex: "none",
    order: "0",
    flexGrow: "0",
    textTransform: "none",
    textDecoration: "none",
  },
  removeButton: {
    position: "static",
    fontSize: "16px",
    lineHeight: "24px",

    alignItems: "flex-end",
    color: "#2D86D9",
    flex: "none",
    order: "0",
    flexGrow: "0",
    textTransform: "none",
    textDecoration: "none",
  },
  frameLinks: {
    display: "flex",
  },
});

class MapStep13 extends Component {

  //Local storage
  mapStep13Data;
    constructor(props) {
        super(props);
        this.state = {
            result: '',
            activity:'',
            impact:'',
            addMoreResult:"Legg til mer",
            addMoreActivity:"Legg til mer",
            addMoreImpact:"Legg til mer",
            inputFieldResult: [ ],
            inputFieldActivity: [ ],
            inputFieldImpact: [ ],
        }

        this.onChangeResult = this.onChangeResult.bind(this);
        this.onChangeActivity = this.onChangeActivity.bind(this);
        this.onChangeImpact = this.onChangeImpact.bind(this);
        this.removeFieldButtonResult = this.removeFieldButtonResult.bind(this);
        this.onAddFieldResult = this.onAddFieldResult.bind(this);
        this.removeFieldButtonActivity = this.removeFieldButtonActivity.bind(this);
        this.onAddFieldActivity = this.onAddFieldActivity.bind(this);
        this.removeFieldButtonImpact = this.removeFieldButtonImpact.bind(this);
        this.onAddFieldImpact = this.onAddFieldImpact.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    //On change event for 3 text
    onChangeResult (i, e) {
      let values = [...this.state.inputFieldResult];
      values[i] = e.target.value;
      this.setState({ inputFieldResult: values });
    };
    onChangeActivity (i, e) {
      let values = [...this.state.inputFieldActivity];
      values[i] = e.target.value;
      this.setState({ inputFieldActivity: values });
    };
    onChangeImpact (i, e) {
      let values = [...this.state.inputFieldImpact];
      values[i] = e.target.value;
      this.setState({ inputFieldImpact: values });
    };
    //Dynamic textBox function for Result Textbox
    removeFieldButtonResult = () => {
      const values = [...this.state.inputFieldResult];
      if (values.length >= 2) {
          values.splice(values.length -1, 1);
          this.setState({inputFieldResult:values});
      }
      this.setState({addMoreResult:"Legg til mer"});
    };
    
    onAddFieldResult = () => {
      this.state.inputFieldResult.length >= 2
        ? this.setState({addMoreResult:"Maks antall valgt"})
        : this.setState({addMoreResult:"Legg til mer"});
      if (this.state.inputFieldResult.length === 3) {
          this.setState({addMoreResult:"Maks antall valgt"});
      }
      if (this.state.inputFieldResult.length < 2) {
        this.setState({inputFieldResult:[...this.state.inputFieldResult,""]})
        
      }
      if (this.state.inputFieldResult.length > 1 && this.state.inputFieldResult.length < 2) {
        //setInputField([...inputFields, { textField: "" }]);
        this.setState({inputFieldResult:[...this.state.inputFieldResult, ""]})
        this.setState({addMoreResult:"Max Rows Added"});
      }
    };
  //Dynamic textBox function for Activity Textbox
  removeFieldButtonActivity = () => {
    const values = [...this.state.inputFieldActivity];
    if (values.length >= 2) {
        values.splice(values.length - 1, 1);
        this.setState({inputFieldActivity:values});
    }
    this.setState({addMoreActivity:"Legg til mer"});
  };

  onAddFieldActivity = () => {
    this.state.inputFieldActivity.length >= 2
      ? this.setState({addMoreActivity:"Maks antall valgt"})
      : this.setState({addMoreActivity:"Legg til mer"});
    if (this.state.inputFieldActivity.length === 2) {
        this.setState({addMoreActivity:"Maks antall valgt"});
    }
    if (this.state.inputFieldActivity.length < 2) {
      this.setState({inputFieldActivity:[...this.state.inputFieldActivity,""]})
      
    }
    if (this.state.inputFieldActivity.length > 1 && this.state.inputFieldActivity.length < 2) {
      this.setState({inputFieldActivity:[...this.state.inputFieldActivity, ""]})
      this.setState({addMoreActivity:"Max Rows Added"});
    }
  };
  //Dynamic textBox function for Impact Textbox
  removeFieldButtonImpact = () => {
    const values = [...this.state.inputFieldImpact];
    if (values.length >= 2) {
        values.splice(values.length - 1, 1);
        this.setState({inputFieldImpact:values});
    }
    this.setState({addMoreImpact:"Legg til mer"});
  };
  
  onAddFieldImpact = () => {
    this.state.inputFieldImpact.length >= 2
      ? this.setState({addMoreImpact:"Maks antall valgt"})
      : this.setState({addMoreImpact:"Legg til mer"});
    if (this.state.inputFieldImpact.length === 2) {
        this.setState({addMoreImpact:"Maks antall valgt"});
    }
    if (this.state.inputFieldImpact.length < 2) {
      this.setState({inputFieldImpact:[...this.state.inputFieldImpact,""]})
      
    }
    if (this.state.inputFieldImpact.length > 1 && this.state.inputFieldImpact.length < 2) {
      this.setState({inputFieldImpact:[...this.state.inputFieldImpact, ""]})
      this.setState({addMoreImpact:"Max Rows Added"});
    }
  };

    onSubmit(e){
        e.preventDefault()
    };
  //Component Lifecycle  
    componentWillUpdate(nextProps, nextState){
        localStorage.setItem('mapStep13', JSON.stringify(nextState));
    }
    componentDidMount(){
        this.mapStep13Data = JSON.parse(localStorage.getItem('mapStep13'));
        if (localStorage.getItem('mapStep13')) {
            this.setState({
                inputFieldResult: this.mapStep13Data.inputFieldResult,
                inputFieldActivity: this.mapStep13Data.inputFieldActivity,
                inputFieldImpact: this.mapStep13Data.inputFieldImpact,
                addMoreResult:  this.mapStep13Data.addMoreResult,
                addMoreActivity:  this.mapStep13Data.addMoreActivity,
                addMoreImpact:  this.mapStep13Data.addMoreActivity
            })  
        } else {
            this.setState({
                inputFieldResult: [''],
                inputFieldActivity: [''],
                inputFieldImpact: [''],
                addMoreResult:"Legg til mer",
                addMoreActivity:"Legg til mer",
                addMoreImpact:"Legg til mer",
            })
            
        }
    };  

  

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.mapItStep1}>
        <Grid item xs className={classes.frame590}>
          <img
            src={MapStep1Vector}
            alt="Vector162"
            className={classes.MapStep1Vector}
          />
        </Grid>
        <Grid item xs className={classes.frame747}>
          <Grid item xs className={classes.frame746}>
            <Grid item xs className={classes.frame745}>
              <Grid item xs className={classes.frame744}>
                <Grid item xs className={classes.frame274}>
                  <Grid item xs className={classes.frame271}>
                    <Grid item xs className={classes.frame210}>
                      <Grid item xs className={classes.group3}>
                        <img
                          src={EllipseFull}
                          alt="Ellipse31"
                          className={classes.Ellipse31}
                        />
                        <img
                          src={Ellipse30}
                          alt="Ellipse30"
                          className={classes.Ellipse30}
                        />{" "}
                      </Grid>
                      <Typography className={classes.PeopleAndComm}>
                      Resultat og effekt
                      </Typography>
                    </Grid>
                    <Typography className={classes.whatIsYourProjects}>
                    Hvilket   {" "}<PopupMap10 />{" "}  vil du oppnå?
                    </Typography>
                    {this.state.inputFieldResult.map((inputField, index) => (
                        <TextField
                          key={index}
                          variant="outlined"
                          aria-label="empty textarea"
                          placeholder={index + 1 + ".  Maks 10 ord"}
                          multiline
                          minRows={1}
                          maxRows={1}
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          className={classes.textField}
                          type="text"
                          name="result"
                          id='result'
                          value={inputField || ''}
                          onChange={this.onChangeResult.bind(this, index)}
                        
                        ></TextField>
                      ))}
                    <Grid item xs className={classes.frame192}>
                      <Grid item xs className={classes.frameLinks}>
                        <Button
                          onClick={this.onAddFieldResult}
                          className={classes.addMoreButton}
                        >
                          {this.state.addMoreResult}
                        </Button>
                        <Button
                          onClick={this.removeFieldButtonResult}
                          className={classes.removeButton}
                        >
                          Fjern siste
                        </Button>
                      </Grid>
                    </Grid>
                    <Typography className={classes.whatIsYourProjects}>
                    Hvilke aktiviteter vil du gjennomføre for å oppnå disse resultatene?
                    </Typography>
                    {this.state.inputFieldActivity.map((inputField, index) => (
                        <TextField
                          key={index}
                          variant="outlined"
                          aria-label="empty textarea"
                          placeholder={index + 1 + ".  Maks 10 ord"}
                          multiline
                          minRows={1}
                          maxRows={1}
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          className={classes.textField}
                          type="text"
                          name="activity"
                          id='activity'
                          value={inputField || ''}
                          onChange={this.onChangeActivity.bind(this, index)}
                        
                        ></TextField>
                      ))}
                    <Grid item xs className={classes.frame192}>
                      <Grid item xs className={classes.frameLinks}>
                        <Button
                          onClick={this.onAddFieldActivity}
                          className={classes.addMoreButton}
                        >
                          {this.state.addMoreActivity}
                        </Button>
                        <Button
                          onClick={this.removeFieldButtonActivity}
                          className={classes.removeButton}
                        >
                          Fjern siste
                        </Button>
                      </Grid>
                    </Grid>
                    <Typography className={classes.whatIsYourProjects}>
                    Er det noen større   {" "}<PopupMap8 />{" "} av prosjektet ditt?
                    </Typography>
                    {this.state.inputFieldImpact.map((inputField, index) => (
                        <TextField
                          key={index}
                          variant="outlined"
                          aria-label="empty textarea"
                          placeholder={index + 1 + ". Maks 10 ord"}
                          multiline
                          minRows={1}
                          maxRows={1}
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          className={classes.textField}
                          type="text"
                          name="impact"
                          id='impact'
                          value={inputField || ''}
                          onChange={this.onChangeImpact.bind(this, index)}
                        ></TextField>
                      ))}
                    <Grid item xs className={classes.frame192}>
                      <Grid item xs className={classes.frameLinks}>
                        <Button
                          onClick={this.onAddFieldImpact}
                          className={classes.addMoreButton}
                        >
                          {this.state.addMoreImpact}
                        </Button>
                        <Button
                          onClick={this.removeFieldButtonImpact}
                          className={classes.removeButton}
                        >
                          Fjern siste
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs className={classes.frame234}>
                  <Typography noWrap className={classes.AboutStep1}>
                    Steg 1
                  </Typography>

                  <Typography className={classes.aboutYourProject}>
                  Om prosjektet ditt
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs className={classes.frame743}>
              <Grid item xs className={classes.frame190}>
                <Grid order="1" item xs className={classes.frameExitButton}>
                <ExitPopupStep1 />
                </Grid>
                <Grid item xs className={classes.frame235}>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235TertiaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.backButton}
                      to="/Main/MapItStep1_2"
                      component={Link}
                    >
                      Tilbake
                    </Button>
                  </Grid>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235PrimaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.nextButton}
                      to="/Main/MapItStep1_4"
                      component={Link}
                    >
                      Neste
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs className={classes.exampleGuide}>
                <Grid order="0" item xs className={classes.frameillustrasjoner10}>
                  <img
                    src={Illustrasjoner10}
                    alt="illustration10"
                    className={classes.illustrasjoner10}
                  />
                </Grid>
                <Grid item xs className={classes.frame203}>
                  <Typography className={classes.frame203Text}>
                  Trenger du hjelp? Se eksempler
                  </Typography>
              
                  <DudePopup3 />
                
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles, {withTheme:true}) (MapStep13)