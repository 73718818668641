import React from 'react'
import { Box, Container, makeStyles, Paper, Typography } from '@material-ui/core'

const styles=makeStyles({
    bar:{
        height:'1260px',
        justifyContent:'center',
        display:'flex',
        alignItems:'flex-start',
        marginTop:'50px',
        margin:'auto',
    }
})

export default function Privacy() {
    const classes = styles();
    return (
        <div className={classes.bar}>
           <Container height='900px' >
               <Paper elevation={3} style={{backgroundColor:'#FBF9F8',marginBottom:'50px',overflowY:'scroll', height:'1200px'}}  >
                    <Box variant='h4'  component={Typography} sx={{paddingTop:'20px',display:'flex',alignItems:'center',justifyContent:'center',fontWeight:700}}>
                        Brukervilkår
                    </Box>
                    <Box variant='body' component='div'  sx={{padding:'60px', fontFamily: 'Verdana', fontSize:15}}>
                        <Box variant='body'  >
                            Ved bruk av våre digitale kanaler aksepterer du disse vilkårene for bruk:
                        </Box>
 
                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>
                            1.      Tillatt bruk
                        </Box>
                        <Box variant='body' component='div' mt={1}>
                            Du kan kun benytte innhold fra våre digitale kanaler til ikke-kommersielt, personlig formål, 
                            og/eller for å lære om Norge Unlimited ved Tøyen Unlimited sine tjenester, og utelukkende i 
                            samsvar med brukervilkårene.
                        </Box>
                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>2.      Forbudt bruk</Box>
                        <Box variant='body' component='div' mt={1}>
                            Ved å benytte våre digitale kanaler sier du deg enig i at du ikke vil:
                        </Box>
                        <ul>
                            <li style={{marginBottom:'16px'}}>
                                Bruke våre digitale kanaler i strid med disse bruksvilkårene.
                            </li>
                            <li style={{marginBottom:'16px'}}>
                                Kopiere, modifisere eller imitere arbeidet, dekonstruere eller demontere siden, eller på annen måte 
                                forsøke å oppdage kildekoden eller tillate en tredjepart å gjøre det.
                            </li >
                            <li style={{marginBottom:'16px'}}>
                                Selge, tildele, viderelisensiere, distribuere, utnytte kommersielt, eller på annen måte overføre rettigheter
                                 til eller tilgjengeliggjøre for en tredjepart, innhold eller tjenester på noen måte.
                            </li>
                            <li style={{marginBottom:'16px'}}>
                                Benytte eller starte et automatisert system, inkludert, men ikke begrenset til: «roboter», «spiders» 
                                eller «offline readers» som benytter siden på en måte som sender flere forespørsler til serveren i en 
                                gitt tidsperiode enn det som vil være rimelig å anta at et menneske kan produsere ved å benytte vanlig 
                                online nettleser. 
                            </li>
                            <li style={{marginBottom:'16px'}}>
                                Bruke våre digitale kanaler på en måte som ødelegger, deaktiverer, overbelaster eller forringer kanalene 
                                eller forstyrrer andre parters bruk og glede av kanalene.
                            </li>
                            <li style={{marginBottom:'16px'}}>
                                Speile eller inkludere kanalene helt eller delvis i enhver annen kanal.
                            </li>
                            <li style={{marginBottom:'16px'}}>
                                Forsøke å få uautorisert tilgang til våre digitale kanaler.
                            </li>
                            <li style={{marginBottom:'16px'}}>
                                Få tilgang til våre digitale kanaler på en annen måte enn innenfor de rammene som Norge Unlimited ved Tøyen 
                                Unlimited tilbyr for tilgang til kanalene.
                            </li>
                            <li style={{marginBottom:'16px'}}>
                                Benytte våre digitale kanaler til et formål eller på en måte som forbys i henhold til vilkårene.
                            </li>
                            <li style={{marginBottom:'16px'}}>
                                Enhver uautorisert bruk av innholdet eller av kanalene som kan være i strid med patentloven, 
                                opphavsrett, varemerker og andre lover.
                            </li>
                        </ul>
                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>3.  Opphavsrett og varemerker</Box>
                        <Box variant='body' component='div' mt={1}>
                            Våre digitale kanaler er beskyttet av gjeldende åndsverkslovgivning og andre lover, inkludert lover om 
                            beskyttelse av varemerker og opphavsrett. Våre digitale kanaler, inkludert alle åndsverk i kanalene, 
                            tilhører og er Norge Unlimited ved Tøyen Unlimited eller dets lisensgivere (hvis noen) sin eiendom. 
                            Norge Unlimited ved Tøyen Unlimited har all opphavsrett til innholdet. Dersom det ikke er gitt spesifikk 
                            tillatelse til å bruke bestemt innhold, skal innholdet ikke kopieres, reproduseres, endres, publiseres, 
                            lastes opp, postes, overføres, utføres eller distribueres på noen måte, og du godtar å ikke endre, leie, låne, 
                            selge, distribuere, kringkaste eller opprette imitert arbeid av innholdet på siden, delvis eller i sin helhet, 
                            på noen måte. Tøyen Norge Unlimited og Tøyen Unlimited sin logo og andre merker som brukes av Norge Unlimited 
                            ved Tøyen Unlimited fra tid til annen er varemerker og Norge Unlimited ved Tøyen Unlimited sin eiendom.
                        </Box>

                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>
                            4.      Informasjon og materiale du registrerer og deler hos oss
                        </Box>
                        <Box variant='body'  mt={1}>
                            Du garanterer at du har nødvendige rettigheter til materiale du registrerer eller deler i våre digitale kanaler og at du gir oss, godkjenning til å bruke men ikke publisere dette materialet. Du garanterer at du ikke har til hensikt å bedra eller gi misvisende informasjon, og at materialet du leverer ikke bryter disse brukervilkårene.
                        </Box>

                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>5.      Linker til tredjepartsnettsider</Box>
                        <Box variant='body'  mt={1}>
                            Linker til eller informasjon om tredjepartsnettsider er kun ment for din bekvemmelighet. Dersom du benytter disse linkene vil du forlate våre digitale kanaler. Dersom du benytter linkene til eksterne sider vil du forlate våre digitale kanaler og være underlagt brukervilkårene og personvernet som er gjeldene på disse sidene.                         
                        </Box>
                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>6.      Nedlasting av filer</Box>
                        <Box variant='body'  mt={1}>
                            Norge Unlimited ved Tøyen Unlimited kan ikke garantere at filene som er tilgjengelig for nedlasting via våre digitale kanaler er fri for virus eller annen skadelig datakode, filer eller programmer.
                        </Box>
                        <Box variant='body'  mt={2}>
                            Hvis du mener at vi behandler personopplysninger i strid med personopplysningsloven, har du rett til å klage til Datatilsynet. Før du eventuelt gjør dette, vil vi gjerne at du kontakter oss slik at vi kan besvare dine spørsmål eller oppklare eventuelle misforståelser. 
                        </Box>

                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>7.      Ansvarsfraskrivelse/begrensning av ansvar</Box>
                        <Box variant='body'  mt={1}>
                            Norge Unlimited ved Tøyen Unlimited og våre tjenesteleverandører, lisensgivere og leverandører gir ingen garanti for våre digitale kanalers eller innholdets egnethet, pålitelighet, tilgjengelighet, tidsriktighet, sikkerhet eller nøyaktighet. All informasjon, programvare, produkter, tjenester og grafiske elementer tilbys «as is», uten noen form for garanti.
                        </Box>
                        <Box variant='body'  mt={1}>
                            Så langt det lar seg gjøre innenfor gitt lovgivning skal Norge Unlimited ved Tøyen Unlimited og deres tjenesteleverandører, lisensgivere eller leverandører ikke under noen omstendigheter holdes ansvarlig for eventuelle tap eller skader i noen form, som måtte oppstå ved eller i forbindelse med bruk av våre digitale kanaler, uansett om det er basert på en kontrakt, erstatning, forsømmelse, ansvar, og selv om Norge Unlimited ved Tøyen Unlimited eller noen av Norge Unlimited ved Tøyen Unlimited sine partnere har blitt informert om muligheten for skade.
                        </Box>

                        <Box variant='body' color='#4da6ff' fontSize='16px' mt={2}>8.      Erstatning</Box>
                        <Box variant='body'  mt={1}>
                            Du forstår og godtar at du står personlig ansvarlig for egen oppførsel i våre digitale kanaler. Du samtykker til å erstatte, forsvare og holde Norge Unlimited ved Tøyen Unlimited, tilknyttede selskaper, partnere, lisensgivere, ansatte, agenter, og alle tredjeparts informasjonstjenester utenfor skade fra og mot alle krav, tap, utgifter, skader og kostnader (inkludert, men ikke begrenset til direkte, tilfeldige, følgeskader og indirekte skader) og realistiske advokatutgifter som et resultat av eller som skyldes bruk, misbruk eller manglende evne til å bruke våre digitale kanaler eller innholdet, eller ethvert brudd på disse vilkårene.
                        </Box>

                    </Box>
                </Paper>
            </Container>

        </div>
    )
}