import React, { Component } from "react";
import {
  Button,
  Grid,
  Typography,
  withStyles,
  TextField,
} from "@material-ui/core";
import Illustrasjoner10 from "../../Images/Main/Illustrasjoner10.png";
import Ellipse30 from "../../Images/MapIt/MapStep3/ellipse30Map3.svg";
import Ellipse31 from "../../Images/Main/Ellipse31.png";
import VectorStep3_1 from "../../Images/MapIt/MapStep3/VectorStep3_1.svg";
import { Link } from "react-router-dom";

import ExitPopup from "../ExitDialoge/ExitPopup.js";
import PopupMap1 from './PopupMap1.js';
import PopupMap2 from './PopupMap2.js';
import PopupMap3 from './PopupMap3.js';
import PopupMap4 from './PopupMap4.js';
import PopupMap5 from './PopupMap5.js';
import PopupMap10 from './PopupMap10.js';
import DudePopup3 from "./DudePopup3.js";

const styles = (theme) => ({
  mapItStep1: {
    maxWidth: "1440px",
    height: "1170px",

    // display: 'flex',
    // //padding: "0px 163.5px 100px 163.5px",
    // overflowY: "visible",
  },
  frame747: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "1440px",
    left: "0px",
    top: "0px",
  },
  frame746: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "50px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-145px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-274px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-346px",
      marginTop: "50px",
    },
  },
  frame745: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    height: "564px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  frame743: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0px 70px 0px 70px",
    bottom: "0px",
    position: "relative",
    width: "1440px",
    height: "85px",
    top: "959px",
    flexGrow: "1",

    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "166px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "283px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "329px",
    },
  },
  frame190: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "524px",
    height: "48px",
    left: "735px",
    top: "37px",
    flex: "none",
    order: "1",
    flexGrow: "0",

    // eslint-disable-next-line
    ["@media (min-width:1440px)"]: {
      marginLeft: "30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-10px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-140px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-255px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-300px",
      width: "440px",
    },
  },
  frame235: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "264px",
    height: "48px",
    left: "260px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  nextButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "10px",
    position: "static",
    left: "144px",
    top: "0px",
    background: "#24243F",
    borderRadius: "24px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#24243F",
    },
  },
  backButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "120px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },

  exitButton: {
    position: "static",
    width: "120px",
    height: "48px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  frame203: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "229px",
    height: "56px",
    left: "101px",
    top: "29px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      width: "200px",
    },
  },
  frame203buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    position: "static",
    width: "75px",
    height: "24px",
    left: "0px",
    top: "32px",
    borderRadius: "8px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginBottom: "12px",
  },
  frame203button: {
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#2D86D9",
    flex: "none",
    order: "0",
    flexGrow: "0",
    marginLeft: "-8px",
    marginTop: "17px",
    textTransform: "none",
    textDecoration: "none",
  },
  frame203Text: {
    position: "static",
    left: "229px",
    top: "24px",
    right: "0px",
    bottom: "0px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  frameillustrasjoner10: {
    position: "static",
    left: "85px",
    top: "85px",
    right: "0px",
    bottom: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "10px 10px 0px 16px",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      marginLeft: "-10px",
    },
  },
  exampleGuide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "0px",
    position: "static",
    width: "330px",
    height: "85px",
    left: "70px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },

  frame744: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px 181px 0px 403px",
    position: "static",
    width: "1440px",
    height: "515px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "43px",
  },
  frame274: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "483px",
    left: "735px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "-40px",
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "0px",
    },
  },
  frame271: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "307px",
    left: "0px",
    top: "176px",
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "16px",
    position: "static",
    width: "524px",
    height: "195px",
    left: "0px",
    top: "112px",
    background: "#FFFFFF",
    border: "2px solid #E3E5E9",
    boxSizing: "border-box",
    borderRadius: "8px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginBottom: "90px",
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#bbbecc",
  },
  group2: {
    position: "static",
    width: "492px",
    height: "163px",
    left: "16px",
    top: "16px",
    flex: "none",
    order: "0",
    alignSelf: "stretch",
    flexGrow: "1",
    margin: "0px 10px",
  },
  textSubHeader1: {
    position: "static",
    width: "524px",
    height: "120px",
    left: "0px",
    top: "48px",
    margin: "20px auto",
    color: "#24243F",
    fontSize: "16px",
  },
  textSubHeader2: {
    position: "static",
    width: "524px",
    height: "120px",
    left: "0px",
    top: "48px",
    margin: "20px auto",
    color: "#24243F",
    fontSize: "16px",
  },
  textSubHeader3: {
    position: "static",
    width: "524px",
    height: "120px",
    left: "0px",
    top: "48px",
    margin: "20px auto",
    color: "#24243F",
    fontSize: "16px",
  },
  frame210: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "32px",
    left: "0px",
    top: "0px",
  },
  ellipseText: {
    width: "260px",
    height: "24px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    position: "ABSOLUTE",
    marginLeft: "45px",
  },
  group3: {
    position: "static",
    height: "32px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "1",
    margin: "0px 0px",
  },
  Ellipse30: {
    marginLeft: "-26px",
  },
  Ellipse31: {
    left: "0px",
    top: "0px",
  },
  frame233: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "128px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "48px 0px",
  },
  ItsImportant: {
    position: "static",
    width: "524px",
    height: "72px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    marginTop: "20px",
  },
  step1: {
    position: "static",
    width: "524px",
    height: "32px",
    left: "0px",
    top: "0px",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#24243F",
  },
  frame234: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "302px",
    height: "72px",
    left: "403px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "-30px 30px",
  },
  aboutYourProject: {
    position: "static",
    width: "302px",
    height: "48px",
    left: "0px",
    top: "24px",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#24243F",
  },
  AboutStep1: {
    fontWeight: "bold",
    position: "static",
    width: "50px",
    height: "24px",
    left: "0px",
    top: "0px",
    fontSize: "16px",
    lineHeight: "32px",
    color: "#24243F",
  },
  frame590: {
    width: "453.32px",
    height: "290.09px",
    left: "16px",
    top: "132px",
  },
  MapStep1Vector: {
    position: "absolute",
    left: "42px",
    top: "41.19px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-150px",
      left: "127px",
      top: "77px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-100px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-150px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
  },
  underlineWord: {
    borderBottom: "1px solid #24243F",
  },
});

class MapStep3_1 extends Component {
  mapStep33Data;
  constructor(props) {
      super(props);
      this.state = {
          shortTerm: '',
          midTerm: '',
          longTerm: '', 
      }

      this.onChangeShortTerm  = this.onChangeShortTerm.bind(this);
      this.onChangeMidTerm = this.onChangeMidTerm.bind(this);
      this.onChangeLongTerm = this.onChangeLongTerm.bind(this)
      this.onSubmit = this.onSubmit.bind(this);
  }
  // on change event for 3 TextBox
  onChangeShortTerm(e) {
      this.setState({ shortTerm: e.target.value})
  };
  onChangeMidTerm(e) {
    this.setState({ midTerm: e.target.value})
  };
  onChangeLongTerm(e) {
    this.setState({ longTerm: e.target.value})
  };
  onSubmit(e){
      e.preventDefault()
  };
//Local storage Lifecycle
  componentWillUpdate(nextProps, nextState){
      localStorage.setItem('mapStep33', JSON.stringify(nextState));
  }
  componentDidMount(){
      this.mapStep33Data = JSON.parse(localStorage.getItem('mapStep33'));
      if (localStorage.getItem('mapStep33')) {
          this.setState({
              shortTerm: this.mapStep33Data.shortTerm,
              midTerm: this.mapStep33Data.midTerm,
              longTerm: this.mapStep33Data.longTerm
          })  
      } else {
          this.setState({
            shortTerm: '',
            midTerm: '',
            longTerm: '',
          })
          
      }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.mapItStep1}>
        <Grid item xs className={classes.frame590}>
          <img
            src={VectorStep3_1}
            alt="Vector162"
            className={classes.MapStep1Vector}
          />
        </Grid>
        <Grid item xs className={classes.frame747}>
          <Grid item xs className={classes.frame746}>
            <Grid item xs className={classes.frame745}>
              <Grid item xs className={classes.frame744}>
                <Grid item xs className={classes.frame274}>
                  <Grid item xs className={classes.frame271}>
                    <Grid item xs className={classes.frame210}>
                      <Grid item xs className={classes.group3}>
                        <img
                          src={Ellipse31}
                          alt="Ellipse31"
                          className={classes.Ellipse31}
                        />
                        <img
                          src={Ellipse30}
                          alt="Ellipse3-3"
                          className={classes.Ellipse30}
                        />{" "}
                      </Grid>
                      <Typography className={classes.ellipseText}>
                      Tidslinjer og milepæler
                      </Typography>
                    </Grid>
                    <Typography className={classes.textSubHeader1}>
                      <span><PopupMap3 /></span>
                      <br />
                      Hva forventer du å se på kort sikt, når du har satt i gang aktiviteter og prosjektet ditt er i gang? Disse {" "}
                      <PopupMap1 />{" "}
                      kan ofte tallfestes.
                    </Typography>
                    <TextField
                      variant="outlined"
                      aria-label="empty textarea"
                      placeholder="Maks 50 ord"
                      multiline
                      minRows={4}
                      maxRows={8}
                      fullWidth
                      inputProps={{
                        maxLength: 300,
                      }}
                      type="text"
                      name="shortTerm"
                      id='shortTerm'
                      value={this.state.shortTerm}
                      onChange={this.onChangeShortTerm}
                    ></TextField>
                    <Typography className={classes.textSubHeader2}>
                      <span><PopupMap4 /></span>
                      <br />
                      Når du går videre fra kort sikt, kan du kanskje oppdage endringer i holdninger, atferd, kunnskap, ferdigheter el. for målgruppen din. Basert på disse endringene, hvilke 
                      {" "}
                      <PopupMap2 />{" "} forventer du å oppnå med prosjektet ditt etter det første året (midtveis)?
                    </Typography>
                    <TextField
                      variant="outlined"
                      aria-label="empty textarea"
                      placeholder="Maks 50 ord"
                      multiline
                      minRows={4}
                      maxRows={8}
                      fullWidth
                      inputProps={{
                        maxLength: 300,
                      }}
                      type="text"
                      name="midTerm"
                      id='midTerm'
                      value={this.state.midTerm}
                      onChange={this.onChangeMidTerm}
                    ></TextField>
                    <Typography className={classes.textSubHeader3}>
                      <span><PopupMap5 /></span>
                      <br />
                      Hva er samlet sett de langsiktige endringene som prosjektet ditt skal skape? Tenk på målgruppen din og spør deg selv hvilken konkret og målbar forskjell prosjektet ditt vil gi dem. Disse {" "}
                      <PopupMap10 />{" "}
                      er nærmest visjonen til prosjektet ditt.
                    </Typography>
                    <TextField
                      variant="outlined"
                      aria-label="empty textarea"
                      placeholder="Maks 50 ord"
                      multiline
                      minRows={4}
                      maxRows={8}
                      fullWidth
                      inputProps={{
                        maxLength: 300,
                      }}
                      type="text"
                      name="longTerm"
                      id='longTerm'
                      value={this.state.longTerm}
                      onChange={this.onChangeLongTerm}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid item xs className={classes.frame234}>
                  <Typography noWrap className={classes.AboutStep1}>
                    Steg 3
                  </Typography>

                  <Typography className={classes.aboutYourProject}>
                  Din endringshistorie
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs className={classes.frame743}>
              <Grid item xs className={classes.frame190}>
                <Grid order="1" item xs className={classes.frameExitButton}>
                  <ExitPopup />
                </Grid>
                <Grid item xs className={classes.frame235}>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235TertiaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.backButton}
                      component={Link}
                      to="/Main/MapItStep3_2"
                    >
                      Tilbake
                    </Button>
                  </Grid>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235PrimaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.nextButton}
                      component={Link}
                      to="/Main/MapItStep3_4"
                      type="submit"
                    >
                      Neste
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs className={classes.exampleGuide}>
                <Grid
                  order="0"
                  item
                  xs
                  className={classes.frameillustrasjoner10}
                >
                  <img
                    src={Illustrasjoner10}
                    alt="illustration10"
                    className={classes.illustrasjoner10}
                  />
                </Grid>
                <Grid item xs className={classes.frame203}>
                  <Typography className={classes.frame203Text}>
                  Trenger du hjelp? Se eksempler
                  </Typography>

                  <DudePopup3 />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(MapStep3_1);
