import React, { Component } from 'react'
import { withStyles, Typography, Grid, Box, Button} from '@material-ui/core';
import { Link } from 'react-router-dom'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const styles= (theme) => ({
    frame193:{
        width:'579px',
        height:'708px',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-end',
        justifyContents:'flex-end',
        padding:'0px',
        background: '#FFFFFF',
        border: '1px solid #24243F',
        boxSizing: 'border-box',
        borderRadius: '12px',
        Padding:'40px'
    },
    frame290button: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"16px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        margin:'32px 0px',
        padding:'10px 20px',
        color:'black',
        backgroundColor:'white'
      },
    frame195:{
        width:'499px',
        height:'33px',
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-start',
        justifyContents:'space-between',
        padding:'0px',
        margin:'16px 0px'
    },
    closeIcon:{
        marginLeft:'120px',
        cursor: "pointer", 
    },
    frame193Title:{
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '33px',
        variant:'subtitle',
        color:'#000000',
        
    },
    frame193info1:{
        variant:'body',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color:'#24243F',
        margin:'8px 0px'
    },
    frame193info2:{
        variant:'body',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color:'#24243F',
        margin:'8px 0px 24px 0px'
    },
    frame235button1: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"16px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        margin:'0px 24px',
        padding:'10px 20px',
        color:'#24243F',
        backgroundColor:'white',
        marginBottom:'16px'
    },
    frame235button2: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"16px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        margin:'0px 24px',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F',
        marginBottom:'16px'
    },
})


class SignUp extends Component {
    userData;
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            reason: '',
            postSubmitted:false,
             open: false,
        }

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeReason = this.onChangeReason.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.clearLocalstorage = this.clearLocalstorage.bind(this)
    }
    // Form event and function
    onChangeName(e) {
        this.setState({ name: e.target.value})
    }

    onChangeEmail(e){
        this.setState({email: e.target.value})
    };
    
    onChangeReason(e){
        this.setState({reason: e.target.value})
    }

    onSubmit(e){
        e.preventDefault()

    }
    //PopUp Open function
    handleClickOpen(event) {
        this.setState({open:true});
    }
    //Popup Close function
    handleClose(event) {
        this.setState({open: false});
    };  
    clearLocalstorage(event){
        localStorage.clear();
    }
    componentDidMount(){
        this.userData = JSON.parse(localStorage.getItem('user'));
        if (localStorage.getItem('user')) {
            this.setState({
                name: this.userData.name,
                email: this.userData.email,
                reason: this.userData.reason
            })  
        } else {
            this.setState({
                name: '',
                email: '',
                reason: ''
            })
            
        }
    };
    componentWillUpdate(nextProps, nextState){
        localStorage.setItem('user', JSON.stringify(nextState));
    }
    
    //Validation
    submitPost = e => {
        if (!this.state.name || !this.state.email) {
            alert(" Name and Emails are mendatory field")
            e.preventDefault();
        } else {
            this.setState({
                postSubmitted: true
            });
            
        }
    }

   

    render() {
        const {classes} = this.props;
        
        return (
            <div >
                <Button variant="contained" className={classes.frame290button} onClick={this.handleClickOpen}>
                    Start
                </Button>
                
                <Dialog  open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" 
                    style=
                        {{border: '1px solid #24243F',
                            boxSizing: 'border-box',
                            borderRadius: '12px'}} >
                    <DialogContent >
                        <Box padding='20px' overflow='visible'>
                            <Grid  item xs direction='row' className={classes.frame195}>
                                <Grid item xs>
                                    <Typography noWrap className={classes.frame193Title} >Før du setter i gang med VIS DET! </Typography>
                                </Grid>
                                <Grid item xs >
                                    <CloseOutlinedIcon className={classes.closeIcon} onClick={() =>{this.handleClose();localStorage.clear()}}/>
                                </Grid>
                            </Grid>
                        <hr color='#BBBECC' margin='16px 0px' />
                        <DialogContentText className={classes.frame193info1}>
                        NB! Dette nettstedet lagrer ikke informasjon. Det betyr at når du forlater nettstedet, vil dataene dine gå tapt. Du har mulighet til å lagre en PDF av ditt innhold underveis.




                        </DialogContentText>
                        <DialogContentText className={classes.frame193info2}>
                        Vi spør om dine kontaktdetaljer for å forstå hvem som bruker verktøyet, og vi deler aldri din informasjon videre.
                        </DialogContentText>
                        <form className={classes.form} onSubmit={this.onSubmit}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} >
                                    <DialogContentText color='#24243F' >
                                    Ditt navn*
                                    </DialogContentText>
                                    <TextField
                                        autoComplete="Name"
                                        name="Name"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Ditt navn"
                                        autoFocus
                                        value={this.state.name}
                                        onChange={this.onChangeName}
                                    />
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <DialogContentText color='#24243F' >
                                    Din e-post*
                                    </DialogContentText>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Din e-post"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChangeEmail}
                                       // helperText={error ? "invalid email" : ""}
                                       // error ={error }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DialogContentText color='#24243F' >
                                    "Hvorfor er du interessert i å måle din samfunnseffekt?
                                    </DialogContentText>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        name="reason"
                                        label="Valgfri"
                                        type="reason"
                                        id="reason"
                                        autoComplete="reason"
                                        value={this.state.reason}
                                        onChange={this.onChangeReason}
                                    />
                                </Grid>
                            </Grid><br></br>
                        </form>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' className={classes.frame235button1} onClick={this.handleClose}>
                        Avbryt
                        </Button>
                        <Button 
                            variant='contained' 
                            className={classes.frame235button2} 
                            component={Link} to="/Main" 
                            onClick={this.submitPost}
                            disabled={!(this.state.name && this.state.email)}
                            >
                           Send inn
                        </Button>
                    </DialogActions>
                </Dialog>                
            </div>
        )
    }
}
export default withStyles(styles, {withTheme: true }) (SignUp);