import React, { Component } from 'react';
import { Button, withStyles } from '@material-ui/core';
import jsPDF from 'jspdf';
import  group49 from '../../Images/MapIt/MapStep1Pdf/group49.png';
import rectangle220 from '../../Images/MapIt/MapFinishPdf/rectangle220.jpg';
import  frame834 from '../../Images/MapIt/MapFinishPdf/frame834.jpg'
import  Illustrationer05 from '../../Images/MapIt/MapFinishPdf/Illustrationer05.jpg'
import { Link } from 'react-router-dom'


const styles = (theme) => ({
    downloadButton: {
        width: "190px",
        height: "48px",
        border: "2px solid #BBBECC",
        borderRadius: "24px",
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "10px 20px",
        color: "#24243F",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "white",
        },
        marginBottom: '50px'
    },
    frame703button2: {
        borderRadius: "24px",
        textTransform:"none",
        width:"230px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"14px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 20px',

    },
    frame235button3: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'normal',
        fontSize:"16px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        margin:'0px 24px',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F',
        marginBottom:'16px',
        border: "2px solid #BBBECC",
        boxSizing: "border-box",
        "&:hover": {
            backgroundColor: "#24243F",
          },
  
    },
  
})

class MapItPdf extends Component {
    MapStep32Data;
    MapStep33Data;
    MapStep34Data;
    MapStep35Data
     constructor(props){
         super(props);
         this.MapStep32Data = JSON.parse(localStorage.getItem('mapStep32'));
         this.MapStep33Data = JSON.parse(localStorage.getItem('mapStep33'));
         this.MapStep34Data = JSON.parse(localStorage.getItem('mapStep34'));
         this.MapStep35Data = JSON.parse(localStorage.getItem('mapStep35'));
         
         this.state = {
             projectName : "",
             workArea : "",
             mainActivity :  "",
             shortTerm : "",
             midTerm : "",
             longTerm : "",
             smTerm : "",
             mlTerm : "",
             barrier : "",
            };
            
            this.pdfGenerate = this.pdfGenerate.bind(this);
        };
        
   componentDidMount(){
    if (localStorage.getItem('mapStep32') === null ) {
        this.setState ( {
          projectName : "",
          workArea : "",
          mainActivity :  "",    
        })
    } else {
        this.setState ({
          projectName : this.MapStep32Data.projectName,
          workArea : this.MapStep32Data.workArea,
          mainActivity :  this.MapStep32Data.mainActivity,    
        })
    }

      if (localStorage.getItem('mapStep33') === null) {
          this.setState ( {
              shortTerm : "",
              midTerm : "",
              longTerm : "",
  
          })
      } else {
        this.setState ({
              shortTerm : this.MapStep33Data.shortTerm,
              midTerm : this.MapStep33Data.midTerm,
              longTerm : this.MapStep33Data.longTerm,
              })
      };
      if (localStorage.getItem('mapStep34') === null) {
          this.setState ({
              smTerm : "",
              mlTerm : ""
          })
      } else {
        this.setState ({
              smTerm : this.MapStep34Data.smTerm,
              mlTerm : this.MapStep34Data.mlTerm,
          })
      };
      if (localStorage.getItem('mapStep35') === null) {
          this.setState ({
              barrier : ""
          })
      } else {
        this.setState ({
              barrier : this.MapStep35Data.barrier,
          })
      };

   }
    pdfGenerate = () => {


        const date = new Date().toLocaleDateString();

        var doc = new jsPDF ('portrait','px', 'a4','false');
        doc.addImage(rectangle220,'JPG',19,19,350,130);
        doc.addImage(group49,'JPG',320,50,100,150);

        doc.setFont(undefined,"bold").setFontSize(12).text(40,90,'KARTELGG DET: Prosjektbeskrivelse');
        var projectName1 = doc.splitTextToSize(this.state.projectName,140)
        doc.setFont('h3',"normal").setFontSize(24).text(40,112,projectName1);

        doc.setFont(undefined,"bold").setFontSize(12).text(40,165,'1. Kontekst');
        var title1 = doc.splitTextToSize('Beskriv området du jobber i. Dette inkluderer geografi, demografi, egenskapene og de viktigste utfordringene prosjektet ditt håper å adressere.', 320);
        doc.setFont(undefined,"bold").setFontSize(9).text(40,180,title1);
        var workArea1 = doc.splitTextToSize(this.state.workArea,350)
        doc.setFont(undefined,"normal").setFontSize(8).text(40,200,workArea1);
        var title2 = doc.splitTextToSize('Hva er noen av hovedaktivitetene i dette prosjektet?', 320);
        doc.setFont(undefined,"bold").setFontSize(9).text(40,242,title2);
        var mainActivity1 = doc.splitTextToSize(this.state.mainActivity,350)
        doc.setFont(undefined,"normal").setFontSize(8).text(40,252,mainActivity1);

        doc.setFont(undefined,"bold").setFontSize(12).text(40,312,'2. Tidslinje og milepæler');
        doc.setFont(undefined,"bold").setFontSize(9).text(40,327,'På kort sikt');
        var title3 = doc.splitTextToSize("Når prosjektet har startet, hvilke resultater forventer du å se på kort sikt? Disse kortsiktige resultatene kan ofte tallfestes.", 260);
        doc.setFont(undefined,"bold").setFontSize(9).text(40,340,title3);
        var shortTerm1 = doc.splitTextToSize(this.state.shortTerm,300)
        doc.setFont(undefined,"normal").setFontSize(8).text(40,360,shortTerm1);

        doc.setFont(undefined,"bold").setFontSize(9).text(40,400,'På mellomlang sikt');
        var title4 = doc.splitTextToSize("Hvilke resultater forventer du å oppnå etter ca. ett år av prosjektet ditt? ", 280);
        doc.setFont(undefined,"bold").setFontSize(9).text(40,413,title4);
        var midTerm1 = doc.splitTextToSize(this.state.midTerm,300)
        doc.setFont(undefined,"normal").setFontSize(8).text(40,423,midTerm1);

        doc.setFont(undefined,"bold").setFontSize(9).text(40,467,'På lang sikt');
        var title5 = doc.splitTextToSize("Hva er de langsiktige resultatene av prosjektet ditt? ", 260);
        doc.setFont(undefined,"bold").setFontSize(9).text(40,480,title5);
        var longTerm1 = doc.splitTextToSize(this.state.longTerm,300)
        doc.setFont(undefined,"normal").setFontSize(8).text(40,490,longTerm1);


        doc.line(40, 580,410, 580);
        doc.addImage(frame834,'JPG',48,592,12,15);
        doc.setFont(undefined,"bold").setFontSize(10).text(63,600, 'Vis Det!');
        doc.setFont(undefined,"bold").setFontSize(8).setTextColor('#656C88').text(91,600, 'laget av Norge Unlimited');
        doc.setFont(undefined,"normal").setFontSize(6).setTextColor('#000000').text(48,610, 'visdet@toyenunlimited.no | www.norgeunlimited.no');
        doc.setFont(undefined,"normal").setFontSize(6).text(340,610, 'Lastet ned '+ date);
        doc.setFont(undefined,"normal").setFontSize(6).setTextColor('#000000').text(400,610, '1/2');

        //New Page
        doc.addPage();
        doc.addImage(group49,'JPG',280,50,100,150);
        //Enablers - short to Mid terms
        doc.setFont(undefined,"bold").setTextColor('#000000').setFontSize(12).text(40,160,'3. Tilretteleggende faktorer');
        doc.setFont(undefined,"bold").setFontSize(9).text(40,175,'Fra kort til mellomlang sikt');
        var title6 = doc.splitTextToSize("Hvordan vil de umiddelbare eller kortsiktige endringene føre til endringer på mellomlang sikt? Hva annet skjer som sikrer at dine endringer på mellomlang sikt kan oppnås?", 260);
        doc.setFont(undefined,"bold").setFontSize(9).text(40,188,title6);
        var smTerm1 = doc.splitTextToSize(this.state.smTerm,300)
        doc.setFont(undefined,"normal").setFontSize(8).text(40,208,smTerm1);
        //Enablers - Mid term to long term
        doc.setFont(undefined,"bold").setFontSize(9).text(40,248,'Fra mellomlang til lang sikt');
        var title61 = doc.splitTextToSize("Hvordan vil endringer på mellomlang sikt føre til endringer på lang sikt? Hva annet skjer som sikrer at dine endringer og visjoner er oppnåelig?", 260);
        doc.setFont(undefined,"bold").setFontSize(9).text(40,261,title61);
        var mlTerm1 = doc.splitTextToSize(this.state.mlTerm,300)
        doc.setFont(undefined,"normal").setFontSize(8).text(40,282,mlTerm1);
        //Barriers
        doc.setFont(undefined,"bold").setFontSize(12).text(40,337,'4. Hindringer');
        var title7 = doc.splitTextToSize("Hvilke hindringer forventer du å møte på i gjennomføringen av aktivitetene? Hva tror du kan forårsake forstyrrelser eller komme i veien for at prosjektet ditt oppnår de positive endringene du har planlagt?", 320);
        doc.setFont(undefined,"bold").setFontSize(9).text(40,352,title7);
        var barrier1 = doc.splitTextToSize(this.state.barrier,350)
        doc.setFont(undefined,"normal").setFontSize(8).text(40,377,barrier1);

        doc.addImage(Illustrationer05,'JPG',40,535,32,32);
        doc.setFont(undefined,"normal").setTextColor('#24243F').setFontSize(8).text(76,544,"Hva er en prosjektbeskrivelse?");
        var narrative = doc.splitTextToSize("En prosjektbeskrivelse er en tekstversjon som oppsummerer hva du har tenkt å oppnå med prosjektet.  Dette dokumentet fungerer som en introduksjon til formålet med aktivitetene dine og de tiltenkte fordelene for dine målgrupper. For mer informasjon, se KARTLEGG DET. ", 280);
        doc.setFont(undefined,"normal").setTextColor('#000000').setFontSize(6).text(76,552,narrative);

        doc.line(40, 580,410, 580);
        doc.addImage(frame834,'JPG',48,592,12,15);
        doc.setFont(undefined,"bold").setFontSize(10).text(63,600, 'Vis Det!');
        doc.setFont(undefined,"bold").setFontSize(8).setTextColor('#656C88').text(91,600, 'laget av Norge Unlimited');
        doc.setFont(undefined,"normal").setFontSize(6).setTextColor('#000000').text(48,610, 'visdet@toyenunlimited.no | www.norgeunlimited.no');
        doc.setFont(undefined,"normal").setFontSize(6).text(340,610, 'Lastet ned '+ date);
        doc.setFont(undefined,"normal").setFontSize(6).setTextColor('#000000').text(400,610, '2/2');
        doc.save('KartleggDet!.pdf');
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Button
                  variant="text"
                  className={classes.frame235button3} 
                  onClick={() => this.pdfGenerate()}
                  component={Link} 
                  to="/Main"
                > 
                  Last ned 
                </Button>
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true}) (MapItPdf)