import {
  Grid,
  withStyles,
  Typography,
  TextField,
  Box,
  InputLabel,
  FormControl,
  Select,
  Button,
} from "@material-ui/core";
import React, { Component } from "react";
import LandingFooter from "../Footer/LandingFooter.js";
import "../../App.css";
import LandingHeader from "../Header/LandingHeader.js";
import LandingDialoge from "./LandingDialoge.js";

const styles = (theme) => ({
  frame804: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "856px",
    height: "749px",
    top: "86px",
    //left: "332px",
    marginBottom: "64px",
  },
  //* 1. Grid name : About us
  frame803: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "856px",
    height: "168px",
    color: "#24243F",
    
  },
  frame234: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "214px",
    height: "48px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "32px",
    lineHeight: "48px",
    fontFeatureSetting: "ss03 on",
    variant: "h3",
  },
  frame274: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "524px",
    height: "168px",
    left: "118px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    fontFeatureSetting: "ss03 on",
    variant: "body",
  },
  frame274Info: {},
  //* 2. Grid name : Contact
  frame802: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "856px",
    height: "517px",
    marginTop: "64px",
    color: "#24243F",
  },
  frame276: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "214px",
    height: "48px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "32px",
    lineHeight: "48px",
    fontFeatureSetting: "ss03 on",
    variant: "h3",
  },
  frame606: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    width: "524px",
    height: "517px",
    left: "118px",
  },
  frame604: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "445px",
    margin: "0px 0px 24px 0px",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 260,
    maxWidth: 300,
  },
  frame213: {
    position: "static",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    width: "468px",
    height: "96px",
  },
  frame235: {
    position: "static",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    width: "264px",
    height: "48px",
    margin: "-24px 0px 0px 260px",
  },
  frame235button: {
    borderRadius: "24px",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    variant: "body",
    justifyContent: "center",
    alignItems: "center",
    margin: "32px 0px",
    padding: "10px 20px",
    color: "black",
    backgroundColor: "white",
  },
});

class LandingContact extends Component {
  landingContactData;
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      section: "",
      message: "",
      postSubmitted: false,
    };

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeSection = this.onChangeSection.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }
  // Form event and function
  onChangeName(e) {
    this.setState({ name: e.target.value });
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  onChangeSection(e) {
    this.setState({ section: e.target.value });
  }
  onChangeMessage(e) {
    this.setState({ message: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
  }

  componentWillUpdate(nextProps, nextState) {
    localStorage.setItem("landingContact", JSON.stringify(nextState));
  }
  componentDidMount() {
    this.landingContactData = JSON.parse(
      localStorage.getItem("landingContact")
    );
    if (localStorage.getItem("landingContact")) {
      this.setState({
        name: this.landingContactData.name,
        email: this.landingContactData.email,
        section: this.landingContactData.section,
        message: this.landingContactData.message,
      });
    } else {
      this.setState({
        name: "",
        email: "",
        section: "",
        message: "",
      });
    }
  }

  //*form reset function
  resetForm() {
    this.setState({
      name: "",
      email: "",
      section: "",
      message: "",
    });
  }

  render() {
    const { classes } = this.props;
    const frame274text =
      "I Norge Unlimited tror vi at de som står nærmest et samfunnsproblem er de som er best egnet til å løse det. VIS DET! sikrer at alle som vil skape positive samfunnsendringer har verktøyene de trenger for å vise effekten av sitt arbeid. Vi har undersøkt de beste metodene rundt om i verden og prøvd å forenkle dem så mye som mulig. Den digitale versjonen av VIS DET! har blitt til takket være den uvurderlige ekspertisen til våre venner i Accenture.";
    return (
      <div className="App">
        <LandingHeader />
        <div className="contact-content">
          <div className="contact">
            <Box className={classes.frame804}>
              <Box className={classes.frame803}>
                <Box component={Typography} className={classes.frame234}>
                  Om oss
                </Box>
                <Box component={Typography} className={classes.frame274}>
                  {frame274text}
                </Box>
              </Box>
              <Box className={classes.frame802}>
                <Box component={Typography} className={classes.frame276}>
                  Kontakt
                </Box>
                <Box component={Typography} className={classes.frame606}>
                  <form id="contact-form" className={classes.frame806}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.boxStyle}
                          autoComplete="Name"
                          name="Name"
                          variant="outlined"
                          fullWidth
                          id="name"
                          label="Navn"
                          autoFocus
                          value={this.state.name}
                          onChange={this.onChangeName}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="email"
                          label="E-post"
                          name="email"
                          value={this.state.email}
                          onChange={this.onChangeEmail}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Del
                          </InputLabel>
                          <Select
                            native
                            label="Please select"
                            inputProps={{
                              name: "age",
                              id: "outlined-age-native-simple",
                            }}
                            value={this.state.section}
                            onChange={this.onChangeSection}
                          >
                            <option aria-label="None" value="" />
                            <option value={"Map It"}>Kartlegg det</option>
                            <option value={"Track It"}>Undersøk det</option>
                            <option value={"Tell It"}>Fortell det</option>
                            <option value={"Other"}>Annet</option>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          multiline
                          rows={4}
                          name="message"
                          label="Melding"
                          type="message"
                          id="message"
                          autoComplete="message"
                          value={this.state.message}
                          onChange={this.onChangeMessage}
                        />
                      </Grid>
                      <Grid item xs className={classes.frame235}>
                        <Button
                          variant="contained"
                          className={classes.frame235button}
                          onClick={this.resetForm}
                        >
                          Slett tekst
                        </Button>
                        <LandingDialoge />
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
        <LandingFooter />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(LandingContact);
