import React from "react";
import {
  Button,
  Grid,
  Box,
  makeStyles,
  withStyles,
  Typography,
  FormControl,
  FormHelperText,
  FormGroup,
} from "@material-ui/core";
import Illustrasjoner11 from "../../Images/MapIt/MapStep2/Illustrasjoner11.png";
import MapStep2Vector from "../../Images/MapIt/MapStep2/MapStep2Vector.svg";
import MAPITNorskGuide from '../../Images/MapIt/MapStep2/MAPITNorskGuide.pdf'
import { Link } from "react-router-dom";
//Checkbox Lib
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExitPopupStep1 from '../ExitDialoge/ExitPopupStep1.js'
const styles = makeStyles({
  mapItStep1: {
    height: "1070px",
    //height: "100%",
    //padding: "165px 163.5px 149px 163.5px",
    //overflowY: "visible",
  },
  frame747: {
    display: "flex",
    "flex-direction": "column",
    "align-items": "flex-start",
    padding: "0px",

    position: "absolute",
    width: "1440px",
    left: "0px",
    top: "0px",
  },
  frame746: {
    display: "flex",
    "flex-direction": "column",
    "align-items": "flex-start",
    padding: "0px",

    position: "static",
    width: "1440px",
    left: "0px",
    top: "256px",

    flex: "none",
    order: "1",
    "flex-grow": "0",
    marginTop: "115px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-145px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-274px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-346px",
      marginTop: "50px",
    },
  },
  frame745: {
    display: "flex",
    "flex-direction": "column",
    "align-items": "flex-start",
    padding: "0px",

    position: "static",
    width: "1440px",
    height: "500px",
    left: "0px",
    top: "256px",

    flex: "none",
    order: "1",
    "flex-grow": "0",
    margin: "72px 0px",
  },
  frame743: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    "align-items": "flex-end",
    padding: "0px 70px 0px 70px",
    bottom: "0px",
    position: "sticky",
    width: "1440px",
    height: "85px",
    top: "1526px",

    "flex-grow": "1",
    marginBottom: "-100px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "166px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      //marginBottom: "-220px",
      marginLeft: "283px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "329px",
      //marginBottom: "-220px",
    },
  },
  frame190: {
    display: "flex",
    "flex-direction": "row",
    "justify-content": "space-between",
    "align-items": "flex-start",
    padding: "0px",

    position: "absolute",
    width: "524px",
    height: "48px",
    left: "735px",
    top: "37px",

    flex: "none",
    order: "1",
    "flex-grow": "0",

    // eslint-disable-next-line
    ["@media (min-width:1440px)"]: {
      marginLeft: "30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-10px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-140px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-255px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-300px",
      width: "440px",
    },
  },
  frame235: {
    display: "flex",
    "flex-direction": "row",
    "align-items": "flex-start",
    padding: "0px",

    position: "static",
    width: "264px",
    height: "48px",
    left: "260px",
    top: "0px",

    flex: "none",
    order: "1",
    "flex-grow": "0",
  },
  nextButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "10px",
    position: "static",
    left: "144px",
    top: "0px",
    background: "#24243F",
    borderRadius: "24px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    //variant: "body",
    justifyContent: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#24243F",
    },
  },
  backButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    //variant: "body",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",

    display: "flex",
    "flex-direction": "row",
    position: "static",
    width: "120px",
    height: "48px",
    left: "0px",
    top: "0px",

    border: "2px solid #BBBECC",
    "box-sizing": "border-box",

    flex: "none",
    order: "0",
    "flex-grow": "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },

  exitButton: {
    position: "static",
    width: "120px",
    height: "48px",

    border: "2px solid #BBBECC",
    "box-sizing": "border-box",

    flex: "none",
    order: "0",
    "flex-grow": "0",
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    //variant: "body",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  frame203: {
    display: "flex",
    "flex-direction": "column",
    "justify-content": "flex-end",
    "align-items": "flex-start",
    padding: "0px",

    position: "static",
    width: "229px",
    height: "56px",
    left: "101px",
    top: "29px",

    flex: "none",
    order: "1",
    "flex-grow": "0",

    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      width: "200px",
    },
  },
  frame203buttonContainer: {
    display: "flex",
    "flex-direction": "row",
    "align-items": "center",
    padding: "0px",

    position: "static",
    width: "75px",
    height: "24px",
    left: "0px",
    top: "32px",

    "border-radius": "8px",

    flex: "none",
    order: "1",
    "flex-grow": "0",
    marginBottom: "12px",
  },
  frame203button: {
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",

    "font-size": "16px",
    "line-height": "24px",

    display: "flex",
    "align-items": "center",

    color: "#2D86D9",

    flex: "none",
    order: "0",
    "flex-grow": "0",
    marginLeft: "-8px",
    marginTop: "17px",
    "text-transform": "none",
    "text-decoration": "none",
  },
  frame203Text: {
    position: "static",
    left: "229px",
    top: "24px",
    right: "0px",
    bottom: "0px",
    "font-style": "normal",
    "font-weight": "normal",
    fontSize: "16px",
    "line-height": "24px",

    color: "#24243F",

    flex: "none",
    order: "0",
    "flex-grow": "0",
  },
  frameillustrasjoner10: {
    position: "static",
    left: "85px",
    top: "85px",
    right: "0px",
    bottom: "0px",

    flex: "none",
    order: "0",
    "flex-grow": "0",
    margin: "10px 10px 0px 16px",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      marginLeft: "-10px",
    },
  },
  exampleGuide: {
    display: "flex",
    "flex-direction": "row",
    "align-items": "flex-end",
    padding: "0px",

    position: "static",
    width: "330px",
    height: "85px",
    left: "70px",
    top: "0px",

    flex: "none",
    order: "0",
    "flex-grow": "0",
  },

  frame744: {
    display: "flex",
    "flex-direction": "row",
    "align-items": "flex-start",
    padding: "0px 181px 0px 403px",

    position: "static",
    width: "1440px",
    height: "483px",
    left: "0px",
    top: "0px",

    flex: "none",
    order: "1",
    "flex-grow": "0",
    //margin: "43px 0px",
  },
  frame277: {
    display: "flex",
    "flex-direction": "column",
    "align-items": "flex-start",
    padding: "0px",
    position: "static",
    width: "523px",
    height: "432px",
    left: "735px",
    top: "0px",

    flex: "none",
    order: "1",
    "flex-grow": "0",
    marginTop: "-40px",
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "0px",
    },
  },
  frame600: {
    display: "flex",
    "flex-direction": "column",
    "align-items": "flex-start",
    padding: "0px",
    position: "relative",
    width: "523px",
    height: "48px",
    left: "0px",
    top:'220px',
    flex: "none",
    order: "1",
    "flex-grow": "0",
  },
  frame213:{
    position:'static',
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
    padding:'0px',
    width:'467px',
    height:'122px',
    marginTop: '50px'
  },
  group2: {
    position: "static",
    width: "492px",
    height: "163px",
    left: "16px",
    top: "16px",

    flex: "none",
    order: "0",
    "align-self": "stretch",
    "flex-grow": "1",
    margin: "0px 10px",
  },
  whatsYourProjects: {
    position: "static",
    width: "524px",
    height: "48px",
    left: "0px",
    top: "48px",
    margin: "20px auto",
    color: "#24243F",
    fontSize: "16px",
  },
  frame210: {
    display: "flex",
    "flex-direction": "row",
    "align-items": "center",
    padding: "0px",

    position: "static",
    width: "524px",
    height: "32px",
    left: "0px",
    top: "0px",
  },
  AimAndObj: {
    width: "136px",
    height: "24px",
    fontSize: "16px",
    "line-height": "24px",

    color: "#24243F",

    position: "ABSOLUTE",
    marginLeft: "45px",
  },
  group3: {
    position: "static",
    height: "32px",
    left: "0px",
    top: "0px",

    flex: "none",
    order: "0",
    "flex-grow": "1",
    margin: "0px 0px",
  },
  frame233: {
    display: "flex",
    "flex-direction": "column",
    "align-items": "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "128px",
    left: "0px",
    top: "0px",

    flex: "none",
    order: "0",
    "flex-grow": "0",
    margin: "20px 0px",
    marginBottom:'32px'
  },
  frame282: {
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
    justifyContent:'space-between',
    width:'523px',
    height:'296px',
    marginTop:'24px'
  },
  frame282Info1: {
    position: "static",
    width: "524px",
    height: "165px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    variant:'body',
    color: "#000000",
  },
  frame282Info2: {
    position: "static",
    width: "524px",
    height: "100px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    "line-height": "24px",
    variant:'body',
    color: "#000000",
    marginTop:'16px'
  },
  downloadButton:{
    borderRadius: "24px",
    textTransform:"none",
    width:"191px",
    height:"48px",
    fontWeight: '',
    fontSize:"15px" ,
    lineHeight: '24px',
    variant:'body',
    justifyContent:'center',
    alignItems:'center',
    padding:'10px 20px',
    color:'white',
    backgroundColor:'#24243F',
    marginTop:'16px',
    "&:hover": {
        boxShadow: "none",
        backgroundColor: "#24243F",
    },
  },
  step1: {
    position: "static",
    width: "524px",
    height: "32px",
    left: "0px",
    top: "8px",
    fontSize: "24px",
    "line-height": "32px",
    color: "#000000",
  },
  frame234: {
    display: "flex",
    "flex-direction": "column",
    "align-items": "flex-start",
    padding: "0px",

    position: "static",
    width: "302px",
    height: "72px",
    left: "403px",
    top: "0px",

    flex: "none",
    order: "0",
    "flex-grow": "0",
    margin: "-34px 30px",
  },
  groupDiscussion: {
    position: "relative",
    width: "302px",
    height: "48px",
    left: "0px",
    top: "12px",
    fontSize: "28px",
    "line-height": "48px",
    fontWeight:'bold',
    color: "#24243F",
    variant:'h3'
  },
  AboutStep2: {
    variant: "body",
    fontWeight: "bold",
    position: "relative",
    width: "50px",
    height: "24px",
    left: "0px",
    top: "8px",
    fontSize: "16px",
    lineHeight: "32px",
    color: "#24243F",
  },
  frame590: {
    width: "453.32px",
    height: "290.09px",
    left: "16px",
    top: "132px",
  },
  MapStep2Vector: {
    position: "absolute",
    left: "42px",
    top: "41.19px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-150px",
      //width: '373px',
      left: "127px",
      top: "77px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-100px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-150px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
  },

  frame263: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "45px",
    borderRadius: "20px",
    backgroundColor: "#F9FAFE",
    width: "561px",
    height: "402px",
    
  },
  frame625: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "471px",
    height: "208px",
    left: "45px",
    top: "45px",
  },
  illustrasjoner10popover: {},
  popoverText1: {
    //variant: "subtitle",
    fontWeight: "bold",
    position: "static",
    width: "471px",
    height: "72px",
    left: "0px",
    top: "136px",
    "font-size": "24px",
    "line-height": "32px",
    color: "#24243F",
    marginTop: "16px",
  },
  popoverText2Header: {
    //variant: "body",
    fontWeight: "bold",
    position: "static",
    width: "471px",
    height: "72px",
    left: "0px",
    top: "136px",
    "font-size": "16px",
    "line-height": "24px",
    color: "#24243F",
    marginTop: "16px",
  },
  popoverText2: {
    //variant: "body",

    position: "static",
    width: "471px",
    height: "72px",
    left: "0px",
    top: "136px",
    "font-size": "16px",
    "line-height": "24px",
    color: "#24243F",
  },
  popoverText3Header: {
    //variant: "body",
    fontWeight: "bold",
    position: "static",
    width: "471px",
    height: "72px",
    left: "45px",
    top: "285px",
    "font-size": "16px",
    "line-height": "24px",
    color: "#24243F",
    marginTop: "32px",
  },
  popoverText3: {
    //variant: "body",
    position: "static",
    width: "471px",
    height: "72px",
    left: "45px",
    top: "285px",
    "font-size": "16px",
    "line-height": "24px",
    color: "#24243F",
  },
  closePopover: {
    position: "static",
    marginBottom: "61px",
    marginLeft: "362px",
    cursor: "pointer",
  },
  popover: {
    top: "668px",
    left: "32px",
    borderRadius: '20px'
  },
});

// Checkbox Shape and color Customization //
const CustomColorCheckbox = withStyles({
    root: {
      color: "#BBBECC",
      "&$checked": {
        color: '#656C88'
      },
      margin: "16 px"
    },
    checked: {}
  })((props) => <Checkbox color="default" {...props} />);

export default function MapStep2() {
 
  
  
  const classes = styles();

  // State definition and handle change for checkbox
   
  const [state, setState] = React.useState({
    checked1: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { checked1} = state;
  const error1 = [checked1].filter((v) => v).length !== 1;
  //




  // useEffect(() => {
  //   if (userValue !== '') {
  //     setUserValue (JSON.parse(localStorage.getItem('aimAndObjective')))
  //   } else {
  //   }
  // }, [setUserValue])
  


  return (
    <div className={classes.mapItStep1}>
      <Grid item xs className={classes.frame590}>
        <img
          src={MapStep2Vector}
          alt="Vector162"
          className={classes.MapStep2Vector}
        />
      </Grid>
      <Grid item xs className={classes.frame747}>
        <Grid item xs className={classes.frame746}>
          <Grid item xs className={classes.frame745}>
            <Grid item xs className={classes.frame744}>
              <Grid item xs className={classes.frame277}>
                <Grid item xs className={classes.frame233}>
                  <Typography className={classes.step1}>
                    Steg 2: Gruppediskusjon og offline forberedelse
                  </Typography>
                  <Box className={classes.frame282}>
                    <Typography className={classes.frame282Info1}>
                    For å måle din samfunnseffekt må du først finne ut hvordan og hvorfor arbeidet ditt medfører endringer. Det betyr å identifisere forholdet mellom handlingene og resultatene dine. Selv om det ikke er noen standard metodikk for å måle samfunnseffekt, starter de fleste tilnærminger med å bruke en endringsteori. Dette bidrar til å visualisere endringsprosessen i et diagram og definere de elementene som virkelig betyr noe.
                    </Typography>
                    <Typography className={classes.frame282Info2}>
                    Vi tror den beste måten å gjøre dette på er å inkludere stemmene, erfaringene og perspektivene til de som påvirker prosjektet ditt (kolleger, frivillige, sponsorer, partnere, styremedlemmer, kunder) og de som drar nytte av det.
                    </Typography>
                    <a href={MAPITNorskGuide} target="_blank" rel="noopener noreferrer" download>
                        <Button
                            variant="contained"
                            className={classes.downloadButton}
                            >
                            Last ned guiden
                        </Button>
                    </a>
                  </Box>                  
                </Grid>
                <Grid item xs className={classes.frame600}>
                    <FormControl
                        required
                        error={error1}
                        component="fieldset"
                        variant="standard"
                        >
                        <FormGroup>                                                  
                            <FormControlLabel                                
                                style={{display:'table'}}
                                control={
                                    <div style={{display:'table-cell'}}>
                                    <CustomColorCheckbox
                                    value={checked1}
                                    onChange={handleChange}
                                    name="checked1"
                                    color="#24243F"
                                    icon={<CircleUnchecked />}
                                    checkedIcon={<CircleCheckedFilled />}
                                    style={{transform: "scale(1.5)"}}
                                    required
                                    />
                                    
                                    </div>
                                }
                                label={
                                    <Box ml={2}  className={classes.frame213}> 
                                        <Typography 
                                            variant="body"  
                                            style={{color:"#24243F", fontSize:"15px",lineHeight:'24px', fontWeight:"bold"}}
                                            >
                                            Jeg har gått gjennom guiden på egen hånd og/eller sammen med prosjektets støtteapparat. Jeg er klar til å gå videre!
                                        </Typography>
                                        <FormHelperText variant='body' style={{fontSize:"15px",lineHeight:'24px',}}>
                                        Det neste steget kan være utfordrende uten noen offline forberedelse. Vi anbefaler å gå gjennom guiden før du fortsetter.
                                        </FormHelperText>                                        
                                    </Box>
                                }                                            
                            />
                        </FormGroup>                                
                    </FormControl>  
                </Grid>
              </Grid>
              <Grid item xs className={classes.frame234}>
                <Typography noWrap className={classes.AboutStep2}>
                  Steg 2
                </Typography>

                <Typography className={classes.groupDiscussion}>
                Gruppediskusjon
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs className={classes.frame743}>
            <Grid item xs className={classes.frame190}>
              <Grid order="1" item xs className={classes.frameExitButton}>
                <ExitPopupStep1/>
              </Grid>
              <Grid item xs className={classes.frame235}>
                <Grid
                  order="1"
                  item
                  xs
                  className={classes.button235TertiaryContainer}
                >
                  <Button
                    variant="contained"
                    className={classes.backButton}
                    component={Link}
                    to="/Main/MapItStep1_4"
                    
                  >
                   Tilbake
                  </Button>
                </Grid>
                <Grid
                  order="1"
                  item
                  xs
                  className={classes.button235PrimaryContainer}
                >
                  <Button
                    variant="contained"
                    className={classes.nextButton}
                    component={Link}
                    to="/Main/MapItStep3_1"
                    disabled={!checked1}            
                    type="submit"
                  >
                    Neste
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs className={classes.exampleGuide}>
              <Grid order="0" item xs className={classes.frameillustrasjoner10}>
                <img
                  src={Illustrasjoner11}
                  alt="illustration10"
                  className={classes.illustrasjoner10}
                />
              </Grid>
              <Grid item xs className={classes.frame203}>
                <Typography className={classes.frame203Text}>
                Spørsmål?
                </Typography>
                <Grid
                  order="1"
                  item
                  xs
                  className={classes.frame203buttonContainer}
                >
                  <Button
                    className={classes.frame203button}
                    //aria-describedby={id}
                    variant='text'
                    component={Link}
                    to='/Main/Contact'
                  >
                    Kontakt Norge Unlimited
                  </Button>
                  
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
