import React from 'react';
//import Pdf from "react-to-pdf";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {  Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import rectangle220 from '../../Images/MapIt/MapStep1/rectangle220.svg'
import group49 from '../../Images/MapIt/MapStep1/group49.svg'
import  frame543_1 from '../../Images/MapIt/MapStep1/frame543_1.JPG'
import { Typography } from '@material-ui/core';


//import { makeStyles } from '@material-ui/core/styles';
//import { Link } from "react-router-dom";

const styles = makeStyles ({
    mapStep1Pdf:{
        height:'1250px',
        width:'595px',
        position:'relative',
        display:'flex',
        flexDirection:'column',
    },
    rectangle220: {
        position:'relative',
        margin:'19px 0px 0px 19px'
    },
    group49: {
        position:'absolute',
        left:'396px',
        top:'111px',
    },
    frame545:{
        position:'absolute',
        display:'flex',
        flexDirection:'column',
        alignItems: 'flex-start',
        top:'145px',
        left:'41px',
        width: '514px',
        height: '465px',
        color: '#000000'

    },
    frame546:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems: 'flex-start',
        left:'41px',
        width: '396px',
        height: '216px',
        marginBottom:'42px',
        
    },
    frame546step1:{
        position:'static',
        width:'279px',
        height:'15px',
        fontStyle:'normal',
        fontWeight:'bold',
        fontSize:'12px',
        lineHeight:'15px',
        margin:'2px 0px',
        fontFamily:'Basis Grotesque Pro'
    },
    frame546About:{
        position:'static',
        width:'279px',
        height:'48px',
        fontStyle:'normal',
        fontWeight:'normal',
        fontSize:'32px',
        lineHeight:'48px',
        variant:'h3',
        fontFeatureSettings:'ss03, on',
        marginBottom:'24px',
        fontFamily:'Basis Grotesque Pro'
    },
    frame411:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems: 'flex-start',
        width: '396px',
        height: '127px',
    },
    frame411Info:{
        position:'static',
        width:'396px',
        height:'102px',
        fontStyle:'normal',
        fontWeight:'bold',
        fontSize:'13px',
        lineHeight:'17px',
        variant:'body',
        fontFeatureSettings:'ss03, on',
        marginTop:'10px',
        fontFamily:'Basis Grotesque Pro'
    },
    frame544:{
        position:'relative',
        display:'flex',
        flexDirection:'row',
        alignItems: 'flex-start',
        left:'41px',
        width: '514px',
        height: '207px'
    },
    frame412:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems: 'flex-start',
        width: '246px',
        height: '143px',
        marginRight:'21px'
    },
    frame414:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems: 'flex-start',
        width: '247px',
        height: '207px',
        marginRight:'21px'
    },
    frame363: {
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems: 'flex-start',
        width: '246px',
        height: '37px',
        margin: '16px 0px'
    },
    frame363Info:{
        position:'static',
        width:'279px',
        height:'15px',
        fontStyle:'normal',
        fontWeight:'bold',
        fontSize:'9px',
        lineHeight:'11px',
        margin:'4px 0px',
        fontFamily:'Basis Grotesque Pro'
    },
    frame363Info2:{
        position:'static',
        width:'279px',
        height:'15px',
        fontFamily:'Avenir',
        fontStyle:'normal',
        fontWeight:'normal',
        fontSize:'8px',
        lineHeight:'11px',
    },
    frame364: {
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems: 'flex-start',
        width: '157px',
        height: '59px',
        marginBottom:'16px'
    },
    frame363_2: {
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems: 'flex-start',
        width: '247px',
        height: '48px',
        margin: '16px 0px'
    },
    frame363_3: {
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems: 'flex-start',
        width: '247px',
        height: '48px',
        margin: '0px 0px 16px 0px'
    },
    frame543:{
        position:'absolute',
        display:'flex',
        flexDirection:'column',
        alignItems: 'flex-start',
        width: '515px',
        height: '39px',
        top:'1020px',
        left:'40px'
    },
    downloadButton: {
        width: "190px",
        height: "48px",
        border: "2px solid #BBBECC",
        borderRadius: "24px",
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "10px 20px",
        color: "white",
        backgroundColor: "#24243F",
        boxShadow: "none",
        marginBottom: '50px',
        fontFamily:'Basis Grotesque Pro'
      },
      backButton: {
        width: "120px",
        height: "48px",
        border: "2px solid #BBBECC",
        borderRadius: "24px",
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "10px 20px",
        color: "#24243F",
        backgroundColor: "white",
        boxShadow: "none",
        marginBottom: '50px'
      },

})
const ref = React.createRef();
// const options = {
//     orientation: 'p',
//     format: 'a4',
//     wrap:   'false'
// };
// const options = {
//     orientation: 'portrait',
//     format: "A4",
// };


const MapStep1Pdf = ( ) => {
    const classes = styles();

    var MapStep1Data = JSON.parse(localStorage.getItem('mapStep1'));
    var MapStep13Data = JSON.parse(localStorage.getItem('mapStep13'));
    var MapStep12Data = JSON.parse(localStorage.getItem('mapStep12'));
    const date = new Date().toLocaleDateString();
    return(
        <>
        <div style={{position:'relative', marginLeft:'400px'}}>
            <div className={classes.mapStep1Pdf} ref={ref}>
                <Box item xs direction='column'>
                    <Box order='0' item xs >
                        <img src={rectangle220} alt="rectangle220" className={classes.rectangle220} />
                        <img src={group49} alt="rectangle220" className={classes.group49} />
                    </Box>
                    <Box order='1' item xs className={classes.frame545}>
                        <Box className={classes.frame546}>
                            <Box component={Typography} className={classes.frame546step1}>MAP IT: STEP 1</Box>
                            <Box component={Typography} className={classes.frame546About}>About your project</Box>
                            <Box className={classes.frame411}>
                                <Box component={Typography} className={classes.frame546step1}>
                                    1. Your project’s ultimate aim or objective
                                </Box>
                                <Box component={Typography} className={classes.frame411Info}>
                                    {MapStep1Data.aim}
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.frame544}>
                            <Box component={Typography} className={classes.frame412}>
                                <Box component={Typography} className={classes.frame546step1}>2. People and community</Box>
                                <Box component={Typography} className={classes.frame363}>
                                    <Box component={Typography} className={classes.frame363Info}>
                                        Who is your main target group?
                                    </Box>

                                    <Box component={Typography} className={classes.frame363Info2} >
                                        {MapStep12Data.targetGroup}
                                    </Box>
                                </Box>
                                <Box component={Typography} className={classes.frame364}>
                                    <Box component={Typography} className={classes.frame363Info}>
                                        What is the most important need you <br/> are addressing in this project? 
                                    </Box>
                                    
                                    <Box component={Typography} className={classes.frame363Info2} >
                                        <ol style={{paddingLeft:'8px'}}>
                                            <li>{MapStep12Data.inputField[0]}</li>
                                            <li>{MapStep12Data.inputField[1]}</li>
                                            <li>{MapStep12Data.inputField[2]}</li>
                                        </ol>
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={Typography} className={classes.frame414}>
                                <Box component={Typography} className={classes.frame546step1}>2. People and community</Box>
                                <Box component={Typography} className={classes.frame363_2} >
                                    <Box component={Typography} className={classes.frame363Info} >
                                        What result you want to achieve?
                                    </Box>
                                    
                                    <Box component={Typography} className={classes.frame363Info2}>
                                        <ol style={{paddingLeft:'8px', marginTop:'0px'}}>
                                            <li>{MapStep13Data.inputFieldResult[0]}</li>
                                            <li>{MapStep13Data.inputFieldResult[1]}</li>
                                            <li>{MapStep13Data.inputFieldResult[2]}</li>
                                        </ol>
                                    </Box>

                                </Box>
                                <Box component={Typography} className={classes.frame363_3} >
                                    <Box component={Typography} className={classes.frame363Info} >
                                        What result you want to achieve?
                                    </Box>
                                    
                                    <Box component={Typography} className={classes.frame363Info2}>
                                        <ol style={{paddingLeft:'8px', marginTop:'0px'}}>
                                            <li>{MapStep13Data.inputFieldActivity[0]}</li>
                                            <li>{MapStep13Data.inputFieldActivity[1]}</li>
                                            <li>{MapStep13Data.inputFieldActivity[2]}</li>
                                        </ol>
                                    </Box>
                                    
                                </Box>
                                <Box component={Typography} className={classes.frame363_3} >
                                    <Box component={Typography} className={classes.frame363Info} >
                                        What result you want to achieve?
                                    </Box>
                                    
                                    <Box component={Typography} className={classes.frame363Info2}>
                                        <ol style={{paddingLeft:'8px', marginTop:'0px'}}>
                                            <li>{MapStep13Data.inputFieldImpact[0]}</li>
                                            <li>{MapStep13Data.inputFieldImpact[1]}</li>
                                            <li>{MapStep13Data.inputFieldImpact[2]}</li>
                                        </ol>
                                    </Box>
                                    
                                </Box>
                                {/* Testing for longer pdf*/}
                                
                            </Box>
                        </Box>
                    </Box>
                    <Box order='3' className={classes.frame543}>
                        <hr width='515px' />
                        <Box display='flex' flexDirection='row' >
                            <img src={frame543_1} alt="frame543" />
                            <Box ml={12}>
                                Date:{date}
                            </Box>
                            
                        </Box>
                    </Box>
                </Box>
               
                     
            </div>
                      
            
            <Box marginLeft="150px" style={{position:'absolute', top:'1150px'}}>
                {/* <Pdf targetRef={ref} filename="Map.pdf" scale='1' wrap={false} options={options} >                               
                    {({toPdf}) => <Button onClick ={toPdf} variant="contained" className={classes.downloadButton}> Capture as PDF</Button> }
                </Pdf>  */}
                <Button component={Link} to="/Main/MapItStep1_4" variant="contained" style={{ marginLeft:'16px'}}
                    className={classes.backButton}
                >
                    Tilbake
                </Button>
            </Box>
            
            
            </div>               
        </>
    );
}
export default MapStep1Pdf;