import React, { Component } from 'react'
import { withStyles, Typography, Grid, Box, Button} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MapItPdf from './MapItPdf.js';

const styles= (theme) => ({
    frame193:{
        width:'579px',
        height:'708px',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-end',
        justifyContents:'flex-end',
        padding:'0px',
        background: '#FFFFFF',
        border: '1px solid #24243F',
        boxSizing: 'border-box',
        borderRadius: '12px',
        Padding:'40px'
    },
    frame235button: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"14px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        margin:'32px 0px',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F',
        marginLeft:'24px'
      },
    frame195:{
        width:'564px',
        height:'32px',
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-start',
        justifyContents:'space-between',
        padding:'0px',
        margin:'16px 0px'
    },
    closeIcon:{
        marginLeft:'240px',
        cursor: "pointer", 
    },
    frame193Title:{
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '33px',
        variant:'subtitle',
        color:'#000000',
        
    },
    frame193info1:{
        variant:'body',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color:'#24243F',
        marginTop:'16px'
    },
    frame235button2: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"16px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        margin:'0px 24px',
        padding:'10px 20px',
        color:'#24243F',
        backgroundColor:'white',
        marginBottom:'16px',
        boxShadow: "none",
        border: "2px solid #BBBECC",
        boxSizing: "border-box",  
        "&:hover": {
          backgroundColor: "white",
        },
    },

    exitButton: {
      position: "static",
      width: "120px",
      height: "48px",
      border: "2px solid #BBBECC",
      boxSizing: "border-box",
      flex: "none",
      order: "0",
      flexGrow: "0",
      borderRadius: "24px",
      textTransform: "none",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "24px",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 20px",
      color: "#24243F",
      backgroundColor: "white",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "white",
      },
    },

})

class ExitMapIt extends Component {
    userData;
    constructor(props) {
        super(props);
        this.state = {
             open: false,
        }

        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    //PopUp Open function
    handleClickOpen(event) {
        this.setState({open:true});
    }
    //Popup Close function
    handleClose(event) {
        this.setState({open: false});
    };

    render() {
        const {classes} = this.props;

        return (
            <div >
                  <Button
                      variant="contained"
                      className={classes.exitButton}
                      onClick={this.handleClickOpen}
                  >
                    Avslutt 
                  </Button>

                <Dialog  open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" 
                    style=
                        {{border: '1px solid #24243F',
                            boxSizing: 'border-box',
                            borderRadius: '12px'}} >
                    <DialogContent >
                        <Box padding='20px' overflow='visible'>
                            <Grid  item xs direction='row' className={classes.frame195}>
                                <Grid item xs>
                                    <Typography noWrap className={classes.frame193Title} >Avslutt Kartlegg Det </Typography>
                                </Grid>
                                <Grid item xs >
                                    <CloseOutlinedIcon className={classes.closeIcon} onClick={() =>{this.handleClose()}}/>
                                </Grid>
                            </Grid>
                            <hr color='#BBBECC' margin='16px 0px' />
                            <DialogContentText className={classes.frame193info1}>
                                OBS! Vær oppmerksom på at når du avslutter, kan du miste du dine data på nettsiden.
                                Klikk “last ned” for å lagre en kopi av arbeidet ditt.

                            </DialogContentText>
                        </Box>                        
                    </DialogContent>
                    <DialogActions>
                        <Box mr={-4}>
                        <Button variant='contained' className={classes.frame235button2}  onClick={this.handleClose}>
                        Tilbake
                        </Button>
                        </Box>
                        <MapItPdf/> 
                    </DialogActions>
                </Dialog>                
            </div>
        )
    }
}
export default withStyles(styles, {withTheme: true }) (ExitMapIt);