import * as React from 'react';
import { Modal, Box, Button, makeStyles } from '@material-ui/core';

import preview from '../Images/TrackIt/TrackHome/preview.svg'


const styles = makeStyles({
    modalBox1:{
        position: 'absolute',
        width:'600px',
        height:'900px',
        top: '70%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        p: 0,
        
    },
    modalBox2:{
        position: 'absolute',
        width:'994px',
        height:'1406px',
        top: '100%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        p: 0,
        
    },
    previewButton1: {
        borderRadius: "24px",
        border: '2px solid #E3E5E9',
        boxSizing: 'border-box',
        textTransform:"none",
        width:"160px",
        height:"48px",
        fontWeight: 'normal',
        fontSize:"16px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 20px',
        color:'#24243F',
        backgroundColor:'white',
        margin:'0px 30px 0px 0px'
    },
    previewButton2: {
        borderRadius: "24px",
        border: '2px solid #E3E5E9',
        boxSizing: 'border-box',
        textTransform:"none",
        width:"97px",
        height:"48px",
        fontWeight: 'normal',
        fontSize:"16px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 20px',
        color:'#24243F',
        backgroundColor:'white',
        
    },
});

export default function BasicModal() {
    const classes= styles();
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  return (
    <div>
      <Button variant='outlined' onClick={handleOpen1} className={classes.previewButton1} >Forhåndsvisning</Button>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{overflow:'scroll'}}
      >
        <Box className={classes.modalBox1}>
          <img src={preview} alt=" preview 57" className={classes.image57} />
        </Box>
      </Modal>
    </div>
  );
}