import React, { Component } from 'react';
import { Button, withStyles } from '@material-ui/core';
import jsPDF from 'jspdf';
import  group49 from '../../Images/MapIt/MapStep1Pdf/group49.png';
import rectangle220 from '../../Images/MapIt/MapStep1Pdf/rectangle220.png';
import  frame834 from '../../Images/MapIt/MapStep1Pdf/frame834.png'
import { Link } from 'react-router-dom'


const styles = (theme) => ({
    downloadButton: {
        width: "190px",
        height: "48px",
        border: "2px solid #BBBECC",
        borderRadius: "24px",
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "10px 20px",
        color: "#24243F",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "white",
        },
        marginBottom: '50px'
      },
      frame235button3: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'normal',
        fontSize:"16px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        margin:'0px 24px',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F',
        marginBottom:'16px',
        border: "2px solid #BBBECC",
        boxSizing: "border-box",
        "&:hover": {
            backgroundColor: "#24243F",
          },
  
    },

})

class ExitStep1Pdf extends Component {
  MapStep1Data;
  MapStep12Data;
  MapStep13Data
  constructor(props) {
    super(props);
    this.MapStep1Data = JSON.parse(localStorage.getItem('mapStep1'));
    this.MapStep12Data = JSON.parse(localStorage.getItem('mapStep12'));
    this.MapStep13Data = JSON.parse(localStorage.getItem('mapStep13'));

    this.state = {
      aim: "",
      target : "",
      need0 : "",
      need1 : "",
      need2 : "",
      result0 : "",
      result1 : "",
      activity0 : "",
      activity1 : "",
      impact0 : "",
      impact1 : "",

  }
    
    this.pdfGenerate = this.pdfGenerate.bind(this);
  }

  componentDidMount(){
    
    if (localStorage.getItem('mapStep1') === null ) {
      this.setState({
          aim: ""
        })  
      } else {
        this.setState({
          aim: this.MapStep1Data.aim
        })  
    };

  /*  if (localStorage.getItem('mapStep12') === null) {
      this.setState({
        target: "",
        need0 : "",
        need1 :"",
        need2 : "",
      })         
    } else {
      this.setState({
        target : this.MapStep12Data.targetGroup,
        need0 : this.MapStep12Data.inputField[0],
        need1 : this.MapStep12Data.inputField[1],
        need2 : this.MapStep12Data.inputField[2],
      })
    };
    */
    if (localStorage.getItem('mapStep12') === null  ) {
      this.setState({
        target: "",
        need0 : "",
        need1:"",
        need2:""
      })
    } else {
      this.setState({
        target : this.MapStep12Data.targetGroup,
        need0 : this.MapStep12Data.inputField[0],
      })

      if (typeof this.MapStep12Data.inputField[1] === 'undefined') {
        this.setState({
          need1:"",
        })
      }else {
        this.setState({
          need1 : this.MapStep12Data.inputField[1],
        })
      };
      if (typeof this.MapStep12Data.inputField[2] === 'undefined') {
        this.setState({
          need2:"",
        })
      }else {
        this.setState({
          need2 : this.MapStep12Data.inputField[2],
        })
      };
    };

    if (localStorage.getItem('mapStep13') === null ) {
        this.setState({
          result0 : "",
          result1 : "",
          activity0 : "",
          activity1 : "",
          impact0 :"",
          impact1 : "",
        })  
      } else {
        this.setState({
          result0 : this.MapStep13Data.inputFieldResult[0],
          activity0 : this.MapStep13Data.inputFieldActivity[0],
          impact0 : this.MapStep13Data.inputFieldImpact[0],
        })
        if (typeof this.MapStep13Data.inputFieldResult[1] === 'undefined') {
          this.setState({
            result1 : "",
          })
        }else {
          this.setState({
            result1 : this.MapStep13Data.inputFieldResult[1],
          })
        };  
        if (typeof this.MapStep13Data.inputFieldActivity[1] === 'undefined') {
          this.setState({
            activity1 : "",
          })
        }else {
          this.setState({
            activity1 : this.MapStep13Data.inputFieldActivity[1],
          })
        };
        if (typeof this.MapStep13Data.inputFieldImpact[1] === 'undefined') {
          this.setState({
            impact1 : "",
          })
        }else {
          this.setState({
            impact1 : this.MapStep13Data.inputFieldImpact[1],
          })
        };
      }
  };  
    pdfGenerate = () => {

      const date = new Date().toLocaleDateString();
        var doc = new jsPDF ('portrait','px', 'a4','false');
        doc.addImage(rectangle220,'PNG',19,19,350,300);
        doc.addImage(group49,'PNG',306,100,70,100);
        doc.setFont(undefined,"bold").setFontSize(12).text(41,135,'KARTLEGG DET: STEG 1');
        doc.setFont('h3',"normal").setFontSize(32).text(41,162,'Om prosjektet');
        doc.setFont("Verdana","bold").setFontSize(12).text(41,210,'1. Prosjektets endelige mål eller formål');
        var aim1 = doc.splitTextToSize(this.state.aim, 260);
        doc.setFont(undefined,"bold").setFontSize(13).text(41,229,aim1);
        doc.setFont(undefined,"bold").setFontSize(12).text(41,340,'2. Personer og samfunn');
        doc.setFont(undefined,"bold").setFontSize(9).text(41,370,'Hvem er hovedmålgruppen din?');
        doc.setFont(undefined,"normal").setFontSize(8).text(41,379, '1. ');
        var target1 = doc.splitTextToSize(this.state.target, 160);
        doc.setFont(undefined,"normal").setFontSize(8).text(46,379, target1 );

        var text2 = doc.splitTextToSize('Hva er de viktigste behovene du adresserer i dette prosjektet?', 90);
        doc.setFont(undefined,"bold").setFontSize(9).text(41,395, text2);
        var need01 = doc.splitTextToSize(this.state.need0, 160);
        doc.setFont(undefined,"normal").setFontSize(8).text(41,415, '1. '+ need01 );
        var need11 = doc.splitTextToSize(this.state.need1, 160);
        doc.setFont(undefined,"normal").setFontSize(8).text(41,423, '2. '+ need11 );
        var need21 = doc.splitTextToSize(this.state.need2, 160);
        doc.setFont(undefined,"normal").setFontSize(8).text(41,431, '3. '+ need21 );
        doc.setFont(undefined,"bold").setFontSize(12).text(240,340,'3. Resultat og effekt');

        doc.setFont(undefined,"bold").setFontSize(9).text(240,370,'Hvilket resultat vil du oppnå?');
        var result01 = doc.splitTextToSize(this.state.result0, 160);
        doc.setFont(undefined,"normal").setFontSize(8).text(240,380, '1. '+ result01);
        var result11 = doc.splitTextToSize(this.state.result1, 160);
        doc.setFont(undefined,"normal").setFontSize(8).text(240,388, '2. '+ result11);

        doc.setFont(undefined,"bold").setFontSize(9).text(240,409, 'Hvilke aktiviteter vil du gjøre for å oppnå resultatene?');
        var activity01 = doc.splitTextToSize(this.state.activity0, 160);
        doc.setFont(undefined,"normal").setFontSize(8).text(240,419, '1. '+ activity01);
        var activity11 = doc.splitTextToSize(this.state.activity1, 160);
        doc.setFont(undefined,"normal").setFontSize(8).text(240,427, '2. '+ activity11);

        doc.setFont(undefined,"bold").setFontSize(9).text(240,451, 'Er det en bredere effekt av prosjektet ditt?');
        var impact01 = doc.splitTextToSize(this.state.impact0, 160);
        doc.setFont(undefined,"normal").setFontSize(8).text(240,461, '1. '+ impact01);
        var impact11 = doc.splitTextToSize(this.state.impact1, 160);
        doc.setFont(undefined,"normal").setFontSize(8).text(240,469, '2. '+ impact11);

        doc.line(40, 580,410, 580);
        doc.addImage(frame834,'PNG',48,592,12,15);
        doc.setFont(undefined,"bold").setFontSize(10).text(63,600, 'Vis Det!');
        doc.setFont(undefined,"bold").setFontSize(8).setTextColor('#656C88').text(91,600, 'laget av Norge Unlimited');
        doc.setFont(undefined,"normal").setFontSize(6).setTextColor('#000000').text(48,610, 'visdet@toyenunlimited.no | www.norgeunlimited.no');
        doc.setFont(undefined,"normal").setFontSize(6).text(370,610, 'Lastet ned '+ date);
        doc.save('KartleggDetSteg1.pdf');
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Button
                  variant="text"
                  className={classes.frame235button3} 
                  onClick={() => this.pdfGenerate()}
                  component={Link} 
                  to="/Main"
                > 
                  Last ned 
                </Button>
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true}) (ExitStep1Pdf)