import React, { Component } from "react";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import Vectortrack2 from "../../Images/TrackIt/TrackStep2/Vectortrack2.svg";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import Ellipse30Track7 from "../../Images/TrackIt/TrackStep2/Ellipse30Track7.svg";
import ExitPopupTrackIt from "../ExitDialoge/ExitPopupTrackIt";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const styles = (theme) => ({
  innerContent: {
    width: "100%",
    //Changes by Kishan
    height: "1100px",
    // height: '100%',
    "overflow-x": "visible",
    "overflow-y": "auto",
  },
  frame747: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "1440px",
    left: "0px",
    top: "0px",
    marginTop: "250px",
  },
  frame746: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "50px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-145px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-274px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-310px",
      marginTop: "50px",
    },
  },
  frame745: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    height: "664px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "32px",
  },
  frame743: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0px 70px 0px 70px",
    bottom: "0px",
    position: "sticky",
    width: "1400px",
    height: "85px",
    top: "1526px",
    flexGrow: "1",
    marginLeft: "40px",
    marginRight: "40px",
    marginBottom: "-300px",
    // // eslint-disable-next-line
    // ["@media (max-width:1280px)"]: {
    //   marginLeft: "166px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1150px)"]: {
    //   marginLeft: "283px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1050px)"]: {
    //   marginLeft: "329px",
    // },
  },
  frame190: {
    display: "flex",
    flexDirection: "row",
    //justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",
    //position: "absolute",
    width: "1080px",
    height: "48px",
    // left: "735px",
    // top: "37px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginLeft: "40px",

    // // eslint-disable-next-line
    // ["@media (min-width:1440px)"]: {
    //   marginLeft: "30px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1300px)"]: {
    //   marginLeft: "-10px",
    //   width: "524px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1280px)"]: {
    //   marginLeft: "-140px",
    //   width: "524px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1150px)"]: {
    //   marginLeft: "-255px",
    //   width: "524px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1050px)"]: {
    //   marginLeft: "-300px",
    //   width: "440px",
    // },
  },
  frame235: {
    display: "flex",
    width: "264px",
    height: "48px",
    marginRight: "25px",
  },
  addOutcomeButton: {
    boxShadow: "none",
    height: "48px",
    width: "215px",
    border: "2px solid #BBBECC",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    background: "white",
    borderRadius: "24px",
    textTransform: "none",
    marginLeft: "10px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  nextButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "30px",
    position: "static",
    left: "144px",
    top: "0px",
    background: "#24243F",
    borderRadius: "24px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#24243F",
    },
  },
  backButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "120px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    marginRight: "522px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  frame203: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "229px",
    height: "56px",
    left: "101px",
    top: "29px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      width: "200px",
    },
  },
  frame203buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    position: "static",
    width: "75px",
    height: "24px",
    left: "0px",
    top: "32px",
    borderRadius: "8px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginBottom: "12px",
  },
  frame203button: {
   
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#2D86D9",
    flex: "none",
    order: "0",
    flexGrow: "0",
    marginLeft: "-8px",
    marginTop: "17px",
    textTransform: "none",
    textDecoration: "none",
  },
  frame203Text: {
   
    position: "static",
    left: "229px",
    top: "24px",
    right: "0px",
    bottom: "0px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  FrameDudeTrackIt: {
    position: "static",
    left: "85px",
    top: "85px",
    right: "0px",
    bottom: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "10px 10px 0px 16px",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      marginLeft: "-10px",
    },
  },
  outerFrameDudeTrackIt: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "0px",
    position: "static",
    width: "330px",
    height: "85px",
    left: "70px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },

  frame744: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px 150px 0px 365px",
    position: "static",
    width: "1440px",
    height: "200px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "43px",
  },
  frame274: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "483px",
    left: "735px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "-40px",
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "0px",
    },
  },
  frame233: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "128px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "40px 0px 0px 0px",
  },
  Paragraph1: {
   
    position: "static",
    width: "524px",
    height: "168px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "30px",
  },
  frame234: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "302px",
    height: "72px",
    left: "403px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "42px 30px",
  },
  header1: {
   
    position: "static",
    width: "302px",
    height: "48px",
    left: "0px",
    top: "24px",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#24243F",
  },
  headerStep: {
   
    fontWeight: "bold",
    position: "static",
    width: "50px",
    height: "24px",
    left: "0px",
    top: "0px",
    fontSize: "16px",
    lineHeight: "32px",
    color: "#24243F",
  },
  frame590: {
    width: "364.34px",
    height: "262.36px",
    // left: "96px",
    // top: "2.19px",
  },
  Frame590Vector: {
    position: "absolute",
    left: "96px",
    top: "-17px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-150px",
      left: "127px",
      top: "77px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-100px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-150px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
  },
  subHeader1: {
   
    width: "475px",
    height: "24px",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    marginLeft: "-197px",
    marginTop: "3px",
  },
  frame210: {
    marginTop: "50px",
  },
  dataDisplay: {
    width: "1070px",
    height: "400px",
    border: "1px solid #24243F",
    margin:'132px 181px 0px 170px ',
    justifyContent:'space-between',
    overflow:'scroll',
    paddingLeft:'20px',
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "70px",
      left: "127px",
      top: "77px",
    },
    },
  divBox: {
    width: "151px",
    marginRight: "20px",
    marginBottom: "-20px",
  },
  divBoxRow2: {
    //width: "151px",
    marginTop: "-40px",
    lineHeight: '28px'
  }, 
  divBox2: {
    width: '189px',
    marginTop: "-40px",
    lineHeight: '28px'
  },
  divBox3: {
    width: '150px',
    marginTop: "-40px",
    lineHeight: '28px'
  },
  boxParagraph1: {
    //alignContent: 'center',
    width: "151px",
    marginRight: "10px",
    marginTop:'0px'
  },
  boxParagraphHeader: {
    //alignContent: 'center',
    fontWeight: "bold",
    fontSize:16,
    width: "142px",
    height: "48px",
    //margin: '0 10px 0 10px'
    marginRight: "10px",
  },
  lineHoriz: {
    marginTop: "-20px",
    marginBottom: "-20px",
  },
  list: {
    paddingLeft: "15px",
    width: "151px",
    height: "120px",
    marginTop: "0px",
    
  },

});

class TrackStep2_7 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outcome: "",
      indicators: [],
      targetGroups: [],
      toolsAndMethods: [],
      whenToCollectData: "",
      addNotes: [],
    };
  }

  componentWillUpdate(nextProps, nextState) {

    // var trackStep27t = [ ]
    
    // var trackStep27 = JSON.parse(localStorage.getItem('trackStep27') || "[]");
    // trackStep27t.push(trackStep27)
 
    // localStorage.setItem("trackStep27t", JSON.stringify(trackStep27t));
    
    // if (trackStep27t === null) {
      
    //   localStorage.setItem(
    //     "trackStep27",
    //     JSON.stringify({
    //       outcome: nextState.outcome,
    //       indicators: nextState.indicators,
    //       targetGroups: nextState.targetGroups,
    //       toolsAndMethods: nextState.toolsAndMethods,
    //       whenToCollectData: nextState.whenToCollectData,
    //       addNotes: nextState.addNotes,
    //       })
    //     );
    //   } else if (trackStep27t.length === 1 ) {
    //     //var secondOutcome = JSON.parse(localStorage.getItem('trackStep27') || "[]");
        
    //   } else if (trackStep27t.length === 2 ) {
        
    //   }
    


  }
  componentDidMount() {
    let thisIndicators = JSON.parse(localStorage.getItem("trackStep22"));
    //to be updates in step2
    let tempIndicators = JSON.parse(localStorage.getItem("trackStep22"));
    let tempTargets = JSON.parse(localStorage.getItem("trackStep23"));
    let tempTools = JSON.parse(localStorage.getItem("trackStep24"));
    let tempWhenToCollectData = JSON.parse(localStorage.getItem("trackStep25"));
    let tempAddNotes = JSON.parse(localStorage.getItem("trackStep26"));
    this.setState({
      outcome: thisIndicators.outcome,
      indicators: tempIndicators.thisIndicators,
      targetGroups: tempTargets.targets,
      toolsAndMethods: tempTools.targets,
      whenToCollectData: tempWhenToCollectData.whenToCollectData,
      addNotes: tempAddNotes.inputField,
    });
    console.log(this.state);
  }

  render() {
    const { classes } = this.props;

    const { outcome } = this.state;
    const { indicators } = this.state;
    const { targetGroups } = this.state;
    const { toolsAndMethods } = this.state;
    const { whenToCollectData } = this.state;
    const { addNotes } = this.state;
    return (
      <div className={classes.innerContent}>
        <Grid item xs className={classes.frame590}>
          <img
            src={Vectortrack2}
            alt="Vectortrack2"
            className={classes.Frame590Vector}
          />
        </Grid>
        <Grid item xs className={classes.frame747}>
          <Grid item xs className={classes.frame746}>
            <Grid item xs className={classes.frame745}>
              <Grid item xs className={classes.frame744}>
                <Grid item xs className={classes.frame274}>
                  <Grid item xs className={classes.frame233}>
                    <Grid container xs className={classes.frame210}>
                      <Grid item xs>
                        <img src={Ellipse30Track7} alt="Ellipse Circle" />{" "}
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.subHeader1}>
                        Forhåndsvisning og legg til endringer
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography className={classes.Paragraph1}>
                    Her er et sammendrag av hvordan du planlegger å samle inn data for å måle den viktigste endringen som resultat av prosjektet. Du kan legge til flere endringer ved å klikke legg til en endring nedenfor. 
                    </Typography>
                  
                  </Grid>
                </Grid>
                <Grid item xs className={classes.frame234}>
                  <Typography noWrap className={classes.headerStep}>
                    Steg 2
                  </Typography>

                  <Typography className={classes.header1}>
                     Plan for måling <br />av data
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs className={classes.frame743}>
              <Grid item xs className={classes.frame190}>
                <Grid order="1" item xs className={classes.frameExitButton}>
                  <ExitPopupTrackIt />
                </Grid>
                <Button
                  variant="contained"
                  className={classes.backButton}
                  component={Link}
                  to="/Main/TrackItStep2_6"
                >
                  Tilbake
                </Button>
                <Grid item xs className={classes.frame235}>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235TertiaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.addOutcomeButton}
                      component={Link}
                      to="/Main/TrackItStep2_1"
                    >
                    Legg til ny endring 
                    </Button>
                  </Grid>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235PrimaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.nextButton}
                      component={Link}
                      to="/Main/TrackItCongratulation"
                      type="submit"
                    >
                      Ferdig
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer component={Paper} className={classes.dataDisplay} sx={{maxWidth:1080}}>
          <Table sx={{maxWidth:1070}} stickyHeader aria-label="sticky table" >
            <TableHead>
              <TableRow>
                <TableCell sx={{fontSize:16,fontWeight:700}}>Endringene vi måler</TableCell>
                <TableCell sx={{fontSize:16,fontWeight:700}}>Indikatorene vi måler</TableCell>
                <TableCell sx={{fontSize:16,fontWeight:700}}>Hvem vil du hente dataene fra?</TableCell>
                <TableCell sx={{fontSize:16,fontWeight:700}}>Hvordan vil du måle det?</TableCell>
                <TableCell sx={{fontSize:16,fontWeight:700}}>Når vil du samle inn data?</TableCell>
                <TableCell sx={{fontSize:16,fontWeight:700}}>Tilleggsnotater</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                >
                  <TableCell sx={{fontSize:16,fontWeight:500}} >
                    {outcome}
                  </TableCell>

                  <TableCell sx={{fontSize:16,fontWeight:500}} >
                    <ol className={classes.list}>
                      {indicators.map((item) => (
                        <li> {item}</li>
                      ))}
                    </ol>
                  </TableCell>
                  <TableCell sx={{fontSize:16,fontWeight:500}} >
                    <ul className={classes.list}>
                      {targetGroups.map((item) => (
                          <li> {item}</li>
                        ))}
                    </ul>
                  </TableCell>
                  <TableCell sx={{fontSize:16,fontWeight:500}}>
                    <ul className={classes.list}>
                      {toolsAndMethods.map((item) => (
                          <li> {item}</li>
                        ))}
                    </ul>
                  </TableCell>
                  <TableCell sx={{fontSize:16,fontWeight:500}}>
                    <Box mt={-9}>
                      {whenToCollectData}
                    </Box>
                  </TableCell>
                  <TableCell sx={{fontSize:16,fontWeight:500}}>
                    <ul className={classes.list}>
                      {addNotes.map((item) => (
                          <li> {item}</li>
                        ))}
                    </ul>
                  </TableCell>
                </TableRow>
              
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TrackStep2_7);
