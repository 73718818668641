import * as React from "react";
import { Box, makeStyles, Popover, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const styles = makeStyles((theme) => ({
  popupBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "14px",
    width: "319px",
    height: "100px",
  },
  frame216info2: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    variant: "body",
    fontFeatureSetting: "ss03 on",
    backgroundColor: "#F0DCE4",
    width: '130px'
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: "#F0DCE4",
  },
  info1: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    variant: "body",
    fontFeatureSetting: "ss03 on",
    margin: "0px 5px",
  },
}));

export default function PopupMap1() {
  const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <span>
      <span
        className={classes.frame216info2}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        kortsiktige resultatene 
      </span>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box className={classes.popupBox}>
          <InfoOutlinedIcon />
          <Typography sx={{ p: 2 }} className={classes.info1}>
          Innledende eller kortsiktige resultater refererer spesifikt til resultater som er numeriske og direkte knyttet til aktiviteten. For eksempel antall personer som deltar på et kurs eller antall måltider som deles ut til eldre.
          </Typography>
        </Box>
      </Popover>
    </span>
  );
}
