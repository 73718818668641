import React, { Component } from "react";
import { Button, Grid, Typography, TextField } from "@material-ui/core";
import ellipse30tell12 from "../../Images/TellIt/TellStep1/ellipse30tell12.svg";
import frame528 from "../../Images/TellIt/TellStep1/frame528.svg";
import { Link } from "react-router-dom";
import DudePopup1 from "./DudePopup1.js";
import { withStyles } from "@material-ui/styles";
import DudeTellIt from "../../Images/TellIt/Illustrasjoner10.svg";
import ExitPopupTellIt from "../ExitDialoge/ExitPopupTellIt";

const styles = (theme) => ({
  tellItStep1: {
    maxWidth: "1440px",
    //Changes by Kishan
    height: "1070px",
    // minHeight: "900px",
    // height: "830px",
    // padding: "0px 163.5px 100px 163.5px",
    // overflowY: "visible",
  },
  frame747: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "absolute",
    width: "1440px",
    //height: "1024px",
    left: "0px",
    top: "0px",
  },
  frame746: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "1440px",
    //height: "768px",
    left: "0px",
    top: "256px",
    height: "700px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "155px",
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-75px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    // ["@media (max-width:1150px)"]: {
    //   marginLeft: "-274px",
    // //   marginTop: "50px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1050px)"]: {
    //   marginLeft: "-346px",
    //   marginTop: "50px",
    // },
  },
  frame745: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "1440px",
    height: "530px",
    left: "0px",
    top: "256px",
  },
  frame743: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0px 70px 0px 70px",
    bottom: "0px",
    //position: "sticky",
    width: "1100px",
    height: "85px",
    top: "1526px",

    flexGrow: "1",

    marginLeft: "30px",
   
  },
  frame190: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "524px",
    height: "48px",
    left: "735px",
    top: "37px",
    marginRight: "-230px",
    flex: "none",
    order: "1",
    flexGrow: "0",

    // eslint-disable-next-line
    // ["@media (min-width:1440px)"]: {
    //   //marginLeft: "30px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1300px)"]: {
    //   marginLeft: "-10px",
    //   width: "524px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1280px)"]: {
    //   marginLeft: "-140px",
    //   width: "524px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1150px)"]: {
    //   marginLeft: "-255px",
    //   width: "524px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1050px)"]: {
    //   marginLeft: "-300px",
    //   width: "440px",
    // },
  },
  frame235: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "264px",
    height: "48px",
    left: "260px",
    top: "0px",

    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  nextButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "10px",
    position: "static",
    left: "144px",
    top: "0px",
    background: "#24243F",
    borderRadius: "24px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    //variant: "body",
    justifyContent: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#24243F",
    },
  },
  backButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    //variant: "body",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",

    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "120px",
    height: "48px",
    left: "0px",
    top: "0px",

    border: "2px solid #BBBECC",
    boxSizing: "border-box",

    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },

  exitButton: {
    position: "static",
    width: "120px",
    height: "48px",

    border: "2px solid #BBBECC",
    boxSizing: "border-box",

    flex: "none",
    order: "0",
    flexGrow: "0",
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    //variant: "body",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  frame203: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "229px",
    height: "56px",
    left: "101px",
    top: "29px",

    flex: "none",
    order: "1",
    flexGrow: "0",

    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      width: "200px",
    },
  },
  frame203Text: {
    position: "static",
    left: "229px",
    top: "24px",
    right: "0px",
    bottom: "0px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",

    color: "#24243F",

    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  frame744: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px 181px 0px 403px",

    position: "static",
    width: "1440px",
    height: "483px",
    left: "0px",
    top: "0px",

    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "43px",
  },
  frame274: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "524px",
    height: "483px",
    left: "735px",
    top: "0px",

    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "-40px",
    // eslint-disable-next-line
    // ["@media (max-width:1300px)"]: {
    //   marginLeft: "-30px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1280px)"]: {
    //   marginLeft: "0px",
    // },
  },
  frame271: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "524px",
    height: "307px",
    left: "0px",
    top: "176px",

    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "20px",
  },

  frame210: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",

    position: "static",
    width: "524px",
    height: "32px",
    left: "0px",
    top: "0px",
    margin: "24px 0",
  },
  header1: {
    width: "200px",
    height: "24px",
    fontSize: "16px",
    lineHeight: "24px",

    color: "#24243F",

    position: "ABSOLUTE",
    marginLeft: "45px",
  },
  group3: {
    position: "static",
    height: "32px",
    left: "0px",
    top: "0px",

    flex: "none",
    order: "0",
    flexGrow: "1",
    margin: "0px 0px",
  },
  paragraph1: {
    position: "static",
    width: "524px",
    height: "95px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    margin: "16px auto",
  },
  frame234: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "302px",
    height: "72px",
    left: "403px",
    top: "0px",

    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px 30px",
  },
  mainHeader: {
    position: "static",
    width: "302px",
    height: "48px",
    left: "0px",
    top: "24px",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#24243F",
  },
  step1: {
    //variant: "body",
    fontWeight: "bold",
    position: "static",
    width: "50px",
    height: "24px",
    left: "0px",
    top: "0px",
    fontSize: "16px",
    lineHeight: "32px",
    color: "#24243F",
  },
  frame590: {
    width: "453.32px",
    height: "290.09px",
    left: "16px",
    top: "132px",
  },
  frame528: {
    position: "absolute",
    left: "102px",
    top: "20px",
    // eslint-disable-next-line
    // ["@media (max-width:1280px)"]: {
    //   marginLeft: "-150px",
    //   //width: '373px',
    //   left: "127px",
    //   top: "77px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1150px)"]: {
    //   marginLeft: "-100px",
    //   width: "373px",
    //   left: "127px",
    //   top: "132px",
    // },
    // // eslint-disable-next-line
    // ["@media (max-width:1050px)"]: {
    //   marginLeft: "-150px",
    //   width: "373px",
    //   left: "127px",
    //   top: "132px",
    // },
  },
  outerFrameDude: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "0px",
    position: "static",
    width: "330px",
    height: "85px",
    left: "70px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  FrameDudeTellIt: {
    position: "static",
    left: "85px",
    top: "85px",
    right: "0px",
    bottom: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "10px 10px 0px 16px",
   
  },
});

class TellStep1_2 extends Component {
  tellStep12Data;
  constructor(props) {
    super(props);
    this.state = {
      elevatorPitch: "",
    };

    this.onChangeElevatorPitch = this.onChangeElevatorPitch.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  // Form event and function
  onChangeElevatorPitch(e) {
    this.setState({ elevatorPitch: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault();
  }

  componentWillUpdate(nextProps, nextState) {
    localStorage.setItem("tellStep12", JSON.stringify(nextState));
  }
  componentDidMount() {
    this.tellStep12Data = JSON.parse(localStorage.getItem("tellStep12"));
    if (localStorage.getItem("tellStep12")) {
      this.setState({
        elevatorPitch: this.tellStep12Data.elevatorPitch,
      });
    } else {
      this.setState({
        elevatorPitch: "",
      });
    }
  }

  //let initialNeeds

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.tellItStep1}>
        <Grid item xs className={classes.frame590}>
          <img src={frame528} alt="Vector162" className={classes.frame528} />
        </Grid>
        <Grid item xs className={classes.frame747}>
          <Grid item xs className={classes.frame746}>
            <Grid item xs className={classes.frame745}>
              <Grid item xs className={classes.frame744}>
                <Grid item xs className={classes.frame274}>
                  <Grid item xs className={classes.frame271}>
                    <Grid item xs className={classes.frame210}>
                      <Grid item xs className={classes.group3}>
                        <img src={ellipse30tell12} alt="Ellipse30" />{" "}
                      </Grid>
                      <Typography className={classes.header1}>
                      Heispitch
                      </Typography>
                    </Grid>
                    <Typography className={classes.paragraph1}>
                    En heispitch er et godt format for å skrive en kort og tydelig introduksjon av ditt prosjekt. Tips: Forklar problemet og hvordan du løser det så enkelt som mulig. Du kan også inkludere det som gjør tilnærmingen din unik.
                    </Typography>
                    <TextField
                      variant="outlined"
                      aria-label="empty textarea"
                      placeholder="Skriv 2-3 setninger (maks 50 ord)"
                      multiline
                      minRows={8}
                      maxRows={8}
                      fullWidth
                      inputProps={{
                        maxLength: 300,
                      }}
                      type="text"
                      name="elevatorPitch"
                      id="elevatorPitch"
                      value={this.state.elevatorPitch}
                      onChange={this.onChangeElevatorPitch}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid item xs className={classes.frame234}>
                  <Typography noWrap className={classes.step1}>
                    Steg 1
                  </Typography>

                  <Typography className={classes.mainHeader}>
                  Kontekst
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs className={classes.frame743}>
              <Grid item xs className={classes.frame190}>
                <Grid order="1" item xs className={classes.frameExitButton}>
                  <ExitPopupTellIt/>
                </Grid>
                <Grid item xs className={classes.frame235}>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235TertiaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.backButton}
                      to="/Main/TellItStep1_1"
                      component={Link}
                    >
                      Tilbake
                    </Button>
                  </Grid>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235PrimaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.nextButton}
                      to="/Main/TellItStep2_1"
                      component={Link}
                    >
                      Neste
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs className={classes.outerFrameDude}>
                <Grid order="0" item xs className={classes.FrameDudeTellIt}>
                  <img src={DudeTellIt} alt="illustrationMan" />
                </Grid>
                <Grid item xs className={classes.frame203}>
                  <Typography className={classes.frame203Text} noWrap>
                  Trenger du hjelp? Se eksempler 
                  </Typography>
                  <DudePopup1 />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(TellStep1_2);
