import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Popover,
  makeStyles,
} from "@material-ui/core";
import Illustrasjoner10 from "../../Images/Main/Illustrasjoner10.png";
import closePopover from "../../Images/Main/ci_close-big.svg";
import Vector from "../../Images/TrackIt/TrackStep2//Vector.svg";
import VectorX from "../../Images/TrackIt/TrackStep2/VectorX.svg";
import Line16 from "../../Images/TrackIt/TrackStep2/Line16.svg";

const styles = makeStyles({
  frameDude: {
    background: "#F9FAFE",
    borderRadius: "20px",
  },
  frame263: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "45px",
    borderRadius: "20px",
    backgroundColor: "#F9FAFE",
    width: "561px",
    height: "665px",
  },
  frame625: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",

    position: "static",
    width: "471px",
    height: "206px",
    left: "45px",
    top: "45px",
  },
  popoverText1: {
    fontWeight: "bold",
    position: "static",
    width: "471px",
    height: "72px",
    left: "0px",
    top: "136px",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#24243F",
    marginTop: "16px",
  },
  popoverText2: {
    position: "static",
    width: "471px",
    height: "48px",
    left: "0px",
    top: "136px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    marginBottom: "16px",
  },
  closePopover: {
    position: "static",
    marginBottom: "61px",
    marginLeft: "362px",
    cursor: "pointer",
  },
  popover: {
    top: "668px",
    left: "32px",
    borderRadius: "20px",
  },
  frame203button: {
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    fontSize: "16px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#2D86D9",
    flex: "none",
    order: "0",
    flexGrow: "0",
    marginLeft: "-8px",

    textTransform: "none",
    textDecoration: "none",
  },
  vector: {
    marginBottom: "-2px",
    marginRight: "-5px",
    marginLeft: "7px",
  },
  vectorX: {
    marginTop: "-5px",
    marginRight: "-5px",
    marginLeft: "7px",
  },
  linksHeader: {
    fontSize: "16px",
    marginTop: "20px",
    marginBottom: "10px",
    fontWeight: "bold",
  },
  addInfoHeader: {
    margin: "20px 0 10px 0",
    fontWeight: "bold",
  },
  addInfo: {
    padding: "15px 30px 30px 30px",
  },
  addInfoBackground: {
    background: "#F4F6F9",
  },
  blueLine: {
    marginBottom: "10px",
  },
});

export default function DudePopup1() {
  const classes = styles();

  const [anchor, setAnchor] = useState();
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [showResearch, setShowResearch] = useState(false);
  const [showInterview, setShowInterview] = useState(false);
  const [showFocus, setShowFocus] = useState(false);

  const handleOpenPopover = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchor(null);
  };
  const handleOpenQuestionnaire = () => {
    setShowQuestionnaire(!showQuestionnaire);
  };
  const handleOpenResearch = () => {
    setShowResearch(!showResearch);
  };
  const handleOpenInterview = () => {
    setShowInterview(!showInterview);
  };
  const handleOpenFocus = () => {
    setShowFocus(!showFocus);
  };

  return (
    <Grid order="1" item xs className={classes.frameDude}>
      <Button
        className={classes.frame203button}
        //aria-describedby={id}
        onClick={handleOpenPopover}
      >
        Klikk her
      </Button>
      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        marginThreshold={20}
        className={classes.popover}
      >
        <Grid item xs className={classes.frame263}>
          <Grid item xs className={classes.frame625}>
            <Grid justifyContent="space-between">
              <img
                src={Illustrasjoner10}
                alt="illustration10"
                className={classes.illustrasjoner10popover}
              />

              <img
                src={closePopover}
                alt="close illustraion"
                className={classes.closePopover}
                onClick={handleClosePopover}
              />
            </Grid>
            <Typography className={classes.popoverText1}>
              Et raskt innblikk: Verktøy og metoder
            </Typography>
            <span className={classes.popoverText2}>
              Nedenfor finner du en kort oversikt over de vanligste
              datainnsamlingsmetodene.
            </span>
            <Typography
              style={{ color: showQuestionnaire ? "#2D86D9" : "black" }}
              className={classes.addInfoHeader}
            >
              Spørreskjema {"  "}
              {!showQuestionnaire && (
                <img
                  src={Vector}
                  alt="Plus"
                  className={classes.vector}
                  onClick={handleOpenQuestionnaire}
                />
              )}
              {showQuestionnaire && (
                <img
                  src={VectorX}
                  alt="Plus"
                  className={classes.vectorX}
                  onClick={handleOpenQuestionnaire}
                />
              )}
            </Typography>
            {showQuestionnaire && (
              <div className={classes.addInfoBackground}>
                <img
                  alt="Blue Line"
                  src={Line16}
                  className={classes.blueLine}
                />
                <Typography className={classes.addInfo}>
                  Et spørreskjema er en strukturert måte å samle inn
                  reproduserbar informasjon på. Innsamlingen av svar kan gjøres
                  av en deltaker selv, eller av en intervjuer.
                  <br /> <br />
                  Fordeler: Mye brukt og mulig å reprodusere, lett å tolke og få
                  statistikk fra, spesifikk.
                  <br /> <br />
                  Ulemper: Ikke-utforskende, risikerer utilsiktede effekter,
                  mangler mulighet for avklaring.
                  <br /> <br />
                  Ideelt for: Produksjon av statistiske data, studier som krever
                  flere tester, svært strukturerte undersøkelser.
                </Typography>
              </div>
            )}
            <Typography
              className={classes.addInfoHeader}
              style={{ color: showResearch ? "#2D86D9" : "black" }}
            >
             Deltakende forskning {"  "}
              {!showResearch && (
                <img
                  src={Vector}
                  alt="Plus"
                  className={classes.vector}
                  onClick={handleOpenResearch}
                />
              )}
              {showResearch && (
                <img
                  src={VectorX}
                  alt="Plus"
                  className={classes.vectorX}
                  onClick={handleOpenResearch}
                />
              )}
            </Typography>
            {showResearch && (
              <div className={classes.addInfoBackground}>
                <img
                  alt="Blue Line"
                  src={Line16}
                  className={classes.blueLine}
                />
                <Typography className={classes.addInfo}>
                Deltakende forskning er en metode der deltakerne leder an, identifiserer relevante behov og fokusområder, og er involvert i alle faser av forskningen.
                  <br /> <br />
                  Fordeler: Kreativ, engasjerer og aktiverer medlemmer av lokalsamfunnet
                  <br /> <br />
                  Ulemper: Krever opplæring, tidkrevende
                  <br /> <br />
                  Ideelt for: Behovsvurdering i et nærmiljø
                </Typography>
              </div>
            )}
            <Typography
              className={classes.addInfoHeader}
              style={{ color: showInterview ? "#2D86D9" : "black" }}
            >
              Intervju {"  "}
              {!showInterview && (
                <img
                  src={Vector}
                  alt="Plus"
                  className={classes.vector}
                  onClick={handleOpenInterview}
                />
              )}
              {showInterview && (
                <img
                  src={VectorX}
                  alt="Plus"
                  className={classes.vectorX}
                  onClick={handleOpenInterview}
                />
              )}
            </Typography>
            {showInterview && (
              <div className={classes.addInfoBackground}>
                <img
                  alt="Blue Line"
                  src={Line16}
                  className={classes.blueLine}
                />
                <Typography className={classes.addInfo}>
                I motsetning til spørreskjemaer kan du i et intervju utforske effekten av arbeidet ditt ved å observere og lytte til svarene til intervjuobjektene du velger å inkludere.  Ved å stille de riktige spørsmålene har deltakerne muligheten til å gå utover enkle svar, og gi mer innsikt i personlige erfaringer med sine egne ord. 
                  <br /> <br />
                  Kan være strukturert, halvstrukturert eller ustrukturert.
                  <br /> <br />
                  Fordeler: Personlig, tillater avklaring,
bra for å identifisere mønstre i meninger,
nyttig for innledende undersøkelse, detaljert

                  <br /> <br />
                  Ulemper: Tidkrevende, ikke generaliserbart
Ideelt for: Avdekke utilsiktede konsekvenser, samle personlige historier, grave i komplekse emner

                </Typography>
              </div>
            )}

            <Typography
              className={classes.linksHeader}
              style={{ color: showFocus ? "#2D86D9" : "black" }}
            >
             Fokusgruppe
              {!showFocus && (
                <img
                  src={Vector}
                  alt="Plus"
                  className={classes.vector}
                  onClick={handleOpenFocus}
                />
              )}
              {showFocus && (
                <img
                  src={VectorX}
                  alt="Plus"
                  className={classes.vectorX}
                  onClick={handleOpenFocus}
                />
              )}
            </Typography>
            {showFocus && (
              <div className={classes.addInfoBackground}>
                <img
                  alt="Blue Line"
                  src={Line16}
                  className={classes.blueLine}
                />
                <Typography className={classes.addInfo}>
                En fokusgruppe er et gruppeintervju som krever tilrettelegging og forhåndsbestemte spørsmål.
                  <br /> <br />
                  Fordeler: Oppmuntrer til gruppeutforskning og felles forståelse
                  <br /> <br />
                  Ulemper: Tidkrevende, analysetung og mangler konfidensialitet
                  <br /> <br />
                  Ideelt for: Innledende forskning, prosjekter som utforsker gruppeforskjeller
                </Typography>
              </div>
            )}
            <Typography className={classes.linksHeader}>
            Flere inspirasjonskilder
            </Typography>
            <Button
              className={classes.frame203button}
              //aria-describedby={id}
              target="_blank"
              href="https://www.inspiringimpact.org/learn-to-measure/do/"
            >
              De vanligste datainnsamlingsverktøyene
            </Button>
            <Button
              className={classes.frame203button}
              //aria-describedby={id}
              target="_blank"
              href="https://www.youtube.com/watch?v=q17s84ADGfA"
            >
              Introduksjonsvideoer for datainnsamlingsverktøy
            </Button>
            <Button
              className={classes.frame203button}
              //aria-describedby={id}
              target="_blank"
              href="https://www.surveymonkey.com/curiosity/good-survey-questions/"
            >
              Lage gode spørsmål
            </Button>
            <Button
              className={classes.frame203button}
              //aria-describedby={id}
              target="_blank"
              href="https://knowhow.ncvo.org.uk/organisation/impact/plan-your-impact-and-evaluation/mentoring-and-befriending/outcome-indicators"
            >
              Vitenskapelige og lisensierte spørreundersøkelser
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
}
