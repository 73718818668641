import React, { Component } from "react";
import {
  Button,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import DudeTrackIt from "../../Images/TrackIt/TrackStep1/DudeTrackIt.svg";
import Frame592 from "../../Images/TrackIt/TrackStep1/Frame592.svg";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import Ellipse30 from "../../Images/Main/Ellipse30.png";
import Ellipse31 from "../../Images/Main/Ellipse31.png";
import ExitPopupTrackIt from "../ExitDialoge/ExitPopupTrackIt";

const styles = (theme) => ({
  innerContent: {
    width: "100%",
    //Changes by Kishan
    height: "1070px",
    // height: '100%',
    "overflow-x": "visible",
    "overflow-y": "auto",
  },
  frame747: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "1440px",
    left: "0px",
    top: "0px",
    marginTop: "250px",
  },
  frame746: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "50px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-145px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-274px",
      marginTop: "50px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-310px",
      marginTop: "50px",
    },
  },
  frame745: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "1440px",
    height: "564px",
    left: "0px",
    top: "256px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "72px",
  },
  frame743: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0px 70px 0px 70px",
    bottom: "0px",
    position: "sticky",
    width: "1440px",
    height: "85px",
    top: "1526px",
    flexGrow: "1",
    marginBottom: "-300px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "166px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "283px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "329px",
    },
  },
  frame190: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    width: "524px",
    height: "48px",
    left: "735px",
    top: "37px",
    flex: "none",
    order: "1",
    flexGrow: "0",

    // eslint-disable-next-line
    ["@media (min-width:1440px)"]: {
      marginLeft: "30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-10px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-140px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-255px",
      width: "524px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-300px",
      width: "440px",
    },
  },
  frame235: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "264px",
    height: "48px",
    left: "260px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  nextButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "10px",
    position: "static",
    left: "144px",
    top: "0px",
    background: "#24243F",
    borderRadius: "24px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    textTransform: "none",
    width: "120px",
    height: "48px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#24243F",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#24243F",
    },
  },
  backButton: {
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    color: "#24243F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "static",
    width: "120px",
    height: "48px",
    left: "0px",
    top: "0px",
    border: "2px solid #BBBECC",
    boxSizing: "border-box",
    flex: "none",
    order: "0",
    flexGrow: "0",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  frame203: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "229px",
    height: "56px",
    left: "101px",
    top: "29px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      width: "200px",
    },
  },
  frame203buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    position: "static",
    width: "75px",
    height: "24px",
    left: "0px",
    top: "32px",
    borderRadius: "8px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginBottom: "12px",
  },
  frame203button: {
    position: "static",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#2D86D9",
    flex: "none",
    order: "0",
    flexGrow: "0",
    marginLeft: "-8px",
    marginTop: "17px",
    textTransform: "none",
    textDecoration: "none",
  },
  frame203Text: {
    position: "static",
    left: "229px",
    top: "24px",
    right: "0px",
    bottom: "0px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#24243F",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  FrameDudeTrackIt: {
    position: "static",
    left: "85px",
    top: "85px",
    right: "0px",
    bottom: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "10px 10px 0px 16px",
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      marginLeft: "-10px",
    },
  },
  outerFrameDudeTrackIt: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "0px",
    position: "static",
    width: "330px",
    height: "85px",
    left: "70px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },

  frame744: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px 150px 0px 365px",
    position: "static",
    width: "1440px",
    height: "515px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "43px",
  },
  frame274: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "483px",
    left: "735px",
    top: "0px",
    flex: "none",
    order: "1",
    flexGrow: "0",
    marginTop: "-40px",
    // eslint-disable-next-line
    ["@media (max-width:1300px)"]: {
      marginLeft: "-30px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "0px",
    },
  },
  frame233: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "524px",
    height: "128px",
    left: "0px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "48px 0px",
  },
  Paragraph1: {
    position: "static",
    width: "524px",
    height: "168px",
    left: "0px",
    top: "56px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "30px",
  },
  frame234: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "static",
    width: "302px",
    height: "72px",
    left: "403px",
    top: "0px",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px 30px",
  },
  header1: {
    position: "static",
    width: "302px",
    height: "48px",
    left: "0px",
    top: "24px",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#24243F",
  },
  headerStep: {
    fontWeight: "bold",
    position: "static",
    width: "50px",
    height: "24px",
    left: "0px",
    top: "0px",
    fontSize: "16px",
    lineHeight: "32px",
    color: "#24243F",
  },
  frame590: {
    width: "364.34px",
    height: "262.36px",
    // left: "96px",
    // top: "2.19px",
  },
  Frame590Vector: {
    position: "absolute",
    left: "96px",
    top: "2.19px",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "-150px",
      left: "127px",
      top: "77px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1150px)"]: {
      marginLeft: "-100px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1050px)"]: {
      marginLeft: "-150px",
      width: "373px",
      left: "127px",
      top: "132px",
    },
  },
  Ellipse30: {
    marginLeft: "-16px",
    marginBottom: "8px",
  },
  Ellipse31: {
    left: "0px",
    top: "0px",
  },
  subHeader1: {
    width: "120px",
    height: "24px",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    marginLeft: "-218px",
    marginTop: "4px",
  },
  subHeader2: {
    width: "524px",
    height: "24px",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#24243F",
    marginTop: "30px",
  },
  formControl: {
    marginTop: "30px",
    width: "255px",
  },
  select: {
    width: '500px'
  }
});

class TrackStep1_2 extends Component {
  areaOfImpact;
  constructor(props) {
    super(props);

    this.state = {
      display: "static",
      dropdownArray: [
        {
          id: 1,
          header: "Bedre fysisk helse",
          description:
            "Dette inkluderer aktiviteter som påvirker menneskers treningsvaner, diett, sovemønster, rusmisbruk og andre forebyggende aktiviteter som forbedrer den fysiske helsen. ",
        },
        {
          id: 2,
          header: "Bedre mental helse",
          description:
            "Dette inkluderer aktiviteter som hjelper mennesker å håndtere sin mentale helse, forbedre livskvaliteten og deres evne til å hente seg inn etter tøffe hendelser. ",
        },
        {
          id: 3,
          header:
            "Har tilgang til de tjenestene og fasilitetene de trenger lokalt",
          description:
            "Dette inkluderer tilgjengelighet og tilgang på nødvendige tilbud i et lokalsamfunn, som f.eks. rimelige boliger, offentlig transport, finanstjenester, bibliotek, legetjenester, samfunnssentere.",
        },
        {
          id: 4,
          header: "Sterkere familier, relasjoner og lokalsamfunn",
          description:
            "Dette inkluderer tilgang på støtte- og hjelpetjenester som trengs for å leve et bra liv. Sterkere samfunn inkluderer aktiviteter som øker involvering i samfunnsbeslutninger, frivillig arbeid og forbedring av lokalområdet.",
        },
        {
          id: 5,
          header: "Arbeid",
          description:
            "Dette inkluderer aktiviteter som hjelper mennesker å øke sine sjanser for å få jobb og opprettholde jobben.",
        },
        {
          id: 6,
          header: "Opplæring og utdanning",
          description:
            "Dette inkluderer aktiviteter som forbedrer mennesker evner, kunnskap, utdannelse og forbereder dem for å gå ut i jobb.",
        },
      ],
      impact: 0,
      checked1: false,
    };
    this.onChangeAreaOfImpact = this.onChangeAreaOfImpact.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangeAreaOfImpact(e) {
    this.setState({ impact: e.target.value, checked1: true });
  }

  onSubmit(e) {
    e.preventDefault();
  }

  componentWillUpdate(nextProps, nextState) {
    localStorage.setItem("trackStep12", JSON.stringify({impact: nextState.impact}));
  }
  componentDidMount() {
    this.areaOfImpact = JSON.parse(localStorage.getItem("trackStep12"));
    if (localStorage.getItem("trackStep12")) {
      this.setState({
        impact: this.areaOfImpact.impact,
      });
      if (this.areaOfImpact.impact !== '') {
        this.setState({
          checked1: true
        });
      }
    } else {
      this.setState({
        impact: "",
      });
    }
  }

  render() {
    const { classes } = this.props;
    let { dropdownArray } = this.state;
    let { impact } = this.state;
    const { checked1 } = this.state;
    return (
      <div className={classes.innerContent}>
        <Grid item xs className={classes.frame590}>
          <img
            src={Frame592}
            alt="Vector162"
            className={classes.Frame590Vector}
          />
        </Grid>
        <Grid item xs className={classes.frame747}>
          <Grid item xs className={classes.frame746}>
            <Grid item xs className={classes.frame745}>
              <Grid item xs className={classes.frame744}>
                <Grid item xs className={classes.frame274}>
                  <Grid item xs className={classes.frame233}>
                    <Grid container xs className={classes.frame210}>
                      <Grid item xs>
                        <img
                          src={Ellipse31}
                          alt="Ellipse31"
                          className={classes.Ellipse31}
                        />
                        <img
                          src={Ellipse30}
                          alt="Ellipse30"
                          className={classes.Ellipse30}
                        />{" "}
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.subHeader1}>
                        Effektområde
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography className={classes.Paragraph1}>
                    Et effektområde beskriver feltet du arbeider med. Basert på hvordan offentlige tjenester leveres, kan sosiale prosjekter kategoriseres innenfor seks effektområder.
                      <br />
                      <br />
                      Hvilken del av folks liv er dine aktiviteter rettet mot å forbedre? Det kan hende at prosjektet ditt ikke passer inn i ett av effektområdene. Velg det som er nærmest prosjektet.
                    </Typography>

                    <Typography className={classes.subHeader2}>
                    Hva er prosjektets effektområde?
                    </Typography>

                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      {/* <InputLabel id="demo-simple-select-label">
                        Please Select
                      </InputLabel> */}
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={impact}
                        onChange={this.onChangeAreaOfImpact}
                        className={classes.select}
                        
                      >
                        <MenuItem value={dropdownArray[0].header}>
                          {this.state.dropdownArray[0].header}
                        </MenuItem>
                        <MenuItem value={dropdownArray[1].header}>
                        {this.state.dropdownArray[1].header}
                        </MenuItem>
                        <MenuItem value={dropdownArray[2].header}>
                        {this.state.dropdownArray[2].header}
                        </MenuItem>
                        <MenuItem value={dropdownArray[3].header}>
                        {this.state.dropdownArray[3].header}
                        </MenuItem>
                        <MenuItem value={dropdownArray[4].header}>
                        {this.state.dropdownArray[4].header}
                        </MenuItem>
                        <MenuItem value={dropdownArray[5].header}>
                        {this.state.dropdownArray[5].header}
                        </MenuItem>
                      </Select>
                    </FormControl>

                    {impact === this.state.dropdownArray[0].header && (
                      <>
                        <Typography className={classes.subHeader2}>
                          {dropdownArray[0].header}
                        </Typography>
                        <Typography className={classes.Paragraph1}>
                          {dropdownArray[0].description}
                        </Typography>
                      </>
                    )}
                    {impact === this.state.dropdownArray[1].header && (
                      <>
                        <Typography className={classes.subHeader2}>
                          {dropdownArray[1].header}
                        </Typography>
                        <Typography className={classes.Paragraph1}>
                          {dropdownArray[1].description}
                        </Typography>
                      </>
                    )}
                    {impact === this.state.dropdownArray[2].header && (
                      <>
                        <Typography className={classes.subHeader2}>
                          {dropdownArray[2].header}
                        </Typography>
                        <Typography className={classes.Paragraph1}>
                          {dropdownArray[2].description}
                        </Typography>
                      </>
                    )}
                    {impact === this.state.dropdownArray[3].header && (
                      <>
                        <Typography className={classes.subHeader2}>
                          {dropdownArray[3].header}
                        </Typography>
                        <Typography className={classes.Paragraph1}>
                          {dropdownArray[3].description}
                        </Typography>
                      </>
                    )}
                    {impact === this.state.dropdownArray[4].header && (
                      <>
                        <Typography className={classes.subHeader2}>
                          {dropdownArray[4].header}
                        </Typography>
                        <Typography className={classes.Paragraph1}>
                          {dropdownArray[4].description}
                        </Typography>
                      </>
                    )}
                    {impact === this.state.dropdownArray[5].header && (
                      <>
                        <Typography className={classes.subHeader2}>
                          {dropdownArray[5].header}
                        </Typography>
                        <Typography className={classes.Paragraph1}>
                          {dropdownArray[5].description}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs className={classes.frame234}>
                  <Typography noWrap className={classes.headerStep}>
                    Steg 1
                  </Typography>

                  <Typography className={classes.header1}>
                  Finn indikatorer
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs className={classes.frame743}>
              <Grid item xs className={classes.frame190}>
                <Grid order="1" item xs className={classes.frameExitButton}>
                  <ExitPopupTrackIt />
                </Grid>
                <Grid item xs className={classes.frame235}>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235TertiaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.backButton}
                      component={Link}
                      to="/Main/TrackItStep1"
                    >
                      Tilbake
                    </Button>
                  </Grid>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.button235PrimaryContainer}
                  >
                    <Button
                      variant="contained"
                      className={classes.nextButton}
                      component={Link}
                      to="/Main/TrackItStep1_3"
                      type="submit"
                      disabled={!checked1}
                    >
                      Neste
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs className={classes.outerFrameDudeTrackIt}>
                <Grid order="0" item xs className={classes.FrameDudeTrackIt}>
                  <img
                    src={DudeTrackIt}
                    alt="illustrationMan"
                    className={classes.DudeTrackIt}
                  />
                </Grid>
                <Grid item xs className={classes.frame203}>
                  <Typography className={classes.frame203Text}>
                  Spørsmål?
                  </Typography>
                  <Grid
                    order="1"
                    item
                    xs
                    className={classes.frame203buttonContainer}
                  >
                    <Button
                      className={classes.frame203button}
                      component={Link}
                      to="/Main/Contact"
                    >
                      Kontakt Norge Unlimited
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TrackStep1_2);
