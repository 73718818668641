import { makeStyles, Typography, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import logoNew from "../Images/Header/logoNew.svg";

const style = makeStyles({

  headerBar: {
    "max-width": "1298px",
    width: "100%",
    position: "sticky",
    display: "flex",
    "flex-direction": "row",
    top: "0px",
    "flex-grow": "1",
    "margin-top": "40px",
    clear: "both",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
  },
  logo: {
    marginLeft: "30px",
    width: "33.26px",
    height: "34.56px",
  },
  logotext: {
    textDecoration: "inherit",
    color: "inherit",
    fontSize: 20,
    fontWeight: 550,
    marginLeft: "17px",
    "&:hover": {
      textDecoration: "underline",
      color: "#f1d39e",
    },
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      display: "inline-block",
    },
    // eslint-disable-next-line
    ["@media (min-width:781px)"]: {
      display: "inline-block",

    },
  },
  contact: {
    marginLeft: "-12px",
    textDecoration: "inherit",
    color: "inherit",
    fontSize: 16,
    fontWeight: 550,
    lineHeight: "60px",
    "&:hover": {
      textDecoration: "underline",
      color: "#f1d39e",
    },
  },
  rectangle981: {
    position: "absolute",
    width: "100%",
  },

  // eslint-disable-next-line
  ["@media (max-width:1165px)"]: {
    contact: {
      marginLeft: "-15px",
    },
  },
  // eslint-disable-next-line
  ["@media (max-width:1100px)"]: {
    contact: {
      marginLeft: "-25px",
    },
  },
});

export default function LandingHeader() {

  const classes = style();
  return (
    <header className={classes.headerBar}>
      <Grid container direction="row">
        <Grid item xs={11}>
          <img src={logoNew} alt="logo" className={classes.logo} />
          <Typography
            noWrap
            className={classes.logotext}
            component={Link}
            to="/"
          >
            Vis Det!{" "}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography
            noWrap
            className={classes.contact}
            component={Link}
            to="/LandingContact"
          >
            {" "}
            Kontakt oss{" "}
          </Typography>
        </Grid>
      </Grid>
    </header>
  );
}
