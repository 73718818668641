import React, { Component } from 'react';
import { Button, withStyles } from '@material-ui/core';
import jsPDF from 'jspdf';
import rectangle221 from '../../Images/TellIt/TellFinishPdf/rectangle221.jpg';
import  frame834 from '../../Images/MapIt/MapFinishPdf/frame834.jpg';
import  Illustrasjoner14 from '../../Images/TellIt/TellFinishPdf/Illustrasjoner14.png';
import  vector2png from '../../Images/TellIt/TellFinishPdf/vector2png.png';
import  Illustrationer05 from '../../Images/MapIt/MapFinishPdf/Illustrationer05.jpg'


const styles = (theme) => ({
    downloadButton: {
        width: "190px",
        height: "48px",
        border: "2px solid #BBBECC",
        borderRadius: "24px",
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "10px 20px",
        color: "#24243F",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "white",
        },
        marginBottom: '50px'
    },
    frame703button2: {
        borderRadius: "24px",
        textTransform:"none",
        width:"230px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"14px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F'
    }
})

class TellFinishPdf extends Component {
    tellStep11Data;
    tellStep12Data;
    tellStep21Data;
    tellStep22Data;
    tellStep31Data;
    tellStep32Data;
    tellStep4Data;

     constructor(props) {
        super(props);
        this.tellStep11Data = JSON.parse(localStorage.getItem('tellStep11'));
        this.tellStep12Data = JSON.parse(localStorage.getItem('tellStep12'));
        this.tellStep21Data = JSON.parse(localStorage.getItem('tellStep21'));
        this.tellStep22Data = JSON.parse(localStorage.getItem('tellStep22'));
        this.tellStep31Data = JSON.parse(localStorage.getItem('tellStep31'));
        this.tellStep32Data = JSON.parse(localStorage.getItem('tellStep32'));
        this.tellStep4Data  = JSON.parse(localStorage.getItem('tellStep4'));

        this.state = {
            projectName : this.tellStep11Data.projectName,
            elevatorPitch : this.tellStep12Data.elevatorPitch,
            outputsAndResults : this.tellStep21Data.outputsAndResults,
            outcomes : this.tellStep22Data.outcomes,
            impact :  this.tellStep31Data.impact,
            quote :  this.tellStep32Data.quote,
            stakeholder : this.tellStep32Data.stakeholder,
            goals :  this.tellStep4Data.goals
        }
     };
    pdfGenerate = () => {

        const date = new Date().toLocaleDateString();

        var doc = new jsPDF ('portrait','px', 'a4','false');
        doc.addImage(rectangle221,'JPG',19,19,350,190);
        doc.addImage(vector2png,'PNG',325,40,90,100);
        doc.addImage(Illustrasjoner14,'PNG',275,40,90,130);

        doc.setFont(undefined,"bold").setFontSize(12).text(40,150,'Samfunnseffektrapport');
        var projectName1 = doc.splitTextToSize(this.state.projectName,150)
        doc.setFont('h3',"normal").setFontSize(28).text(40,180,projectName1);

        doc.setFont(undefined,"bold").setFontSize(12).text(40,230,'Din heispitch');
        var elevatorPitch1 = doc.splitTextToSize(this.state.elevatorPitch, 470);
        doc.setFont(undefined,"normal").setFontSize(10).text(40,245,elevatorPitch1);
        doc.setDrawColor('#E3E5E9').line(40, 280,410, 280);

        doc.setFont(undefined,"bold").setFontSize(12).text(40,296,'Resultater');
        var outputsAndResults1 = doc.splitTextToSize(this.state.outputsAndResults, 250);
        doc.setFont(undefined,"normal").setFontSize(9).text(40,311,outputsAndResults1);
        doc.setDrawColor('#E3E5E9').line(40, 360,219, 360);
        doc.setFont(undefined,"bold").setFontSize(12).text(40,375,'Endring');
        var outcomes1 = doc.splitTextToSize(this.state.outcomes, 250);
        doc.setFont(undefined,"normal").setFontSize(9).text(40,390,outcomes1);
        doc.setDrawColor('#E3E5E9').line(225, 295,225, 430);

        doc.setFont(undefined,"bold").setFontSize(12).text(245,296,'Effekt og hva interessenter sier');
        var impact1 = doc.splitTextToSize(this.state.impact, 250);
        doc.setFont(undefined,"normal").setFontSize(9).text(245,311,impact1);
        var quote1 = doc.splitTextToSize(this.state.quote, 155);
        doc.setFont("Time-Italic","normal").setFontSize(10).text(245,365,quote1);

        doc.setFont(undefined,"normal").setFontSize(10).text(245,425,'-');
        var stake1 = doc.splitTextToSize(this.state.stakeholder, 150);
        doc.setFont("Time-Italic","normal").setFontSize(10).text(250,425,stake1);

        doc.setDrawColor('#E3E5E9').line(40, 440,410, 440);

        doc.setFont(undefined,"bold").setFontSize(12).text(40,455,'Neste steg');
        var goals1 = doc.splitTextToSize(this.state.goals, 520);
        doc.setFont(undefined,"normal").setFontSize(9).text(40,470,goals1);


        doc.addImage(Illustrationer05,'JPG',40,540,32,32);
        doc.setFont(undefined,"normal").setTextColor('#24243F').setFontSize(8).text(76,550,"Hva er en samfunnseffektrapport?");
        var narrative = doc.splitTextToSize("Effektrapporten er en énsides rapport som presenterer resultatene og kommuniserer verdien av aktivitetene dine. Det vil hjelpe deg med å demonstrere legitimiteten til arbeidet ditt for dine sponsorer, investorer eller kunder. Vil ha mer informasjon, se FORTELL DET.", 270);
        doc.setFont(undefined,"normal").setTextColor('#000000').setFontSize(6).text(76,558,narrative);

        doc.line(40, 580,410, 580);
        doc.addImage(frame834,'JPG',48,592,12,15);
        doc.setFont(undefined,"bold").setFontSize(10).text(63,600, 'Vis Det!');
        doc.setFont(undefined,"bold").setFontSize(8).setTextColor('#656C88').text(91,600, 'laget av Norge Unlimited');
        doc.setFont(undefined,"normal").setFontSize(6).setTextColor('#000000').text(48,610, 'visdet@toyenunlimited.no | www.norgeunlimited.no');
        doc.setFont(undefined,"normal").setFontSize(6).text(370,610, 'Lastet ned '+ date);
        doc.save('FortellDet!.pdf');
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Button
                  variant="contained"
                  className={classes.frame703button2} 
                  onClick={this.pdfGenerate}
                > 
                  Last ned rapporten
                </Button>
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true}) (TellFinishPdf)