import React, { Component } from 'react'
import { withStyles, Typography, Grid, Box, Button} from '@material-ui/core';
import { Link } from 'react-router-dom'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

//Emailjs library
import emailjs from 'emailjs-com';
import {  toast } from 'react-toastify';

const styles= (theme) => ({
    frame193:{
        width:'579px',
        height:'708px',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-end',
        justifyContents:'flex-end',
        padding:'0px',
        background: '#FFFFFF',
        border: '1px solid #24243F',
        boxSizing: 'border-box',
        borderRadius: '12px',
        Padding:'40px'
    },
    frame235button: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"14px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        margin:'32px 0px',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F',
        marginLeft:'24px'
      },
    frame195:{
        width:'564px',
        height:'32px',
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-start',
        justifyContents:'space-between',
        padding:'0px',
        margin:'16px 0px'
    },
    closeIcon:{
        marginLeft:'240px',
        cursor: "pointer", 
    },
    frame193Title:{
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '33px',
        variant:'subtitle',
        color:'#000000',
        
    },
    frame193info1:{
        variant:'body',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color:'#24243F',
        marginTop:'16px'
    },
    frame235button2: {
        borderRadius: "24px",
        textTransform:"none",
        width:"120px",
        height:"48px",
        fontWeight: 'bold',
        fontSize:"16px" ,
        lineHeight: '24px',
        variant:'body',
        justifyContent:'center',
        alignItems:'center',
        margin:'0px 24px',
        padding:'10px 20px',
        color:'white',
        backgroundColor:'#24243F',
        marginBottom:'16px'
    },
})

class LandingDialog extends Component {
    userData;
    constructor(props) {
        super(props);
        this.state = {
             open: false
        }

        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.emailService = this.emailService.bind(this);
    };
    //PopUp Open function
    handleClickOpen(event) {
        this.setState({open:true});
    };

    //Popup Close function
    handleClose(event) {
        this.setState({open: false});
    };

    emailService = e => {
        var landingContactData = JSON.parse(localStorage.getItem('landingContact'));
        var email= "";
        var message = "";
        var name = "";
        var section = "";
        if (landingContactData == null) {
            landingContactData = ""
        }
        else{
            email = landingContactData.email;
            message = landingContactData.message;
            name = landingContactData.name;
            section = landingContactData.section;
        }
        const templateParams = {
            from_name: name,
            to_name: "Team",
            to_email: "visdet@toyenunlimited.no",
            message: message,
            userEmail: email,
            section:   section
          };
          
        emailjs.send("service_1eqxy04", "template_u7z9whd", templateParams,"user_iSg6PpR3UP45tdGG8Q14F" )
          .then(
            function(response) {
              toast.success("Your message has successfully sent!", {
                position: toast.POSITION.BOTTOM_CENTER
              });
              console.log("SUCCESS!", response.status, response.text);
            },
            function(err) {
              toast.error("Your message was not able to be sent");
            }
          );
    };

    render() {
        const {classes} = this.props;
        var landingContactData = JSON.parse(localStorage.getItem('landingContact'));
        var email= "";
        var message = ""
        if (landingContactData == null) {
            landingContactData = ""
        }
        else{
            email = landingContactData.email;
            message = landingContactData.message;
        }
        
        return (
            <div >
                <Button variant="contained" 
                    className={classes.frame235button} 
                    onClick={() => { this.handleClickOpen(); this.emailService();}}
                    disabled={!(email && message) }
                >
                   Send inn
                </Button>
                <Dialog  open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" 
                    style=
                        {{border: '1px solid #24243F',
                            boxSizing: 'border-box',
                            borderRadius: '12px'}} >
                    <DialogContent >
                        <Box padding='20px' overflow='visible'>
                            <Grid  item xs direction='row' className={classes.frame195}>
                                <Grid item xs>
                                    <Typography noWrap className={classes.frame193Title} >Sendt inn! </Typography>
                                </Grid>
                                <Grid item xs >
                                    <CloseOutlinedIcon className={classes.closeIcon} onClick={() =>{this.handleClose();localStorage.clear()}}/>
                                </Grid>
                            </Grid>
                            <hr color='#BBBECC' margin='16px 0px' />
                            <DialogContentText className={classes.frame193info1}>
                            Takk for meldingen! Teamet i Norge Unlimited svarer deg innen 3-4 virkedager.
                            </DialogContentText>
                        </Box>                        
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' className={classes.frame235button2} component={Link} to="/" onClick={this.handleClose}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>                
            </div>
        )
    }
}
export default withStyles(styles, {withTheme: true }) (LandingDialog);