import React, { Component } from "react";
import { withStyles, Grid, Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import "../../App.css";
import logoNew from "../Images/Header/logoNew.svg";

const styles= (theme) => ({
  headerBar: {
    "max-width": "1440px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    //flexGrow: 1,
    position: "sticky",
    background: " #FFFFFF",
    width: "100%",
    height: "101px",
    zIndex: 1,
    top: "0px",
    padding: "0px 70px 0px 70px",
    //marginTop: "42px",
    //marginBottom: "20px",
    alignItems: "center",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      flexDirection: "column",
    },
  },
  logoFrame: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    position: "static",
    width: "130px",
    height: "35px",
  },
  frame11: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    position: "relative",
    width: "550px",
    height: "35px",
  },
  logo: {
    width: "27px",
    height: "33px",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      display: "",
    },
  },
  logotext: {
    textDecoration: "inherit",
    color: "inherit",
    fontSize: 20,
    fontWeight: 550,
    marginLeft: "17px",
    flexGrow: 1,
    fontFamily: "Verdana",
    "&:hover": {
      textDecoration: "underline",
      color: "#f1d39e",
    },
  },

  frame217: {
    display: "flex",
    flexDirection: "row",
    alignContent: "right",
    left: "0px",
    padding: "0px",
    position: "relative",
    width: "150px",
    height: "24px",
  },
  contact: {
    marginLeft: "200px",
    textDecoration: "inherit",
    color: "inherit",
    fontSize: 16,
    fontWeight: 550,
    lineHeight: "24px",
    fontFamily: "Verdana",
    width: "150px",
    flexGrow: 1,
    "&:hover": {
      textDecoration: "underline",
      color: "#f1d39e",
    },
    // eslint-disable-next-line
    ["@media (max-width:1430px)"]: {
      marginLeft: "199px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      marginLeft: "190px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1245px)"]: {
      marginLeft: "150px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1200px)"]: {
      marginLeft: "134px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1024px)"]: {
      marginLeft: "135px",
    },
  },
  line15: {
    position: "relative",
    "max-width": "1298px",
    width: "100%",
    height: "0px",
    "z-index": "1",
    // eslint-disable-next-line
    ["@media (max-width:1280px)"]: {
      "max-width": "1140px",
    },
    // eslint-disable-next-line
    ["@media (max-width:1024px)"]: {
      "max-width": "884px",
    },
  },
});

class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overview: true,
      mapIt: false,
      trackIt: false,
      tellIt: false,
    };

    this.onOverview = this.onOverview.bind(this);
    this.onMapIt = this.onMapIt.bind(this);
    this.onTrackIt = this.onTrackIt.bind(this);
    this.onTellIt = this.onTellIt.bind(this);
   
  }
  // Form event and function
  onOverview(e) {
    this.setState({
      overview: true,
      mapIt: false,
      trackIt: false,
      tellIt: false,
    });
  }
  onMapIt(e) {
    this.setState({
      overview: false,
      mapIt: true,
      trackIt: false,
      tellIt: false,
    });
  }
  onTrackIt(e) {
    this.setState({
      overview: false,
      mapIt: false,
      trackIt: true,
      tellIt: false,
    });
  }
  onTellIt(e) {
    this.setState({
      overview: false,
      mapIt: false,
      trackIt: false,
      tellIt: true,
    });
  }
  componentDidMount() {
    this.header = JSON.parse(localStorage.getItem("header"));
    if (localStorage.getItem("header")) {
      this.setState({
        overview: this.state.overview,
      mapIt: this.state.mapIt,
      trackIt: this.state.trackIt,
      tellIt: this.state.tellIt,
      });
    } else {
      this.setState({
        overview: this.state.overview,
        mapIt: this.state.mapIt,
        trackIt: this.state.trackIt,
        tellIt: this.state.tellIt,
      });
    }
  }
  componentWillUpdate(nextProps, nextState) {
    localStorage.setItem("header", JSON.stringify(nextState));
  }
  render() {
    const {classes} = this.props;

    return (
      <>
        <Grid container direction="row" className={classes.headerBar}>
          <Grid xs={3} item className={classes.logoFrame}>
            <img src={logoNew} alt="logo" className={classes.logo} />
            <Typography
              noWrap
              className={classes.logotext}
              component={Link}
              to="/Main"
            >
              Vis Det!{" "}
            </Typography>
          </Grid>

          <Grid xs={6} item className={classes.frame11}>
            <Typography
              autoFocus
              component={Link}
              to="/Main"
              className="frameOverview"
            >
              OVERSIKT
            </Typography>

          {/* {this.state.mapIt && <Typography
            noWrap
            component={Link}
            to="/Main/MapHome"
            className="frameMapIt"
            onClick={this.onMapIt}
          >
          KARTLEGG DET
          </Typography>} */}
          <Typography
            noWrap
            component={Link}
            to="/Main/MapHome"
            className="frameMapIt"
            onClick={this.onMapIt}
          > KARTLEGG DET
          
            </Typography>
            <Typography
              noWrap
              component={Link}
              to="/Main/TrackHome"
              className="frameTrackIt"
            >
              UNDERSØK DET
            </Typography>

            <Typography
              noWrap
              component={Link}
              to="/Main/TellHome"
              className="frameTellIt"
            >
              FORTELL DET
            </Typography>
          </Grid>

          <Grid xs={3} item className={classes.frame217}>
            <Box
              className={classes.contact}
              exact
              component={Link}
              to="/Main/Contact"
              noWrap
            >
              {" "}
              Kontakt oss{" "}
            </Box>
          </Grid>
        </Grid>
        <div className={classes.line15}>
          <hr color="#E3E5E9" />
        </div>
      </>
    );
  }
}
export default withStyles(styles, {withTheme: true }) (MainHeader);
